import {Button, Form, Input, Modal} from "antd";
import React from "react";

const NewInstanceForm = (props) => {
	const {getFieldDecorator} = props.form;
	return (
		<Form layout="vertical" hideRequiredMark>
			<Form.Item label="Example Field A">
				{getFieldDecorator('exampleA', {
					rules: [{required: true, message: 'Please enter value'}],
				})(<Input placeholder="Please enter value" />)}
			</Form.Item>
			<Form.Item label="Example Field B">
				{getFieldDecorator('exampleB', {
					rules: [{required: true, message: 'Please enter value'}],
				})(<Input placeholder="Please enter value" />)}
			</Form.Item>
			<Form.Item label="Example Field C">
				{getFieldDecorator('exampleC', {
					rules: [{required: true, message: 'Please enter value'}],
				})(<Input placeholder="Please enter value" />)}
			</Form.Item>
			<Form.Item label="Example Field D">
				{getFieldDecorator('exampleD', {
					rules: [{required: true, message: 'Please enter value'}],
				})(<Input placeholder="Please enter value" />)}
			</Form.Item>
		</Form>
	);
};

export default Form.create()(NewInstanceForm);
