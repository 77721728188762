import {Button, Card, Col, Statistic} from "antd";
import InSitePieChart from "../../charts/InSitePieChart";
import ReactApexChart from "react-apexcharts";
import React from "react";
import formatMoney from "../../../util/formatMoney";
import {DatePickerStripShitVersion} from "../../datepicker/Datepicker";

const STAGE_PERF_CONFIG = {
	series: [
		{
			name: 'Available',
			data: [1, 3, 0, 1].reverse()
		},
		{
			name: 'On Hold',
			data: [1, 0, 0, 0].reverse()
		},
		{
			name: 'Deposit',
			data: [2, 0, 0, 0].reverse()
		},
		{
			name: 'Settled',
			data: [0, 1, 0, 2].reverse()
		},
		{
			name: 'Sold',
			data: [0, 0, 4, 1].reverse()
		}
	],
	options: {
		colors: ["#000000", "#df6f44", "#1890ff", "#a0d911", "#ff4d4f"],
		chart: {
			type: 'bar',
			height: 450,
			stacked: true,
		},
		labels: ["Stage 1", "Stage 2", "Stage 3", "Stage 4"].reverse()
	}
};

const ReportButtonTab = (props) => {
	const onHold = props.data.map((stage) => stage.lots).flat().filter((lot) => lot.status === 'Hold');
	const deposits = props.data.map((stage) => stage.lots).flat().filter((lot) => lot.status === 'Deposit');
	const conditional = props.data.map((stage) => stage.lots).flat().filter((lot) => lot.status === 'Conditional');
	const unconditional = props.data.map((stage) => stage.lots).flat().filter((lot) => lot.status === 'Unconditional');
	const settled = props.data.map((stage) => stage.lots).flat().filter((lot) => lot.status === 'Settled');
	const sold = props.data.map((stage) => stage.lots).flat().filter((lot) => lot.status === 'Sold');

	return (
		<>
			<Col span={4}>
				<Card>
					<Statistic title={<div style={{width: '100%'}}><span>Sold</span><span style={{float: 'right'}}>{sold.length}</span></div>} value={sold.reduce((acc, curr) => acc + curr.price, 0)} formatter={(value) => "$" + formatMoney(value)} />
				</Card>
			</Col>

			<Col span={4}>
				<Card>
					<Statistic title={<div style={{width: '100%'}}><span>Deposit</span><span style={{float: 'right', fontSize: '28px'}}>{deposits.length}</span></div>} value={deposits.reduce((acc, curr) => acc + curr.price, 0)} formatter={(value) => "$" + formatMoney(value)} />
				</Card>
			</Col>

			<Col span={4}>
				<Card>
					<Statistic title={<div style={{width: '100%'}}><span>On Hold</span><span style={{float: 'right', fontSize: '28px'}}>{onHold.length}</span></div>} value={onHold.reduce((acc, curr) => acc + curr.price, 0)} formatter={(value) => "$" + formatMoney(value)} />
				</Card>
			</Col>

			<Col span={4}>
				<Card>
					<Statistic title={<div style={{width: '100%'}}><span>Conditional</span><span style={{float: 'right', fontSize: '28px'}}>{conditional.length}</span></div>} value={conditional.reduce((acc, curr) => acc + curr.price, 0)} formatter={(value) => "$" + formatMoney(value)} />
				</Card>
			</Col>

			<Col span={4}>
				<Card>
					<Statistic title={<div style={{width: '100%'}}><span>Unconditional</span><span style={{float: 'right', fontSize: '28px'}}>{unconditional.length}</span></div>} value={unconditional.reduce((acc, curr) => acc + curr.price, 0)} formatter={(value) => "$" + formatMoney(value)} />
				</Card>
			</Col>

			<Col span={4}>
				<Card>
					<Statistic title={<div style={{width: '100%'}}><span>Settled</span><span style={{float: 'right', fontSize: '28px'}}>{settled.length}</span></div>} value={settled.reduce((acc, curr) => acc + curr.price, 0)} formatter={(value) => "$" + formatMoney(value)} />
				</Card>
			</Col>



			<Col span={8}>
				<Card title="Sold vs Deposit">
					<InSitePieChart id={`example_one`} data={[3, 2]} labels={['Sold', 'Deposit']} />
				</Card>
			</Col>

			<Col span={8}>
				<Card title="Conditional vs Unconditional">
					<InSitePieChart id={`example_one`} data={[7, 8]} labels={['Conditional', 'Unconditional']} />
				</Card>
			</Col>

			<Col span={8}>
				<Card title="Sold per Stage">
					<InSitePieChart id={`example_one`} data={[7, 8, 4, 13]} labels={['Stage 1', 'Stage 2', 'Stage 3', 'Stage 4']} />
				</Card>
			</Col>

			<Col span={24}>
				<Card title="Stage Performance">
					<ReactApexChart options={STAGE_PERF_CONFIG.options} series={STAGE_PERF_CONFIG.series} type="bar" height={450} width="95%" />
				</Card>
			</Col>
		</>
	);
};

export default ReportButtonTab;
