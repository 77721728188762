import React, {useState} from "react";
import {Button, Drawer} from 'antd';
import Result from "antd/es/result";
import UpdateProfileForm from "../forms/UpdateProfileForm";

const showStatus = (status) => {
	if(status) {
		return (
			<Result
				status="success"
				title="Successfully updated user profile"
				subTitle="Click the below button to close this window."
				extra={[
					<Button type="primary" key="console" onClick={() => { props.onClose(); props.clearSubmitSuccess() }}>
						Close
					</Button>
				]}
			/>
		);
	} else {
		return (<UpdateProfileForm/>);
	}
};

const UpdateCustomerDetailsDrawer = (props) => {
	return (
		<Drawer title="Update Customer Details" width={720} onClose={props.onClose} visible={props.visible} bodyStyle={{ paddingBottom: 80 }}>
			{showStatus(props.submitSuccess)}
			<div style={{
					position: 'absolute',
					right: 0,
					bottom: 0,
					width: '100%',
					borderTop: '1px solid #e9e9e9',
					padding: '10px 16px',
					background: '#fff',
					textAlign: 'right',
				}}>
				<Button onClick={props.onClose} style={{ marginRight: 8 }}>
					Cancel
				</Button>
				<Button onClick={props.onSubmitSuccess} type="primary">
					Submit
				</Button>
			</div>
		</Drawer>
	);
};

export default UpdateCustomerDetailsDrawer;
