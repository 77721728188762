import React from "react";
import { Drawer, Form, Button, Col, Row, Input, Select, DatePicker, Icon } from 'antd';
import UpdateProfileForm from "../forms/UpdateProfileForm";
import Result from "antd/es/result";

const { Option } = Select;

export default class UpdateProfileDrawer extends React.Component {
	showStatus(status) {
		if(status) {
			return (
				<Result
					status="success"
					title="Successfully updated user profile"
					subTitle="Click the below button to close this window."
					extra={[
						<Button type="primary" key="console" onClick={() => { this.props.onClose(); this.props.clearSubmitSuccess() }}>
							Close
						</Button>
					]}
				/>
			);
		} else {
			return (<UpdateProfileForm/>);
		}
	}

	render() {
		return (
			<div>
				<Drawer
					title="Create a new account"
					width={720}
					onClose={this.props.onClose}
					visible={this.props.visible}
					bodyStyle={{ paddingBottom: 80 }}
				>
					{this.showStatus(this.props.submitSuccess)}
					<div
						style={{
							position: 'absolute',
							right: 0,
							bottom: 0,
							width: '100%',
							borderTop: '1px solid #e9e9e9',
							padding: '10px 16px',
							background: '#fff',
							textAlign: 'right',
						}}
					>
						<Button onClick={this.props.onClose} style={{ marginRight: 8 }}>
							Cancel
						</Button>
						<Button onClick={this.props.onSubmitSuccess} type="primary">
							Submit
						</Button>
					</div>
				</Drawer>
			</div>
		);
	}
}


