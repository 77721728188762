import InSiteFrame from "../../components/frame/InSiteFrame";
import React, {useState} from "react";
import {Button, Card, Col, Collapse, Form, Icon, Input, Modal, PageHeader, Row, Select, Table, notification} from "antd";
import initGsuiteIntegration from "../../integrations/gsuite";

const WEBHOOK_COLS = [
	{ title: 'Name', dataIndex: 'name', key: 'name' },
	{ title: 'Event', dataIndex: 'event', key: 'event' },
	{ title: 'URL', dataIndex: 'url', key: 'url' },
];
const WEBHOOK_DATA = [
	{ key: 1, name: 'On Lead Created', event: 'event.tracking.new_lead', url: 'https://examplereceiver.com/webhooks/receive' },
	{ key: 2, name: 'On Lead Registered', event: 'event.registration.new_lead', url: 'https://examplereceiver.com/webhooks/receive' },
	{ key: 3, name: 'On New Note', event: 'event.notes.new_note', url: 'https://examplereceiver.com/webhooks/receive' },
	{ key: 4, name: 'On Agent Update', event: 'event.lead.update_agent', url: 'https://examplereceiver.com/webhooks/receive' },
	{ key: 5, name: 'On Lead Updated', event: 'event.lead.update_lead', url: 'https://examplereceiver.com/webhooks/receive' }
];

const IntegrationsPage = (props) => {
	const [showWebhookModal, setShowWebhookModal] = useState(false);
	const closeWebhookModal = () => setShowWebhookModal(false);
	const displayWebhookModal = () => setShowWebhookModal(true);
	const [isGsuiteApiInitialised, setIsGsuiteApiInitialised] = useState(false);
	initGsuiteIntegration(() => {
		setIsGsuiteApiInitialised(true);
	});

	return (
		<InSiteFrame>
			<Row gutter={[16,16]} type="flex">
				<Col span={24}>
					<Card>
						<PageHeader backIcon={false} title="Integrations Configuration">
							<p>Use this page to configure integration with a number of supported services.</p>
							<p>If you cannot find your supported service in this list, please feel free to request an additional integration.</p>
						</PageHeader>
					</Card>
				</Col>
			</Row>

			<Row gutter={[16,16]} type="flex">
				<Col span={12}>
					<Card title="InSite API">
						<p>
							InSite provides API access to all the data available within the portal,
							accessing this API requires that you provide some form of authentication
							to ensure you are authorized to access the data in question. Should a
							developer ask for your API Key or any details necessary to connect to the
							InSite API, provide them with the following:
						</p>
						<Input value="0cf68b10-150a-4a1a-aae4-69fc73f7b54e" disabled={true} />
					</Card>
				</Col>
				<Col span={12}>
					<Card title="Supported Services">
						<Collapse>
							<Collapse.Panel key="1" header="Google Suite" extra={getExtraIcon('chrome')}>
								<p>Integrating with your Google account allows you to sync up your Google Calendar, allowing you to manage and view events in your calendar from within the InSite platform.</p>
								<ul>
									<li>Requires access to your google account</li>
									<li>Allows InSite to read and update your google calendar</li>
									<li>Merges your calendar events with the events already stored in InSite</li>
									<li>Available in the "My Work Day" window.</li>
								</ul>
								<Button type={"primary"} disabled={!isGsuiteApiInitialised} onClick={() => { gapi.auth2.getAuthInstance().signIn(); }}>Integrate with Google Suite</Button>
							</Collapse.Panel>

							<Collapse.Panel key="2" header="Microsoft 365" extra={getExtraIcon('windows')}>
								<p>Integrating with your 365 account allows InSite to sync up your 365 Calendar, allowing you to manage and view events in your calendar from within the InSite platform.</p>
								<ul>
									<li>Requires access to your Microsoft 365 account</li>
									<li>Allows InSite to read and update your 365 calendar</li>
									<li>Merges your calendar events with the events already stored in InSite</li>
									<li>Available in the "My Work Day" window.</li>
								</ul>
								<Button type={"primary"}>Integrate with Microsoft 365</Button>
							</Collapse.Panel>

							<Collapse.Panel key="3" header="Mailchimp" extra={getExtraIcon('mail')}>
								<p>Integrating with Mailchimp allows you to track and report on your email campaigns from within InSite.</p>
								<ul>
									<li>Requires access to your Mailchimp account</li>
									<li>Allows InSite to read your audiences and campaigns</li>
									<li>Allows InSite to update and sync your audience with your leads in InSite</li>
									<li>Allows InSite to report and track on your email campaigns sent via Mailchimp</li>
									<li>Audience management is available via the Leads Report and Lead Profile Page</li>
									<li>Campaign Reporting is available via the eDM Report page available under the Reporting menu</li>
								</ul>
								<Button type={"primary"}>Integrate with Mailchimp</Button>
							</Collapse.Panel>

							<Collapse.Panel key="4" header="Salesforce" extra={getExtraIcon('cloud')}>
								<p>Integrating with Salesforce allows you to sync your InSite data with your Salesforce Org.</p>
								<ul>
									<li>Requires access to your Salesforce account</li>
									<li>Allows InSite to read, update and create data within your Salesforce Org</li>
									<li>Data is synced between the two systems automatically</li>
									<li>Downstream data requires configuring mapping between objects and fields</li>
								</ul>
								<Button type={"primary"}>Integrate with Salesforce</Button>
							</Collapse.Panel>
						</Collapse>
					</Card>
				</Col>
			</Row>
			<Row gutter={[16,16]} type="flex">
				<Col span={24}>
					<Card title="Webhooks" extra={<Button type="primary" onClick={displayWebhookModal}>Add Webhook Listener</Button>}>
						<Table columns={WEBHOOK_COLS} dataSource={WEBHOOK_DATA} />
					</Card>
				</Col>
			</Row>
			<Modal title="Add Webhook" visible={showWebhookModal} onOk={() => { showSuccessNotification("Successfully registered webhook", "The next time InSite detects the associated event, this webhook will be triggered."); closeWebhookModal(); }} onCancel={closeWebhookModal}>
				<Form>
					<Form.Item label="Name">
						<Input placeholder="Give your webhook a name" />
					</Form.Item>
					<Form.Item label="Event Type">
						<Select placeholder="Select an event to listen for">
							<Select.OptGroup label="Tracking">
								<Select.Option value="event.tracking.new_lead">event.tracking.new_lead</Select.Option>
							</Select.OptGroup>
							<Select.OptGroup label="Registration">
								<Select.Option value="event.registration.new_lead">event.registration.new_lead</Select.Option>
							</Select.OptGroup>
							<Select.OptGroup label="Notes">
								<Select.Option value="event.notes.new_note">event.notes.new_note</Select.Option>
							</Select.OptGroup>
							<Select.OptGroup label="Lead">
								<Select.Option value="event.lead.update_agent">event.lead.update_agent</Select.Option>
								<Select.Option value="event.lead.update_lead">event.lead.update_lead</Select.Option>
							</Select.OptGroup>
						</Select>
					</Form.Item>
					<Form.Item label="Webhook URL">
						<Input addonBefore={selectBefore} placeholder="Enter the URL to submit the Webhook to" />
					</Form.Item>
				</Form>
			</Modal>
		</InSiteFrame>
	);
};

const showSuccessNotification = (title, msg) => {
	notification['success']({
		message: title,
		description: msg
	});
};

const selectBefore = (
	<Select defaultValue="http://" style={{ width: 90 }}>
		<Select.Option value="http://">http://</Select.Option>
		<Select.Option value="https://">https://</Select.Option>
	</Select>
);

const getExtraIcon = (iconName) => {
	return (
		<Icon type={iconName} />
	);
};

export default IntegrationsPage;
