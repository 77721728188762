import React, {useState} from "react";
import InSiteFrame from "../components/frame/InSiteFrame";
import {Button, Card, Col, PageHeader, Result, Row, Steps} from "antd";
import EditCustomerDetailsForm from "../components/tabPage/inventoryManagementEdit/EditCustomerDetailsForm";
import EditSalesDetailsForm from "../components/tabPage/inventoryManagementEdit/EditSalesDetailsForm";
import {useHistory} from "react-router";
import EditDepositDetailsForm from "../components/tabPage/inventoryManagementEdit/EditDepositDetailsForm";
import EditSettlementDetailsForm from "../components/tabPage/inventoryManagementEdit/EditSettlementDetailsForm";
import UploadFilesDetailsForm from "../components/tabPage/inventoryManagementEdit/UploadFilesDetailsForm";
import EditSalesDetailsAltForm from "../components/tabPage/inventoryManagementEdit/EditSalesDetailsAltForm";
import EditCustomerDetailsAltForm from "../components/tabPage/inventoryManagementEdit/EditCustomerDetailsAltForm";


const StockManagementStepFormPage = (props) => {
	const history = useHistory();
	const [step, setStep] = useState('customer');
	const [stepIdx, setStepIdx] = useState(0);

	const nextStep = () => {
		setStepIdx(stepIdx + 1);
		let nextKey = Object.keys(stepContent)[stepIdx + 1];
		setStep(nextKey);
	};

	const prevStep = () => {
		setStepIdx(stepIdx - 1);
		let nextKey = Object.keys(stepContent)[stepIdx - 1];
		setStep(nextKey);
	};

	const stepContent = {
		customer: (
			<EditCustomerDetailsAltForm onNextClick={() => nextStep()} />
		),
		sale: (
			<EditSalesDetailsAltForm onNextClick={() => nextStep()} onPrevClick={() => prevStep()} />
		),
		upload: (
			<UploadFilesDetailsForm onNextClick={() => nextStep()} onPrevClick={() => prevStep()} />
		),
		finish: (
			<Row gutter={[16, 16]} type={`flex`}>
				<Col span={24}>
					<Card style={{padding: 0}}>
						<PageHeader className="inv-management-header" style={{padding: 0}} title={`Lot 101`} extra={[
							<Button type="primary" key="console" onClick={() => history.push('/stock-management')}>
								Finished
							</Button>
						]} />
					</Card>
				</Col>
				<Col span={24}>
					<Card>
						<Result
							status="success"
							title="Successfully recorded sales information"
							subTitle="Sales details have been recorded against Lot 101." />
					</Card>
				</Col>
			</Row>
		)
	};

	return (
		<InSiteFrame>
			<Row gutter={[16, 16]} type={`flex`}>
				<Col span={24}>
					<Card>
						<Steps current={stepIdx}>
							<Steps.Step key={`customer`} title={`Customer Details`} />
							<Steps.Step key={`sale`} title={`Sale Details`} />
							<Steps.Step key={`upload`} title={`Upload Files`} />
							<Steps.Step key={`finish`} title={`Finished`} status={`finish`} />
						</Steps>
					</Card>
				</Col>
			</Row>


			<Row gutter={[16, 16]} type={`flex`}>
				<Col span={24}>
					{stepContent[step]}
				</Col>
			</Row>
		</InSiteFrame>
	);
};

export default StockManagementStepFormPage;
