import moment from "moment";
import React, {useState} from "react";
import {Col, DatePicker, Select} from "antd";

const DatePickerStrip = (props) => {
	const defaultDuration = [moment().subtract(30, 'days'), moment()];
	const dateRanges = {
		'Today': [moment(), moment()],
		'Past 7 Days': [moment().subtract(7, 'days'), moment()],
		'Past 14 Days': [moment().subtract(14, 'days'), moment()],
		'Past 30 Days': [moment().subtract(30, 'days'), moment()],
		'This Month': [moment().startOf('month'), moment()],
		'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
		'Last Quarter': [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')],
	};
	const [duration, setDuration] = useState('Past 30 Days');
	const onCalendarChange = (dates, dateStrings) => {
		let str = Object.keys(dateRanges).filter((key) => {
			let currentRange = dateRanges[key];
			if(dates[0].isSame(currentRange[0], 'day')) {
				if(dates[1].isSame(currentRange[1], 'day')) {
					return true;
				}
			}

			return false;
		}).pop();

		if(str === undefined || str === null) {
			str = moment.duration(dates[0], dates[1]).humanize();
		}

		setDuration(str);
	};

	return (
		<>
			<Col span={2} style={{textAlign: 'right'}}>
				{duration}
			</Col>
			<Col span={4}>
				<DatePicker.RangePicker onOk={(selectedTime) => console.log(selectedTime)} onChange={onCalendarChange} style={{width: '100%'}} defaultValue={defaultDuration} ranges={dateRanges} />
			</Col>
		</>
	);
};

const DatePickerStripShitVersion = ({hasCustom=true, forceUpdate=null}) => {
	const defaultDuration = [moment().subtract(30, 'days'), moment()];
	const [selectedOption, setSelectedOption] = useState('past_thirty');

	if(hasCustom) {
		if(selectedOption === 'custom') {
			return (
				<>
					<Col span={3} style={{textAlign: 'right'}}>
						<Select size="large" style={{width: '100%', fontSize: '22px'}} defaultValue={`past_thirty`} value={selectedOption} onChange={(value) => { setSelectedOption(value) }}>
							<Select.Option value={`today`}>Today</Select.Option>
							<Select.Option value={`past_seven`}>Past 7 Days</Select.Option>
							<Select.Option value={`past_fourteen`}>Past 14 Days</Select.Option>
							<Select.Option value={`past_thirty`}>Past 30 Days</Select.Option>
							<Select.Option value={`current_month`}>Current Month</Select.Option>
							<Select.Option value={`previous_month`}>Previous Month</Select.Option>
							<Select.Option value={`previous_qtr`}>Previous Quarter</Select.Option>
							<Select.Option value={`all`}>All Recorded Data</Select.Option>
							<Select.Option value={`custom`}>Custom Range</Select.Option>
						</Select>
					</Col>
					<Col span={4}>
						<DatePicker.RangePicker size="large" onOk={(selectedTime) => console.log(selectedTime)} style={{width: '100%', fontSize: '22px'}} defaultValue={defaultDuration} />
					</Col>
				</>
			);
		} else {
			return (
				<>
					<Col span={4} style={{textAlign: 'right'}}>
						&nbsp;
					</Col>
					<Col span={3} style={{textAlign: 'right'}}>
						<Select size="large" style={{width: '100%', fontSize: '22px'}} defaultValue={`past_thirty`} value={selectedOption} onChange={(value) => { setSelectedOption(value) }}>
							<Select.Option value={`today`}>Today</Select.Option>
							<Select.Option value={`past_seven`}>Past 7 Days</Select.Option>
							<Select.Option value={`past_fourteen`}>Past 14 Days</Select.Option>
							<Select.Option value={`past_thirty`}>Past 30 Days</Select.Option>
							<Select.Option value={`current_month`}>Current Month</Select.Option>
							<Select.Option value={`previous_month`}>Previous Month</Select.Option>
							<Select.Option value={`previous_qtr`}>Previous Quarter</Select.Option>
							<Select.Option value={`all`}>All Recorded Data</Select.Option>
							<Select.Option value={`custom`}>Custom Range</Select.Option>
						</Select>
					</Col>
				</>
			);
		}
	} else {
		return (
			<>
				<Col span={4} style={{textAlign: 'right'}}>
					&nbsp;
				</Col>
				<Col span={3} style={{textAlign: 'right'}}>
					<Select size="large" style={{width: '100%', fontSize: '22px'}} defaultValue={`past_thirty`} onChange={() => { if(forceUpdate !== null) { forceUpdate(n => !n); } }}>
						<Select.Option value={`today`}>Today</Select.Option>
						<Select.Option value={`past_seven`}>Past 7 Days</Select.Option>
						<Select.Option value={`past_fourteen`}>Past 14 Days</Select.Option>
						<Select.Option value={`past_thirty`}>Past 30 Days</Select.Option>
						<Select.Option value={`current_month`}>Current Month</Select.Option>
						<Select.Option value={`previous_month`}>Previous Month</Select.Option>
						<Select.Option value={`previous_qtr`}>Previous Quarter</Select.Option>
						<Select.Option value={`all`}>All Recorded Data</Select.Option>
					</Select>
				</Col>
			</>
		);
	}

};

export {
	DatePickerStrip,
	DatePickerStripShitVersion
};
