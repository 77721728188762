import React from "react";
import {
	AutoComplete,
	Button,
	Drawer, Form, Input, Radio, Transfer
} from 'antd';

const MetaDrawer = (props) => {
	const buyerTypes = ["First Home", "Up-sizer", "Down-sizer", "Investor", "Builder"];

	return (
		<Drawer width={720} title={`Customer Details`} visible={props.visible} onClose={props.onClose}>
			<Form>
				<Form.Item label={`Postcode`} style={{marginBottom: '8px'}}>
					<Input value={`0000`} />
				</Form.Item>
				<Form.Item label={`Buyer Type`} style={{marginBottom: '8px'}}>
					<Radio.Group style={{display: 'flex'}}>
						{buyerTypes.map((bt, idx) =>
							<Radio key={idx} style={{flexGrow: '1'}} value={bt}>{bt}</Radio>
						)}
					</Radio.Group>
				</Form.Item>
				<Form.Item label={`Referral Source`} style={{marginBottom: '8px'}}>
					<MultiSelectTransfer data={["Project Website", "Social Media", "realestate.com.au", "Signage", "Google Search", "Google Adwords", "Builder Referral", "Newspaper", "Family & Friends Referral", "Radio/TV", "Repeat Purchaser", "Walk-in"]} />
				</Form.Item>
				<Form.Item label={`Land Budget`} style={{marginBottom: '8px'}}>
					<MultiSelectTransfer data={["$150,000 - $200,000", "$200,000 - $250,000", "$250,000 - $300,000", "$300,000 - $350,000", "$350,000 - $400,000", "$400,000 - $450,000", "$450,000 - $500,000", "$500,000 - $550,000", "$550,000 - $600,000", "$600,000 - $650,000", "$650,000 - $750,000", "$700,000+"]} />
				</Form.Item>
				<Form.Item label={`House & Land Budget`} style={{marginBottom: '8px'}}>
					<MultiSelectTransfer data={["$150,000 - $200,000", "$200,000 - $250,000", "$250,000 - $300,000", "$300,000 - $350,000", "$350,000 - $400,000", "$400,000 - $450,000", "$450,000 - $500,000", "$500,000 - $550,000", "$550,000 - $600,000", "$600,000 - $650,000", "$650,000 - $750,000", "$700,000+"]} />
				</Form.Item>
				<Form.Item label={`Land Size`} style={{marginBottom: '8px'}}>
					<MultiSelectTransfer data={["< 300m2", "300m2 - 350m2", "350m2 - 400m2", "400m2 - 450m2", "450m2 - 500m2", "500m2 - 550m2", "550m2 - 600m2", "600m2+"]} />
				</Form.Item>
			</Form>
			<div
				style={{
					position: 'absolute',
					right: 0,
					bottom: 0,
					width: '100%',
					borderTop: '1px solid #e9e9e9',
					padding: '10px 16px',
					background: '#fff',
					textAlign: 'right',
				}}
			>
				<Button onClick={props.onClose} style={{ marginRight: 8 }}>
					Cancel
				</Button>
				<Button onClick={props.onSubmitSuccess} type="primary">
					Submit
				</Button>
			</div>
		</Drawer>
	);
};

class MultiSelectTransfer extends React.Component {
	constructor(props, context) {
		super(props, context);
		const data = props.data.map((ref, idx) => { return { key: idx, title: ref, description: ref } });
		this.state = {
			data: data,
			targetKeys: [],
			selectedKeys: []
		};
	}

	handleChange = (nextTargetKeys, direction, moveKeys) => {
		this.setState({ targetKeys: nextTargetKeys });

		console.log('targetKeys: ', nextTargetKeys);
		console.log('direction: ', direction);
		console.log('moveKeys: ', moveKeys);
	};

	handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
		this.setState({ selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys] });

		console.log('sourceSelectedKeys: ', sourceSelectedKeys);
		console.log('targetSelectedKeys: ', targetSelectedKeys);
	};

	render() {
		const { data, targetKeys, selectedKeys } = this.state;
		return (
			<Transfer
				dataSource={data}
				titles={['Source', 'Target']}
				targetKeys={targetKeys}
				selectedKeys={selectedKeys}
				onChange={this.handleChange}
				onSelectChange={this.handleSelectChange}
				render={item => item.title}
			/>
		);
	}
}

export default MetaDrawer;
