import React, {useState} from "react";
import {Col, Icon, Row, Table} from "antd";
import formatMoney from "../../../util/formatMoney";
import * as moment from "moment";
import SalesDrawerNew from "../../drawer/SalesDrawerNew";
import {DatePickerStripShitVersion} from "../../datepicker/Datepicker";
import {useHistory} from "react-router";

const PurchaserDetailsTab = (props) => {
	const history = useHistory();
	const [isSalesDrawerVisible, setSalesDrawerVisible] = useState(false);
	console.log("PurchaserDetailsTab");
	const DATA = props.data
		.map((stg) => stg.lots)
		.flat()
		.reduce((accum, lot, idx) => {
			lot.key = idx;
			accum.push(lot);
			return accum;
		}, [])
		.filter((lot) => lot.status !== 'Available' && lot.status !== 'Hold');
	console.log(DATA);
	const COLUMNS = [
		{ width: 150, align: 'center', title: 'Lot', dataIndex: 'name', key: 'name', render: (txt, record, idx) => {
			let color = '#000000';
			if(record.status === 'Sold' || record.status === 'Unconditional') {
				color = '#ff4d4f';
			} else if(record.status === 'Deposit' || record.status === 'Conditional') {
				color = '#1890ff';
			} else if(record.status === 'Settled') {
				color = '#a0d911';
			}
			return (<span style={{color: color}}>{txt}</span>);
		} },
		{ width: 250, align: 'center', title: 'Purchaser', dataIndex: 'buyer', key: 'buyer', render: (txt, record, idx) => {
			let color = '#000000';

			if(!txt) {
				return (<span style={{color: color}}>-</span>);
			} else {
				return (<a style={{cursor: 'pointer', color: color, textDecoration: 'underline'}}>{txt}</a>);
			}
		} },
		{ width: 150, align: 'center', title: 'Status', dataIndex: 'status', key: 'status', render: (txt, record, idx) => {
			let color = '#000000';

			if(!txt) {
				return (<span style={{color: color}}>-</span>);
			} else {
				return (<a style={{cursor: 'pointer', color: color}}>{txt}</a>);
			}
		} },
		{ width: 150, align: 'center', title: 'Conditional', dataIndex: 'condition', key: 'condition', render: (txt, record, idx) => {
			if(!txt) {
				return (<span>-</span>);
			} else {
				return (<span>{txt}</span>);
			}
		}  },
		{ width: 150, align: 'center', title: 'Unconditional', dataIndex: 'status', key: 'status', render: (txt, record, idx) => {
			if(txt === 'Conditional') {
				return (<Icon type="close" />);
			} else if(txt === 'Unconditional') {
				return (<Icon type="check" />);
			} else {
				return (<span>-</span>);
			}
		}  },
		{ width: 150, align: 'center', title: 'Sale Price', dataIndex: 'price', key: 'price', render: (txt, record, idx) => { return `$${formatMoney(txt)}` } },
		{ width: 150, align: 'center', title: 'Contract Date', dataIndex: 'price', key: 'price', render: (txt, record, idx) => { return moment().format('Do MMMM, YYYY') } },
		{ width: 150, align: 'center', title: 'Initial Deposit', dataIndex: 'depositAmt', key: 'depositAmt', render: (txt, record, idx) => {
			if(!txt) {
				return (<span>-</span>);
			} else {
				return (<span>${formatMoney(txt)}</span>);
			}
		}  },
		{ width: 200, align: 'center', title: 'Outstanding Deposit', dataIndex: 'depositOutstanding', key: 'depositOutstanding', render: (txt, record, idx) => {
			if(!txt) {
				return (<span>-</span>);
			} else {
				return (<span>${formatMoney(txt)}</span>);
			}
		}  },
		{ width: 150, align: 'center', title: 'Deposit Date', dataIndex: 'depositDate', key: 'depositDate', render: (txt, record, idx) => {
			if(!txt) {
				return (<span>-</span>);
			} else {
				return (<span>{moment.unix(txt).format("Do MMMM, YYYY")}</span>);
			}
		}  },
		{ width: 150, align: 'center', title: 'Rebate', dataIndex: 'status', key: 'status', render: (txt, record, idx) => {
			if(txt !== 'Conditional') {
				return (<span>-</span>);
			} else {
				return (<span>{`$${formatMoney(5000)}`}</span>);
			}
		}  },
		{ width: 150, align: 'center', title: 'Settlement Price', dataIndex: 'settlementPrice', key: 'settlementPrice', render: (txt, record, idx) => {
			if(!txt) {
				return (<span>-</span>);
			} else {
				return (<span>${formatMoney(txt)}</span>);
			}
		}  },
		{ width: 150, align: 'center', title: 'Settlement Date (Est.)', dataIndex: 'settlementDate', key: 'settlementDate', render: (txt, record, idx) => {
			if(!txt) {
				return (<span>-</span>);
			} else {
				return (<span>{moment.unix(txt).format("Do MMMM, YYYY")}</span>);
			}
		}  },
		{ width: 150, align: 'center', title: 'Actions', dataIndex: 'actions', key: 'actions', render: (txt, record, idx) => {
			return (
				<a onClick={() => history.push('/stock-management/lot-101/edit')}>Edit</a>
			);
		} },
	];


	return (
		<React.Fragment>
			<Row gutter={[16, 16]} type={`flex`} style={{alignItems: 'flex-end', marginBottom: '8px'}}>
				<Col span={17}>&nbsp;</Col>
				<DatePickerStripShitVersion />
			</Row>
			<Table className="stock-nested-table" columns={COLUMNS} dataSource={DATA} pagination={false} scroll={{x: 1500}} />
			<SalesDrawerNew visible={isSalesDrawerVisible} onClose={() => setSalesDrawerVisible(false)} onSubmitSuccess={() => setSalesDrawerVisible(false)} />
		</React.Fragment>
	);
};

export default PurchaserDetailsTab;
