import React, {useState} from "react";
import {useHistory} from 'react-router';
import {Col, Layout, Menu, Row, Typography} from "antd";
import DashboardDisplay from "../components/dashboard/DashboardDisplay";
import InSiteDashboardFrame from "../components/frame/InSiteDashboardFrame";
import {useHotkeys} from "react-hotkeys-hook";


const DashboardPage = (props) => {
	const [current,setCurrent] = useState('all');
	const history = useHistory();
	useHotkeys("ctrl+command+p", () => {
		history.replace('/corporate')
	});

	return (
		<InSiteDashboardFrame>
			<Layout>
				<Layout.Sider width="256" theme={"light"} className={`sider-no-theme`} breakpoint="md" collapsedWidth={0}>
					<Row gutter={[16, 16]} type={`flex`} style={{marginTop: '2px'}}>
						<Col span={24}>&nbsp;</Col>
					</Row>
					<Menu onClick={(e) => setCurrent(e.key)} selectedKeys={[current]} theme={"light"}>
						<Menu.Item key="all">
							All
						</Menu.Item>
						<Menu.SubMenu title={<span className="submenu-title-wrapper">States</span>}>
							<Menu.Item key="states:vic">Victoria</Menu.Item>
							<Menu.Item key="states:qld">Queensland</Menu.Item>
							<Menu.Item key="states:nsw">New South Wales</Menu.Item>
						</Menu.SubMenu>
					</Menu>

					<Menu style={{marginTop: '24px'}}>
						<Menu.ItemGroup title="System">
							<Menu.Item key="admin" onClick={() => history.push('/admin')}>Admin Console</Menu.Item>
							<Menu.Item key="logout" onClick={() => history.replace('/')}>Logout</Menu.Item>
						</Menu.ItemGroup>
					</Menu>
				</Layout.Sider>
				<Layout.Content style={{margin: '0 16px', overflow: 'visible'}}>
					<DashboardDisplay selected={current} />
				</Layout.Content>
			</Layout>
		</InSiteDashboardFrame>
	);
};

export default DashboardPage;
