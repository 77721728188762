import React, {useState} from "react";
import {Card, Drawer, Icon, Table, Timeline} from "antd";
import ReactApexChart from 'react-apexcharts';
import Chance from 'chance';
import moment from "moment";
import {generateStarRating} from "../icons/StarIcons";

const UserJourney = (props) => {
	const TAB_LIST = [ { key: "1", tab: 'Graph' }, { key: "2", tab: 'Table' } ];
	const [currentTabKey, setCurrentTabKey] = useState("1");

	return (
		<Card type="inner" title="User Journey" tabList={TAB_LIST}  activeTabKey={currentTabKey} onTabChange={(key) => setCurrentTabKey(key)}>
			<TabContent currentTabKey={currentTabKey} />
		</Card>
	);
};

const TabContent = ({ currentTabKey }) => {
	switch (currentTabKey) {
		case "1":
			return contentGraph();
		case "2":
			return contentTable();
		default:
			return contentGraph();
	}
};

const contentGraph = (props) => {
	const GRAPH_OPTS = {

		series: [{
			name: 'Registrations',
			data: [4, 6, 2, 1, 3, 4, 3],
			type: 'column'
		}],
		options: {
			colors: ["#00adef"],
			chart: {
				height: 400,
				type: 'column',
			},
			stroke: {
				width: [0, 4]
			},
			plotOptions: {
				bar: {
					columnWidth: '75%',
					dataLabels: {
						position: 'top', // top, center, bottom
					},
				}
			},
			dataLabels: {
				enabled: true,
				enabledOnSeries: [1]
			},
			labels: ["07 Feb 2020", "08 Feb 2020", "09 Feb 2020", "10 Feb 2020", "11 Feb 2020", "12 Feb 2020", "13 Feb 2020"],
			xaxis: {
				type: 'datetime',
				tickPlacement: 'on'
			}
		}
	};

	return (
		<div>
			<ReactApexChart width={'85%'} options={GRAPH_OPTS.options} series={GRAPH_OPTS.series} type="bar" height={400} />
		</div>
	);
};

const contentTable = (props) => {
	const chance = new Chance();
	const [showJourneyDrawer, setShowJourneyDrawer] = useState(false);
	const dataGenerator = (amount=10) => {
		let buffer = [];
		for(let i = 0; i < amount; i++) {
			buffer.push({
				date: moment(chance.date()).format('Mo MMM, YYYY'),
				source: chance.pickone(['Direct', 'Social Media', 'Google', 'Adwords', 'RealEstate.com.au', 'Domain.com.au']),
				device: chance.pickone(['Personal Computer', 'Mobile', 'Tablet']),
				weight: chance.integer({min: 0, max: 5}),
				downloaded: chance.bool(),
				registered: chance.bool({ likelihood: 30 }),
			});
		}

		return buffer;
	};

	const COLUMNS = [
		{ title: 'Date', dataIndex: 'date', key: 'date' },
		{ title: 'Source', dataIndex: 'source', key: 'source' },
		{ title: 'Device', dataIndex: 'device', key: 'device' },
		{ title: 'Weight', dataIndex: 'weight', key: 'weight', render: (txt, record, idx) => {
			return generateStarRating(txt);
		} },
		{ title: 'Downloaded', dataIndex: 'downloaded', key: 'downloaded', render: (txt, record, idx) => {
			if(record.downloaded) {
				return (<Icon type="check" />);
			} else {
				return "";
			}
		} },
		{ title: 'Registered', dataIndex: 'registered', key: 'registered', render: (txt, record, idx) => {
				if(record.registered) {
					return (<Icon type="check" />);
				} else {
					return "";
				}
			} }
	];

	const DATA = dataGenerator();

	return (
		<React.Fragment>
			<Table className="insite" columns={COLUMNS} dataSource={DATA} onRow={(record) => ({
					onClick: () => {
						setShowJourneyDrawer(true);
					},
				})} />
			<Drawer width={720} title="User Journey" placement="right" closable={true} onClose={() => setShowJourneyDrawer(false)} visible={showJourneyDrawer}>
				<Timeline mode="alternate">
					<Timeline.Item>Create a services site 2015-09-01</Timeline.Item>
					<Timeline.Item color="green">Solve initial network problems 2015-09-01</Timeline.Item>
					<Timeline.Item dot={<Icon type="clock-circle-o" style={{ fontSize: '16px' }} />}>
						Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
						laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto
						beatae vitae dicta sunt explicabo.
					</Timeline.Item>
					<Timeline.Item color="red">Network problems being solved 2015-09-01</Timeline.Item>
					<Timeline.Item>Create a services site 2015-09-01</Timeline.Item>
					<Timeline.Item dot={<Icon type="clock-circle-o" style={{ fontSize: '16px' }} />}>
						Technical testing 2015-09-01
					</Timeline.Item>
					<Timeline.Item>Create a services site 2015-09-01</Timeline.Item>
					<Timeline.Item color="green">Solve initial network problems 2015-09-01</Timeline.Item>
					<Timeline.Item dot={<Icon type="clock-circle-o" style={{ fontSize: '16px' }} />}>
						Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
						laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto
						beatae vitae dicta sunt explicabo.
					</Timeline.Item>
					<Timeline.Item color="red">Network problems being solved 2015-09-01</Timeline.Item>
					<Timeline.Item>Create a services site 2015-09-01</Timeline.Item>
					<Timeline.Item dot={<Icon type="clock-circle-o" style={{ fontSize: '16px' }} />}>
						Technical testing 2015-09-01
					</Timeline.Item>
					<Timeline.Item>Create a services site 2015-09-01</Timeline.Item>
					<Timeline.Item color="green">Solve initial network problems 2015-09-01</Timeline.Item>
					<Timeline.Item dot={<Icon type="clock-circle-o" style={{ fontSize: '16px' }} />}>
						Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
						laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto
						beatae vitae dicta sunt explicabo.
					</Timeline.Item>
					<Timeline.Item color="red">Network problems being solved 2015-09-01</Timeline.Item>
					<Timeline.Item>Create a services site 2015-09-01</Timeline.Item>
					<Timeline.Item dot={<Icon type="clock-circle-o" style={{ fontSize: '16px' }} />}>
						Technical testing 2015-09-01
					</Timeline.Item>
				</Timeline>
			</Drawer>
		</React.Fragment>
	);
};

export default UserJourney;
