import React, {useState} from "react";
import InSiteFrame from "../components/frame/InSiteFrame";
import {
	Button,
	Card,
	Col,
	Descriptions,
	Icon,
	Modal,
	PageHeader,
	Row,
	Timeline,
	Radio,
	Form,
	Input,
	DatePicker,
	TimePicker,
	notification, Statistic, Select, Typography, Breadcrumb, Collapse, List, Switch, Table, AutoComplete, Text, Divider
} from "antd";
import InSitePieChart from "../components/charts/InSitePieChart";
import UpdateProfileDrawer from "../components/drawer/UpdateProfileDrawer";
import UpdateCustomerDetailsDrawer from "../components/drawer/UpdateCustomerDetailsDrawer";
import UserJourney from "../components/profile/UserJourney";
import Chance from 'chance';
import {EmptyStarIcon, FullStarIcon, HalfStarIcon} from "../components/icons/StarIcons";
import moment from "moment";
import PieChartTableView from "../components/table/general/PieChartTableView";
import SalesDrawer from "../components/drawer/SalesDrawer";
import MetaDrawer from "../components/drawer/MetaDrawer";
import SalesDrawerNew from "../components/drawer/SalesDrawerNew";

const ProfileDaveVersion = (props) => {
	const [updateDrawerVisible, setUpdateDrawerVisible] = useState(false);
	const [isUpdated, setIsUpdated] = useState(false);

	const [metaDrawerVisible, setMetaDrawerVisible] = useState(false);

	const [linkProfilesModalVisible, setLinkProfilesModalVisible] = useState(false);

	const [detailsDrawerVisible, setDetailsDrawerVisible] = useState(false);
	const [isDetailsUpdated, setIsDetailsUpdated] = useState(false);

	const [salesDrawerVisible, setSalesDrawerVisible] = useState(false);
	const updateSalesDrawerVisibility = (value) => setSalesDrawerVisible(value);

	const [isTimelineModalVisible, setIsTimelineModalVisible] = useState(false);
	const [currentTimelinePage, setCurrentTimelinePage] = useState(0);
	const [timelineTypeSelection, setTimelineTypeSelection] = useState(0);
	const [showTimelineEntryModal, setShowTimelineEntryModal] = useState(false);

	const [isFutureMeeting, setIsFutureMeeting] = useState(true);

	const [chartATabSelection, setChartATabSelection] = useState('chart_a_chart');
	const chartAContents = {
		chart_a_chart: <InSitePieChart id={`example_one`} data={[7, 8, 4, 13]} labels={['Label A', 'Label B', 'Label C', 'Label D']} />,
		chart_a_tbl: <PieChartTableView />
	};
	const [chartBTabSelection, setChartBTabSelection] = useState('chart_b_chart');
	const chartBContents = {
		chart_b_chart: <InSitePieChart id={`example_one`} data={[7, 8, 4, 13]} labels={['Label A', 'Label B', 'Label C', 'Label D']} />,
		chart_b_tbl: <PieChartTableView />
	};

	const noteColumns = [
		{ title: 'Date', dataIndex: 'date', key: 'date', render: (txt, record) => {
				return record.date.format('Do MMMM, YYYY')
			} },
		{ width: '60%', title: 'Note', dataIndex: 'note', key: 'note' },
		{ title: 'Status', dataIndex: 'status', key: 'status', render: (txt, record) => {
				let txtColor = "#000000";
				switch (txt) {
					case "Cold":
						txtColor = "#44b6ff";
						break;
					case "Warm":
						txtColor = "#ffc444";
						break;
					case "Hot":
						txtColor = "#ff4444";
						break;
					default:
						break;
				}
			return (<Typography.Text strong={true} style={{color: txtColor}}>{txt}</Typography.Text>);
		} },
		{ title: 'Sales Agent', dataIndex: 'author', key: 'author' }
	];
	const noteData = generateNoteData();

	const showTimelineModal = () => setIsTimelineModalVisible(true);
	const hideTimelineModal = () => setIsTimelineModalVisible(false);

	const [tabKey, setTabKey] = useState('reporting');
	const tabListNoTitle = [
		{
			key: 'reporting',
			tab: 'Reports',
		},
		{
			key: 'notes',
			tab: 'Notes',
		},
		{
			key: 'sales',
			tab: 'Deposits & Purchases',
		}
	];

	const contentList = {
		timeline: (
			<React.Fragment>

			</React.Fragment>
		),
		notes: <Table className={`insite`} columns={noteColumns} dataSource={noteData} pagination={{ defaultPageSize: 5 }} />,
		sales: (
			<Row gutter={[16, 16]} type={`flex`}>
				<Col span={12}>
					<Card type="inner" title="Deposits" extra={[<Icon style={{fontSize: '22px'}} key={1} type="plus-circle" onClick={() => setSalesDrawerVisible(true)} />]}>
						<List>
							<List.Item key={1} actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">delete</a>]}>
								<List.Item.Meta title={`Lot 101`} description={`Deposited $10,000AUD on 12th January, 2020`} />
							</List.Item>
							<List.Item key={2} actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">delete</a>]}>
								<List.Item.Meta title={`Lot 103`} description={`Deposited $10,000AUD on 12th January, 2020`} />
							</List.Item>
							<List.Item key={3} actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">delete</a>]}>
								<List.Item.Meta title={`Lot 106`} description={`Deposited $10,000AUD on 12th January, 2020`} />
							</List.Item>
						</List>
					</Card>
				</Col>
				<Col span={12}>
					<Card type="inner" title="Sales" extra={[<Icon style={{fontSize: '22px'}} key={1} type="plus-circle" onClick={() => setSalesDrawerVisible(true)} />]}>
						<List>
							<List.Item key={1} actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">delete</a>]}>
								<List.Item.Meta title={`Lot 102`} description={`Purchased for $123,000AUD on 13th January, 2020`} />
							</List.Item>
							<List.Item key={2} actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">delete</a>]}>
								<List.Item.Meta title={`Lot 104`} description={`Purchased for $123,000AUD on 13th January, 2020`} />
							</List.Item>
						</List>
					</Card>
				</Col>
			</Row>
		),
		reporting: (
			<React.Fragment>
				<Row gutter={[16, 16]} type={`flex`}>
					<Col span={16}>
						<Row gutter={[16, 16]} type={`flex`}>
							<Col lg={12} md={12} sm={24} xs={24}>
								<Card type="inner" title={`Land Engagement`} tabList={[{key: 'chart_a_chart', tab: 'Chart'}, {key: 'chart_a_tbl', tab: 'Table'}]} activeTabKey={chartATabSelection} onTabChange={(key) => setChartATabSelection(key)}>
									{chartAContents[chartATabSelection]}
								</Card>
							</Col>
							<Col lg={12} md={12} sm={24} xs={24}>
								<Card type="inner" title={`Package Engagement`}  tabList={[{key: 'chart_b_chart', tab: 'Chart'}, {key: 'chart_b_tbl', tab: 'Table'}]} activeTabKey={chartBTabSelection} onTabChange={(key) => setChartBTabSelection(key)}>
									{chartBContents[chartBTabSelection]}
								</Card>
							</Col>
						</Row>
						<Row gutter={[16, 16]} type={`flex`}>
							<Col span={24}>
								<UserJourney/>
							</Col>
						</Row>
					</Col>
					<Col span={8}>
						<Row gutter={[16, 16]} type={`flex`} style={{margin: 0}}>
							<Card type="inner" title="Timeline" style={{width: '100%'}} extra={[
								<Select key={1} mode="multiple" style={{width: '200px', marginRight: '8px'}} placeholder="Filter Timeline">
									<Select.Option key={(new Chance()).string({ length: 5 })} value="note">Note</Select.Option>
									<Select.Option key={(new Chance()).string({ length: 5 })} value="call">Call</Select.Option>
									<Select.Option key={(new Chance()).string({ length: 5 })} value="email">Email</Select.Option>
									<Select.Option key={(new Chance()).string({ length: 5 })} value="meeting">Meeting</Select.Option>
									<Select.Option key={(new Chance()).string({ length: 5 })} value="eDM">eDM</Select.Option>
									<Select.Option key={(new Chance()).string({ length: 5 })} value="Download">Download</Select.Option>
									<Select.Option key={(new Chance()).string({ length: 5 })} value="Registration">Registration</Select.Option>
								</Select>
							]}>
								<Timeline mode="alternate" className="profile-timeline" style={{margin: '32px auto 0 auto', width: '60%'}}>
									{generateTimelineMockData().map((entry, idx) => {
										return (
											<Timeline.Item key={idx} dot={getTimelineIcon(entry.type, entry.actionType)} onClick={() => setShowTimelineEntryModal(true)}>
												<Typography.Text type="secondary" style={{fontStyle: 'italic'}}>{entry.date.format('YYYY-MM-DD')}</Typography.Text><br/>
												<Typography.Text>{entry.content}</Typography.Text>
											</Timeline.Item>
										)
									})}
								</Timeline>
							</Card>
						</Row>
					</Col>
				</Row>
			</React.Fragment>
		)
	}

	return (
		<InSiteFrame>
			<Row gutter={[16, 16]} type={`flex`} style={{alignItems: 'flex-end'}}>
				<Col span={24}>
					<Breadcrumb style={{alignItems: 'flex-end'}}>
						<Breadcrumb.Item href={`/leads`}>Leads Report</Breadcrumb.Item>
						<Breadcrumb.Item>Example Profile (id: 1234)</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			<Row gutter={[16, 16]} type={`flex`}>
				<Col span={8}>
					<PageHeader
						style={{marginBottom: '8px', height: '100%'}}
						className="insite"
						ghost={false}
						onBack={() => window.history.back()}
						title="Example Profile"
						subTitle={<Typography.Text style={{fontSize: '16px'}} strong>(<span style={{color: '#ffc444'}}>Warm</span>)</Typography.Text>}
						extra={[
							<Button key="1" type="primary" onClick={() => setUpdateDrawerVisible(true)}>
								Update Profile
							</Button>,
							<Button key="2" type="primary" onClick={() => setLinkProfilesModalVisible(true)}>
								Link Profiles
							</Button>,
						]}
					>
						<Row gutter={[16, 16]} type={`flex`} style={{marginBottom: '8px'}}>
							<Col span={24}>
								<FullStarIcon style={{width: '2em', height: '2em'}} />
								<FullStarIcon style={{width: '2em', height: '2em'}} />
								<FullStarIcon style={{width: '2em', height: '2em'}} />
								<HalfStarIcon style={{width: '2em', height: '2em'}} />
								<EmptyStarIcon style={{width: '2em', height: '2em'}} />
							</Col>
						</Row>
						<Descriptions className="insite" size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
							<Descriptions.Item label="Email Address">example@insitelogic.com.au</Descriptions.Item>
							<Descriptions.Item label="First Visit">28th April, 2020</Descriptions.Item>
							<Descriptions.Item label="Sales Agent">Example Agent</Descriptions.Item>
							<Descriptions.Item label="Registration">28th April, 2020</Descriptions.Item>
							<Descriptions.Item label="Phone Number">0400 000 000</Descriptions.Item>

							<Descriptions.Item label="Last Active">28th April, 2020</Descriptions.Item>
						</Descriptions>
						<List bordered={true} header={"Linked Profiles"} style={{ marginTop: '32px' }}>
							<List.Item actions={[<a>View Profile</a>, <a>Delete</a>]}><Typography.Text mark>[CO-INVESTOR]</Typography.Text> Person One</List.Item>
							<List.Item actions={[<a>View Profile</a>, <a>Delete</a>]}><Typography.Text mark>[CO-INVESTOR]</Typography.Text> Person Two</List.Item>
						</List>
					</PageHeader>
				</Col>
				<Col span={16}>
					<Card extra={<Button type="primary" onClick={() => setMetaDrawerVisible(true)}>Update Customer Information</Button>}>
						<Row gutter={[16, 16]} type={`flex`}>
							<Divider>Online Activity</Divider>
							<Col lg={6} md={6} sm={24} xs={24}>
								<Statistic title="Total Website Visits" formatter={(value) => value} value={(new Chance()).integer({min: 0, max: 9})} />
							</Col>

							<Col lg={6} md={6} sm={24} xs={24}>
								<Statistic title="Registration Source" formatter={(value) => value} value={'Online'} />
							</Col>

							<Col lg={6} md={6} sm={24} xs={24}>
								<Statistic title="Initial Traffic Source" formatter={(value) => value} value={'Google Search'} />
							</Col>

							<Col lg={6} md={6} sm={24} xs={24}>
								<Statistic title="Devices Recorded" formatter={(value) => {
									return value.map((entries, idx) => <Typography.Text key={idx} style={{display: 'block', color: 'black'}}><Icon type={entries.icon} />&nbsp;{entries.type} x {entries.count}</Typography.Text>)
								}} value={[{icon: 'mobile', count: '5', type: 'Mobile'}, {icon: 'desktop', count: '3', type: 'Desktop'}]} />
							</Col>

							<Divider>Customer Information</Divider>
							<Col lg={8} md={8} sm={24} xs={24}>
								<Statistic title="Postcode" formatter={(value) => value} value={Array(4).fill().map((_) => (new Chance()).integer({min: 0, max: 9})).join("")} />
							</Col>
							<Col lg={8} md={8} sm={24} xs={24}>
								<Statistic title="Buyer Type" value={(new Chance()).pickone(["First Home", "Up-sizer", "Down-sizer", "Investor", "Builder"])} />
							</Col>
							<Col lg={8} md={8} sm={24} xs={24}>
								<Statistic title="Referral Source" value={(new Chance()).pickone(["Project Website", "Social Media", "realestate.com.au", "Signage", "Google Search", "Google Adwords", "Builder Referral", "Newspaper", "Family & Friends Referral", "Radio/TV", "Repeat Purchaser", "Walk-in"])} />
							</Col>
							<Col lg={8} md={8} sm={24} xs={24}>
								<Statistic title="Land Budget" value={(new Chance()).pickone(["$150,000 - $200,000", "$200,000 - $250,000", "$250,000 - $300,000", "$300,000 - $350,000", "$350,000 - $400,000", "$400,000 - $450,000", "$450,000 - $500,000", "$500,000 - $550,000", "$550,000 - $600,000", "$600,000 - $650,000", "$650,000 - $750,000", "$700,000+"])} />
							</Col>
							<Col lg={8} md={8} sm={24} xs={24}>
								<Statistic title="House & Land Budget" value={(new Chance()).pickone(["$300,000 - $350,000", "$350,000 - $400,000", "$400,000 - $450,000", "$450,000 - $500,000", "$500,000 - $550,000", "$550,000 - $600,000", "$600,000 - $650,000", "$650,000 - $750,000", "$700,000+"])} />
							</Col>
							<Col lg={8} md={8} sm={24} xs={24}>
								<Statistic title="Land Size" value={(new Chance()).pickone(["< 300m2", "300m2 - 350m2", "350m2 - 400m2", "400m2 - 450m2", "450m2 - 500m2", "500m2 - 550m2", "550m2 - 600m2", "600m2+"])} />
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			<Row gutter={[16, 16]} type={`flex`}>
				<Col span={24}>
					<Card tabList={tabListNoTitle} activeTabKey={tabKey} onTabChange={(key) => setTabKey(key)} tabBarExtraContent={<Button style={{width: '100%'}} key={2} type="primary" onClick={showTimelineModal}>Add Timeline Entry</Button>}>
						{contentList[tabKey]}
					</Card>
				</Col>
			</Row>
			<UpdateCustomerDetailsDrawer visible={detailsDrawerVisible} onClose={() => setDetailsDrawerVisible(false)} submitSuccess={isDetailsUpdated} onSubmitSuccess={() => setIsDetailsUpdated(true)} clearSubmitSuccess={() => setIsDetailsUpdated(false)} />
			<UpdateProfileDrawer visible={updateDrawerVisible} onClose={() => setUpdateDrawerVisible(false)} submitSuccess={isUpdated} onSubmitSuccess={() => setIsUpdated(true)} clearSubmitSuccess={() => setIsUpdated(false)} />
			<SalesDrawerNew visible={salesDrawerVisible} onClose={() => setSalesDrawerVisible(false)} onSubmitSuccess={() => { setSalesDrawerVisible(false); notification['success']({message: 'Added Sales/Deposit Entry', description: 'Lead status will be updated to reflect the deposit/sale.'}) }} />
			<MetaDrawer visible={metaDrawerVisible} onClose={() => setMetaDrawerVisible(false)} onSubmitSuccess={() => { setMetaDrawerVisible(false); notification['success']({message: 'Updated Customer Information', description: 'Customer Information Updated'}) }} />
			<Modal width={720} title="Add Timeline Entry" visible={isTimelineModalVisible} onOk={currentTimelinePage !== 1  ? () => { setCurrentTimelinePage(currentTimelinePage + 1) } : () => { setCurrentTimelinePage(0); hideTimelineModal(); notification['success']({message: 'Timeline Entry Added', description: 'Timeline has been updated with the message "Lorem Ipsum" on the 10th Feb, 2020.'}) }} onCancel={() => { setCurrentTimelinePage(0); hideTimelineModal(); }} okText={currentTimelinePage !== 1 ? "Next" : "Finish"}>
				{showTimelineFormContents(currentTimelinePage, timelineTypeSelection, setTimelineTypeSelection, isFutureMeeting, setIsFutureMeeting)}
			</Modal>
			<Modal width={720} title="Link Profiles" visible={linkProfilesModalVisible} onOk={() => { setLinkProfilesModalVisible(false); notification['success']({message: 'Linked Profiles', description: 'Profiles have been linked'}); }} onCancel={() => setLinkProfilesModalVisible(false)}>
				<Form>
					<Form.Item label={"Current Profile Name"}>
						<Input value="Example Profile" disabled={true} />
					</Form.Item>
					<Form.Item label={"Current Profile Id"}>
						<Input value="1234" disabled={true} />
					</Form.Item>
					<Form.Item label={"Select Profile to Merge With"}>
						<AutoComplete placeholder={`Search for related profile`} dataSource={Array(25).fill().map((_) => {
							return (new Chance()).name({nationality: 'en'})
						})} filterOption={(inputValue, option) =>
							option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
						} />
					</Form.Item>
					<Form.Item label={"What is the Relationship?"}>
						<Select>
							<Select.Option key={1} value={"Married"}>Married</Select.Option>
							<Select.Option key={2} value={"Partner"}>Partner</Select.Option>
							<Select.Option key={3} value={"Parent"}>Parent</Select.Option>
							<Select.Option key={4} value={"Child"}>Child</Select.Option>
							<Select.Option key={5} value={"Roomate"}>Roomate</Select.Option>
							<Select.Option key={6} value={"Co-Investor"}>Co-Investor</Select.Option>
						</Select>
					</Form.Item>
				</Form>
			</Modal>
			<Modal width={720} title="Timeline Entry" visible={showTimelineEntryModal} footer={[
				<Button key="back" onClick={() => setShowTimelineEntryModal(false)}>
					Close
				</Button>
			]}>
				<Row type={`flex`}>
					<Col span={3}>
						{getTimelineIcon(chance.pickone(['note', 'phone', 'email', 'meeting', 'edm', 'download', 'visit']), 'inbound', '44px')}
					</Col>
					<Col span={21}>
						<Typography.Title>{chance.sentence({ words: 3 })}</Typography.Title>
					</Col>
					<Col span={24}>
						<Typography.Text type="secondary" style={{fontStyle: "italic", display: 'block', marginBottom: '12px'}}>{moment(chance.date({string: true, american: false}), "D/M/YYYY").format('YYYY-MM-DD')}</Typography.Text>
					</Col>
					<Col span={24}>
						<Typography.Text>{chance.paragraph({sentences: 4})}</Typography.Text>
					</Col>
				</Row>
			</Modal>
		</InSiteFrame>
	);
};

function showTimelineFormContents(currentPage, timelineTypeSelection, setTimelineTypeSelection, isFutureMeeting, setIsFutureMeeting) {
	if(currentPage === 0) {
		return addEntryFormOne(setTimelineTypeSelection);
	} else if(currentPage === 1) {
		switch (timelineTypeSelection) {
			case "call-incoming":
				return addCall();
			case "call-outgoing":
				return addCall();
			case "email":
				return addEmail();
			case "meeting":
				return addMeeting(isFutureMeeting, setIsFutureMeeting);
			case "sms":
				return addSms();
			case "reminder":
				return addReminder();
			case "note":
				return addNote();
			default:
				return addEntryFormTwo();
		}

	}
}

function addEntryFormOne(setTimelineTypeSelection) {
	return (
		<React.Fragment>
			<h1>Select Entry Type</h1>
			<Radio.Group onChange={(e) => { setTimelineTypeSelection(e.target.value) }}>
				<Radio style={{ display: 'block', height: '30px', lineHeight: '30px' }} value="note">Note</Radio>
				<Radio style={{ display: 'block', height: '30px', lineHeight: '30px' }} value="call-incoming">Call Incoming</Radio>
				<Radio style={{ display: 'block', height: '30px', lineHeight: '30px' }} value="call-outgoing">Call Outgoing</Radio>
				<Radio style={{ display: 'block', height: '30px', lineHeight: '30px' }} value="email">Email</Radio>
				<Radio style={{ display: 'block', height: '30px', lineHeight: '30px' }} value="meeting">Meeting</Radio>
				<Radio style={{ display: 'block', height: '30px', lineHeight: '30px' }} value="sms">Text Message</Radio>
				<Radio style={{ display: 'block', height: '30px', lineHeight: '30px' }} value="reminder">Add Reminder</Radio>
			</Radio.Group>
		</React.Fragment>
	);
}

function addEntryFormTwo() {
	return (
		<React.Fragment>
			<h1>Enter Details</h1>
			<Form>
				<Form.Item>
					<Input placeholder="Enter a subject" />
				</Form.Item>
				<Row gutter={[16, 16]} type={`flex`}>
					<Col span={12}>
						<Form.Item>
							<DatePicker style={{width: "100%"}} />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item>
							<TimePicker style={{width: "100%"}} />
						</Form.Item>
					</Col>
				</Row>
				<Form.Item>
					<Input.TextArea rows={4} placeholder={"Enter a message"} />
				</Form.Item>
			</Form>
		</React.Fragment>
	);
}

function addCall() {
	return (
		<React.Fragment>
			<h1>Enter Details</h1>
			<Form>
				<Form.Item label={`Select Lead Status`} style={{marginTop: '8px'}}>
					<Radio.Group style={{display: 'flex'}}>
						<Radio style={{flexGrow: '1'}} value={`Cold`}>Cold</Radio>
						<Radio style={{flexGrow: '1'}} value={`Warm`}>Warm</Radio>
						<Radio style={{flexGrow: '1'}} value={`Hot`}>Hot</Radio>
						<Radio style={{flexGrow: '1'}} value={`Builder`}>Builder</Radio>
						<Radio style={{flexGrow: '1'}} value={`Archived`}>Archived</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label={`Note Contents`} style={{marginTop: '8px'}}>
					<Input.TextArea rows={4} placeholder={"What did you discuss?"} />
				</Form.Item>
			</Form>
		</React.Fragment>
	);
}

function addNote() {
	return (
		<React.Fragment>
			<h1>Enter Details</h1>
			<Form>
				<Form.Item label={`Select Lead Status`} style={{marginTop: '8px'}}>
					<Radio.Group style={{display: 'flex'}}>
						<Radio style={{flexGrow: '1'}} value={`Cold`}>Cold</Radio>
						<Radio style={{flexGrow: '1'}} value={`Warm`}>Warm</Radio>
						<Radio style={{flexGrow: '1'}} value={`Hot`}>Hot</Radio>
						<Radio style={{flexGrow: '1'}} value={`Builder`}>Builder</Radio>
						<Radio style={{flexGrow: '1'}} value={`Archived`}>Archived</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label={`Note Contents`} style={{marginTop: '8px'}}>
					<Input.TextArea rows={4} placeholder={"Enter note details"} />
				</Form.Item>
			</Form>
		</React.Fragment>
	);
}

function addEmail() {
	return (
		<React.Fragment>
			<h1>Enter Details</h1>
			<Form>
				<Form.Item label={`Select Lead Status`} style={{marginTop: '8px'}}>
					<Radio.Group style={{display: 'flex'}}>
						<Radio style={{flexGrow: '1'}} value={`Cold`}>Cold</Radio>
						<Radio style={{flexGrow: '1'}} value={`Warm`}>Warm</Radio>
						<Radio style={{flexGrow: '1'}} value={`Hot`}>Hot</Radio>
						<Radio style={{flexGrow: '1'}} value={`Builder`}>Builder</Radio>
						<Radio style={{flexGrow: '1'}} value={`Archived`}>Archived</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label={`Send To`} style={{marginBottom: '8px'}}>
					<Input value={`example@insitelogic.com.au`} disabled={true} />
				</Form.Item>
				<Form.Item label={`From Name`} style={{marginBottom: '8px'}}>
					<Input value={`Example Agent`} disabled={true} />
				</Form.Item>
				<Form.Item label={`From Email Address`} style={{marginBottom: '8px'}}>
					<Input value={`example.agent@insitelogic.com.au`} disabled={true} />
				</Form.Item>
				<Form.Item label={`Select email template`} style={{marginBottom: '8px'}}>
					<Select placeholder={`Select email template`}>
						<Select.Option value="1">Example Template One</Select.Option>
						<Select.Option value="2">Example Template Two</Select.Option>
						<Select.Option value="3">Example Template Three</Select.Option>
						<Select.Option value="4">Example Template Four</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item label={"Enter Email Contents"} style={{marginBottom: '8px'}}>
					<Input.TextArea rows={4} placeholder={"Enter Email Contents"} />
				</Form.Item>
			</Form>
		</React.Fragment>
	);
}

function addMeeting(isFutureMeeting, setIsFutureMeeting) {
	return (
		<React.Fragment>
			<h1>Enter Details</h1>
			<Form>
				<Form.Item label={`Select Lead Status`} style={{marginTop: '8px'}}>
					<Radio.Group style={{display: 'flex'}}>
						<Radio style={{flexGrow: '1'}} value={`Cold`}>Cold</Radio>
						<Radio style={{flexGrow: '1'}} value={`Warm`}>Warm</Radio>
						<Radio style={{flexGrow: '1'}} value={`Hot`}>Hot</Radio>
						<Radio style={{flexGrow: '1'}} value={`Builder`}>Builder</Radio>
						<Radio style={{flexGrow: '1'}} value={`Archived`}>Archived</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label={`Is this a future scheduled meeting?`} style={{marginBottom: '8px'}} extra={`Future meetings will be added to your calendar and todo tasks.`}>
					<Switch
						onChange={(isChecked) => {setIsFutureMeeting(isChecked)}}
						checkedChildren={<Icon type="check" />}
						unCheckedChildren={<Icon type="close" />}
						defaultChecked />
				</Form.Item>
				<Form.Item label={`Select Meeting Date`} style={{marginBottom: '8px'}}>
					<DatePicker style={{width: '100%'}} defaultValue={moment()} disabled={!isFutureMeeting} />
				</Form.Item>
				<Form.Item label={`Select Meeting Time`} style={{marginBottom: '8px'}}>
					<TimePicker style={{width: '100%'}} format={"hh:mm a"} defaultValue={moment()} />
				</Form.Item>
				<Form.Item label={`What did you discuss in the meeting?`}>
					<Input.TextArea rows={4} placeholder={"What did you discuss in the meeting?"} />
				</Form.Item>
			</Form>
		</React.Fragment>
	);
}

function addSms() {
	return (
		<React.Fragment>
			<h1>Enter Details</h1>
			<Form>
				<Form.Item label={`Select Lead Status`} style={{marginTop: '8px'}}>
					<Radio.Group style={{display: 'flex'}}>
						<Radio style={{flexGrow: '1'}} value={`Cold`}>Cold</Radio>
						<Radio style={{flexGrow: '1'}} value={`Warm`}>Warm</Radio>
						<Radio style={{flexGrow: '1'}} value={`Hot`}>Hot</Radio>
						<Radio style={{flexGrow: '1'}} value={`Builder`}>Builder</Radio>
						<Radio style={{flexGrow: '1'}} value={`Archived`}>Archived</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label={`Sender Phone Number`} style={{marginBottom: '8px'}}>
					<Input type={'phone'} value={'+61431 123 456'} disabled={true} />
				</Form.Item>
				<Form.Item label={`Recipient Phone Number`} style={{marginBottom: '8px'}}>
					<Input type={'phone'} value={'0400 000 000'} disabled={true} />
				</Form.Item>
				<Form.Item label={`Enter Message Contents`} extra={"Messages over 140 characters will incur extra charges."}>
					<Input.TextArea rows={4} placeholder={"Enter Message Contents"} />
				</Form.Item>
			</Form>
		</React.Fragment>
	);
}

function addReminder() {
	return (
		<React.Fragment>
			<h1>Enter Details</h1>
			<Form>
				<Form.Item label={`Select Lead Status`} style={{marginTop: '8px'}}>
					<Radio.Group style={{display: 'flex'}}>
						<Radio style={{flexGrow: '1'}} value={`Cold`}>Cold</Radio>
						<Radio style={{flexGrow: '1'}} value={`Warm`}>Warm</Radio>
						<Radio style={{flexGrow: '1'}} value={`Hot`}>Hot</Radio>
						<Radio style={{flexGrow: '1'}} value={`Builder`}>Builder</Radio>
						<Radio style={{flexGrow: '1'}} value={`Archived`}>Archived</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label={`Select Reminder Date`} style={{marginBottom: '8px'}}>
					<DatePicker style={{width: '100%'}} defaultValue={moment()} />
				</Form.Item>
				<Form.Item label={`Select Reminder Time`} style={{marginBottom: '8px'}}>
					<TimePicker style={{width: '100%'}} format={"hh:mm a"} defaultValue={moment()} />
				</Form.Item>
				<Form.Item label={`Reminder Contents`} extra={"A reminder will be added to your calendar and todo list if it is enabled."}>
					<Input.TextArea rows={4} placeholder={"Reminder Contents"} />
				</Form.Item>
			</Form>
		</React.Fragment>
	);
}

function generateTimelineMockData(amount=10) {
	const chance = new Chance();
	return Array(amount).fill().map((_, idx) => {
		let type = chance.pickone(['note', 'phone', 'email', 'meeting', 'edm', 'download', 'visit']);
		let date = moment(chance.date({string: true, american: false}), "D/M/YYYY");
		let content = chance.paragraph({sentences: 1});
		let actionType = chance.pickone(['inbound', 'outbound']);

		return {
			key: idx,
			type,
			date,
			content,
			actionType
		}
	});
}

function getTimelineIcon(type, actionType="inbound", sizeOverride='24px') {
	const INBOUND_COLOR = '#b7eb8f';
	const OUTBOUND_COLOR = '#1890ff';

	let icon = <Icon type="info-circle" style={{ fontSize: sizeOverride }} />;
	let actionTypeColor = INBOUND_COLOR
	if(actionType !== 'inbound') {
		actionTypeColor = OUTBOUND_COLOR
	}

	switch (type.toLowerCase()) {
		case 'note':
			icon = <Icon type="form" style={{ fontSize: sizeOverride }} />;
			break;
		case 'phone':
			icon = <Icon type="phone" style={{ fontSize: sizeOverride, color: OUTBOUND_COLOR }} />;
			break;
		case 'email':
			icon = <Icon type="mail" style={{ fontSize: sizeOverride, color: actionTypeColor }} />;
			break;
		case 'meeting':
			icon = <Icon type="team" style={{ fontSize: sizeOverride }} />;
			break;
		case 'edm':
			icon = <Icon type="copy" style={{ fontSize: sizeOverride, color: actionTypeColor }} />;
			break;
		case 'download':
			icon = <Icon type="download" style={{ fontSize: sizeOverride, color: INBOUND_COLOR }} />;
			break;
		case 'visit':
			icon = <Icon type="global" style={{ fontSize: sizeOverride, color: INBOUND_COLOR }} />;
			break;
		case 'click':
			icon = <Icon type="link" style={{ fontSize: sizeOverride, color: INBOUND_COLOR }} />;
			break;
		default:
			break;
	}

	return icon;
}

const generateNoteData = (amount=10) => {
	const chance = new Chance();
	return Array(amount).fill().map((_, idx) => {
		let date = moment(chance.date({string: true, american: false}), "D/M/YYYY")
		let note = chance.paragraph();
		let status = chance.pickone(["Cold", "Warm", "Hot"]);
		let author = chance.name({ nationality: 'en' });
		return { key: idx, note, status, date, author };
	});
};

export default ProfileDaveVersion;
