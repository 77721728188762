import {Button, Card, Col, DatePicker, Form, Icon, Input, InputNumber, PageHeader, Row, Switch} from "antd";
import React, {useState} from "react";

const EditSalesDetailsAltForm = ({ onNextClick, onPrevClick }) => {
	const [hasRebate, setHasRebate] = useState(false);

	return (
		<Row gutter={[16, 16]} type={`flex`}>
			<Col span={24}>
				<Card>
					<PageHeader className="inv-management-header" style={{padding: 0}} title={`Lot 101`} extra={[
						<Button type="default" onClick={() => onPrevClick()}>Back</Button>,
						<Button type="primary" onClick={() => onNextClick()}>Next</Button>
					]} />
				</Card>
			</Col>
			<Col span={12}>
				<Card title="Deposit Details">
					<Form>
						<Form.Item label={`Deposit Total`}>
							<Row gutter={[16, 16]} type={`flex`}>
								<Col span={12}>
									<Input prefix="$" value="33,000" disabled />
								</Col>
								<Col span={4}>
									<span>Which is: </span>
								</Col>
								<Col span={4}>
									<Input min={5} max={100} value={10} type="number" suffix="%" />
								</Col>
								<Col span={4}>
									<span>of the sales price</span>
								</Col>
							</Row>
						</Form.Item>

						<Row gutter={[16, 16]} type={`flex`}>
							<Col span={8}>
								<Form.Item label="Initial Deposit" help={<span>Receipt Number: <Input size="small" /></span>}>
									<Input prefix="$" />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label="Outstanding Deposit" help={<span>Receipt Number: <Input size="small" /></span>}>
									<Input prefix="$" value="32,000" disabled />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label="Deposit Due Date">
									<DatePicker style={{width: '100%'}} />
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Card>
			</Col>
			<Col span={12}>
				<Card title="Sales Details">
					<Row gutter={[16, 16]} type={`flex`}>
						<Col span={12}>
							<Form.Item label={`Sale Price`}>
								<Input prefix="$" value="330,000" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={`List Price`}>
								<Input prefix="$" value="330,000" disabled />
							</Form.Item>
						</Col>
					</Row>

					<Form.Item label={`Rebate / Discount`}>
						<Switch checkedChildren={<Icon type="check" />} unCheckedChildren={<Icon type="close" />} onChange={(checked) => setHasRebate(checked)} />
						<Row gutter={[16, 16]} type={`flex`}>
							<Col span={8}>
								<Form.Item label="Amount">
									<Input prefix="$" disabled={!hasRebate} />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label="Reason">
									<Input disabled={!hasRebate} />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label="Approved By">
									<Input disabled={!hasRebate} />
								</Form.Item>
							</Col>
						</Row>
					</Form.Item>

					<Row gutter={[16, 16]} type={`flex`}>
						<Col span={12}>
							<Form.Item label="Outstanding Settlement">
								<Input prefix="$" value="297,000" disabled />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Settlement Date">
								<DatePicker style={{width: '100%'}} />
							</Form.Item>
						</Col>
					</Row>
				</Card>
			</Col>
		</Row>
	);
}

export default EditSalesDetailsAltForm;
