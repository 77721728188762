import {Card, Col, Table} from "antd";
import React from "react";
import moment from "moment";

const AgentActivityTable = (props) => {
	let columns = [
		{ key: 'agent', dataIndex: 'agent', title: 'Agent Name' },
		{ key: 'title', dataIndex: 'title', title: 'Title' },
		{ key: 'sales', dataIndex: 'sales', title: 'Sales' },
		{ key: 'value', dataIndex: 'value', title: 'Sales Amount' },
		{ key: 'listings', dataIndex: 'listings', title: 'Listings' },
		{ key: 'lots', dataIndex: 'lots', title: 'Total Lots' },
		{ key: 'lastAccessed', dataIndex: 'lastAccessed', title: 'Last Accessed', render: (txt) => {
			return {
				props: {
					style: {
						background: '#aaffaa'
					}
				},
				children: (
					<div style={{textAlign: 'center'}}>
						{txt}
					</div>
				)
			};
		} },
	];

	let tableData = [
		{ agent: 'John Smith', title: 'Sales Manager', sales: '2', value: '$5,810,000', listings: '6', lots: '23', lastAccessed:  moment(chance.date()).format('Mo MMM, YYYY') },
		{ agent: 'Ben Brown', title: 'Sales Agent', sales: '1', value: '$3,242,000', listings: '5', lots: '11', lastAccessed:  moment(chance.date()).format('Mo MMM, YYYY') },
		{ agent: 'Alf Long', title: 'Sales Agent', sales: '5', value: '$2,643,0000', listings: '3', lots: '16', lastAccessed:  moment(chance.date()).format('Mo MMM, YYYY') },
		{ agent: 'Amy Burger', title: 'Sales Agent', sales: '8', value: '$1,664,000', listings: '5', lots: '21', lastAccessed:  moment(chance.date()).format('Mo MMM, YYYY') },
	];

	return (
		<Card title="Agents">
			<Table className="insite" dataSource={tableData} columns={columns} />
		</Card>
	);
};

export default AgentActivityTable;
