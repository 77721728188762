import React, {useState} from 'react';
import {Breadcrumb, Button, Card, Col, DatePicker, Row, Select, Statistic, Table, Title, Typography} from 'antd';

import InSitePieChart from '../charts/InSitePieChart';
import moment from "moment";
import DashboardProjectTile from "./DashboardProjectTile";
import Chance from "chance";
import {DatePickerStripShitVersion} from "../datepicker/Datepicker";
import formatMoney from "../../util/formatMoney";

const DashboardDisplay = (props) => {
	const chance = new Chance();
	const [, forceUpdate] = React.useState(0);
	const [salesTab, setSalesTab] = React.useState('sales.chart');
	const [listingTab, setListingTab] = React.useState('listing.chart');
	const [referrerTab, setReferrerTab] = React.useState('referrer.chart');

	const graphTabLists = {
		SALES: [ {key: 'sales.chart', tab: 'Chart'}, {key: 'sales.table', tab: 'Table'} ],
		LISTINGS: [ {key: 'listing.chart', tab: 'Chart'}, {key: 'listing.table', tab: 'Table'} ],
		REFERRER: [ {key: 'referrer.chart', tab: 'Chart'}, {key: 'referrer.table', tab: 'Table'} ]
	};

	const contentList = {
		"sales.chart": (
			<InSitePieChart width="75%" position="right" id={`example_one`} data={[7, 8, 4, 13]} labels={['Richmond', 'Kensington', 'Elwood', 'St. Kilda']} />
		),
		"listing.chart": (
			<InSitePieChart width="75%" position="right" id={`example_one`} data={[15, 9, 2, 4]} labels={['Richmond', 'Kensington', 'Elwood', 'St. Kilda']} />
		),
		"referrer.chart": (
			<InSitePieChart width="75%" position="right" id={`example_one`} data={[32, 17, 112, 187, 15, 36, 35]} labels={['Direct', 'REA', 'Social', 'Google', 'Google Adwords', 'Domain', 'Others']} />
		),
		"sales.table": (
			<Table
				className="insite"
				dataSource={[
					{ key: '1', agent: 'Richmond', total: 7 },
					{ key: '2', agent: 'Kensington', total: 8 },
					{ key: '3', agent: 'Elwood', total: 4 },
					{ key: '4', agent: 'St. Kilda', total: 13 },
				]}
				columns={[
					{ title: 'Agent', dataIndex: 'agent', key: '1' },
					{ title: 'Total', dataIndex: 'total', key: '2' },
				]} />
		),
		"listing.table": (
			<Table
				className="insite"
				pagination={{ defaultPageSize: 5 }}
				dataSource={
					((arr1, arr2) => arr1.map((k, i) => [k, arr2[i]]))([15, 9, 2, 4], ['Richmond', 'Kensington', 'Elwood', 'St. Kilda'])
						.reduce((accum, curr, idx) => {
							let tmp = { key: idx, agent: curr[1], total: curr[0] }
							accum.push(tmp);
							return accum;
						}, [])
				}
				columns={[
					{ title: 'Agent', dataIndex: 'agent', key: '1' },
					{ title: 'Total', dataIndex: 'total', key: '2' },
			]} />
		),
		"referrer.table": (
			<Table
				className="insite"
				dataSource={
					((arr1, arr2) => arr1.map((k, i) => [k, arr2[i]]))([32, 17, 112, 187, 15, 36, 35], ['Direct', 'REA', 'Social', 'Google', 'Google Adwords', 'Domain', 'Others'])
					.reduce((accum, curr, idx) => {
						let tmp = { key: idx, referrer: curr[1], total: curr[0] }
						accum.push(tmp);
						return accum;
					}, [])
			} columns={[
				{ title: 'Referrer Source', dataIndex: 'referrer', key: '1' },
				{ title: 'Total', dataIndex: 'total', key: '2' },
			]} />
		),
	};

	return (
		<>
			<Row gutter={[16, 16]} type={`flex`} style={{alignItems: 'flex-end'}}>
				<Col span={17}>
					<DashboardBreadcrumbs selected={props.selected} />
				</Col>
				<DatePickerStripShitVersion hasCustom={false} forceUpdate={forceUpdate} />
			</Row>
			<Row gutter={[16, 16]} type={`flex`}>
				<Col lg={8} md={8} sm={24} xs={24}>
					<Card title={"Sales (August 2020)"}>
						<Row gutter={16}>
							<Col span={12} style={{textAlign:'center'}}>
								<Statistic title="Sales" value="234" />
							</Col>
							<Col span={12} style={{textAlign:'center'}}>
								<Statistic title="Value" value="69,660,000" />
							</Col>
						</Row>
					</Card>
				</Col>

				<Col lg={8} md={8} sm={24} xs={24}>
					<Card title='Listings (August 2020)'>
						<Row gutter={16}>
							<Col span={12} style={{textAlign:'center'}}>
								<Statistic title="Listings" value="32" />
							</Col>
							<Col span={12} style={{textAlign:'center'}}>
								<Statistic title="Prev. Month" value="112" />
							</Col>

						</Row>
					</Card>
				</Col>

				<Col lg={8} md={8} sm={24} xs={24}>
					<Card title='Website'>
						<Row gutter={16}>
							<Col span={12} style={{textAlign:'center'}}>
								<Statistic title="Visits" value="31,693" />
							</Col>
							<Col span={12} style={{textAlign:'center'}}>
								<Statistic title="Views" value="434" />
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			<Row gutter={[16, 16]} type={`flex`}>
				<Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
					<Card title='Sales' tabList={graphTabLists.SALES} activeTabKey={salesTab} onTabChange={(key) => setSalesTab(key)}>
						{contentList[salesTab]}
					</Card>
				</Col>
				<Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
					<Card title='Listings' tabList={graphTabLists.LISTINGS} activeTabKey={listingTab} onTabChange={(key) => setListingTab(key)}>
						{contentList[listingTab]}
					</Card>
				</Col>
				<Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
					<Card title='Referrer Source' tabList={graphTabLists.REFERRER} activeTabKey={referrerTab} onTabChange={(key) => setReferrerTab(key)}>
						{contentList[referrerTab]}
					</Card>
				</Col>
			</Row>

			<div>
				<Row gutter={[16, 16]} type={`flex`}>
					<DashboardProjectTile title={'Biggins & Scott Richmond'} ytdSales={72} ytdListings={128} currSales={14} currListings={18} />
					<DashboardProjectTile title={'Biggins & Scott Kensington'} ytdSales={68} ytdListings={106} currSales={13} currListings={11} />
				</Row>
			</div>
		</>
	);
};

const DashboardTitle = ({ selected }) => {
	let txt = 'All Data';
	switch (selected) {
		case 'land:all':
			txt = 'Land - All Data';
			break;
		case 'land:vic':
			txt = 'Land - Victoria';
			break;
		case 'land:qld':
			txt = 'Land - Queensland';
			break;
		case 'land:nsw':
			txt = 'Land - New South Wales';
			break;
		case 'apt:all':
			txt = 'Apartments - All Data';
			break;
		case 'apt:vic':
			txt = 'Apartments - Victoria';
			break;
		case 'apt:qld':
			txt = 'Apartments - Queensland';
			break;
		case 'apt:nsw':
			txt = 'Apartments - New South Wales';
			break;
		case 'md:all':
			txt = 'Medium Density - All Data';
			break;
		case 'md:vic':
			txt = 'Medium Density - Victoria';
			break;
		case 'md:qld':
			txt = 'Medium Density - Queensland';
			break;
		case 'md:nsw':
			txt = 'Medium Density - New South Wales';
			break;
		default:
			break;
	}

	return (
		<Typography.Title>{txt}</Typography.Title>
	);
};

const DashboardBreadcrumbs = ({ selected }) => {
	let steps = ['All Data'];
	switch (selected) {
		case "states:vic":
			steps = ["States", "Victoria"]
			break;
		case "states:qld":
			steps = ["States", "Queensland"]
			break;
		case "states:nsw":
			steps = ["States", "New South Wales"]
			break;
		default:
			break;
	}

	return (
		<Breadcrumb style={{alignItems: 'flex-end'}}>
			{steps.map((step, idx) =>
				<Breadcrumb.Item key={idx}>{step}</Breadcrumb.Item>
			)}
		</Breadcrumb>
	);
};

export default DashboardDisplay;
