import React from "react";
import {Card, Col, Icon, Layout, Menu, Row, Statistic} from "antd";
import InSiteDashboardFrame from "../components/frame/InSiteDashboardFrame";
import InSitePieChart from "../components/charts/InSitePieChart";
import {DatePickerStripShitVersion} from "../components/datepicker/Datepicker";
import {useHistory} from "react-router";
import Chance from "chance";


const CorpHomePage = (props) => {
	const chance = new Chance();
	const [, forceUpdate] = React.useState(0);
	const history = useHistory();
	return (
		<InSiteDashboardFrame>
			<Layout>
				<Layout.Sider className={`sider-no-theme`}>
					<Menu mode="inline">
						<Menu.Item key="dashboard">
							<Icon type="home" />
							<a href="/corporate">Dashboard</a>
						</Menu.Item>
						<Menu.Item key="leads">
							<Icon type="contacts" />
							<a href="/corporate/leads">Leads</a>
						</Menu.Item>
						<Menu.Item key="inventory">
							<Icon type="reconciliation" />
							<a href="/corporate/inventory">Inventory</a>
						</Menu.Item>
						<Menu.SubMenu key="projects" title={
							<span>
								<Icon type="project" />
								<span>Projects</span>
							</span>
						}>
							<Menu.ItemGroup key="land" title="Land">
								<Menu.Item key="1">
									<a href="/overview">Project One</a>
								</Menu.Item>
								<Menu.Item key="2">
									<a href="/overview">Project Two</a>
								</Menu.Item>
							</Menu.ItemGroup>
							<Menu.ItemGroup key="townhomes" title="Medium Density">
								<Menu.Item key="3">
									<a href="/overview">Project Three</a>
								</Menu.Item>
								<Menu.Item key="4">
									<a href="/overview">Project Four</a>
								</Menu.Item>
							</Menu.ItemGroup>
						</Menu.SubMenu>
					</Menu>
					<Menu style={{marginTop: '8px'}}>
						<Menu.ItemGroup title="System">
							<Menu.Item key="admin" onClick={() => history.push('/admin')}>Admin Console</Menu.Item>
							<Menu.Item key="logout" onClick={() => history.replace('/')}>Logout</Menu.Item>
						</Menu.ItemGroup>
					</Menu>
				</Layout.Sider>
				<Layout.Content style={{paddingLeft: '8px'}}>
					<Row gutter={[16, 16]} type={`flex`} style={{alignItems: 'flex-end'}}>
						<Col span={17}>&nbsp;</Col>
						<DatePickerStripShitVersion hasCustom={false} forceUpdate={forceUpdate} />
					</Row>
					<Row gutter={[16, 16]} type={`flex`}>
						<Col span={24}>
							<Row gutter={[16, 16]} type={`flex`}>
								<Col lg={8} md={8} sm={24} xs={24}>
									<Card title='Sales (Last 30 Days)'>
										<Row gutter={16}>
											<Col span={12} style={{textAlign:'center'}}>
												<Statistic title="Sales" value={chance.integer({ min: 0, max: 300 })} />
											</Col>
											<Col span={12} style={{textAlign:'center'}}>
												<Statistic title="Deposits" value={chance.integer({ min: 0, max: 300 })} />
											</Col>
										</Row>
									</Card>
								</Col>

								<Col lg={8} md={8} sm={24} xs={24}>
									<Card title='Engagement (Last 30 Days)'>
										<Row gutter={16}>
											<Col span={12} style={{textAlign:'center'}}>
												<Statistic title="Registrations" value={chance.integer({ min: 400, max: 5000 })} />
											</Col>
											<Col span={12} style={{textAlign:'center'}}>
												<Statistic title="Visits" value={chance.integer({ min: 400, max: 5000 })} />
											</Col>

										</Row>
									</Card>
								</Col>

								<Col lg={8} md={8} sm={24} xs={24}>
									<Card title='All Recorded Data'>
										<Row gutter={16}>
											<Col span={12} style={{textAlign:'center'}}>
												<Statistic title="Registrations" value={chance.integer({ min: 4000, max: 50000 })} />
											</Col>
											<Col span={12} style={{textAlign:'center'}}>
												<Statistic title="Visits" value={chance.integer({ min: 4000, max: 50000 })} />
											</Col>
										</Row>
									</Card>
								</Col>
							</Row>
							<Row gutter={[16, 16]} type={`flex`}>
								<Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
									<Card title='Project Sales'>
										<InSitePieChart id={`example_one`} data={[7, 8, 4, 13]} labels={['Label A', 'Label B', 'Label C', 'Label D']} />
									</Card>
								</Col>
								<Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
									<Card title='Project Registrations'>
										<InSitePieChart id={`example_one`} data={[7, 8, 4, 13, 15, 9, 2, 4, 1, 17, 11, 3, 5, 9, 12, 11, 22, 14, 8, 5]} labels={['Label A', 'Label B', 'Label C', 'Label D', 'Label E', 'Label F', 'Label G', 'Label H', 'Label I', 'Label J', 'Label K', 'Label L', 'Label M', 'Label N', 'Label O', 'Label P', 'Label Q', 'Label R', 'Label S', 'Label T']} />
									</Card>
								</Col>
								<Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
									<Card title='Sales vs Deposits'>
										<InSitePieChart id={`example_one`} data={[42, 51]} labels={['Label A', 'Label B']} />
									</Card>
								</Col>
							</Row>
							<Row gutter={[16, 16]} type={`flex`}>
								<Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
									<Card title='Sales Sources' extra={<span className="extra-subtitle">* Digital Sources</span>}>
										<InSitePieChart id={`example_one`} data={[7, 8, 4, 13]} labels={['Label A', 'Label B', 'Label C', 'Label D']} />
									</Card>
								</Col>
								<Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
									<Card title='Registration Sources' extra={<span className="extra-subtitle">* Digital Sources</span>}>
										<InSitePieChart id={`example_one`} data={[7, 8, 4, 13]} labels={['Label A', 'Label B', 'Label C', 'Label D']} />
									</Card>
								</Col>
								<Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
									<Card title='Traffic Sources' extra={<span className="extra-subtitle">* Digital Sources</span>}>
										<InSitePieChart id={`example_one`} data={[7, 8, 4, 13]} labels={['Label A', 'Label B', 'Label C', 'Label D']} />
									</Card>
								</Col>
							</Row>
						</Col>
					</Row>
				</Layout.Content>
			</Layout>
		</InSiteDashboardFrame>
	);
};

export default CorpHomePage;
