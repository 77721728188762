import {Card, Col, List, Table, Typography} from "antd";
import React from "react";

function randomInteger(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getTable(lotColumns, tableData) {
	console.log(tableData);
	console.log(lotColumns);
	return <Table className="stock-nested-table" columns={lotColumns} dataSource={tableData} pagination={false}/>;
}

const GenericStatusButtonTabPage = ({cardTitle, lotColumns, tableData, actionHistory}) => {
	console.log(cardTitle);
	console.log(tableData);
	console.log(lotColumns);

	return (
		<>
			<Col span={16}>
				<Card title={cardTitle} extra={[<Typography.Text key={randomInteger(1, 9000)} type="secondary" style={{
					fontStyle: 'italic',
					fontSize: '12px'
				}}>Total: {tableData.length}</Typography.Text>]}>
					{getTable(lotColumns, tableData)}
				</Card>
			</Col>
			<Col span={8}>
				<Card className="action_history_list" title="Action History">
					<List bordered={true} itemLayout="horizontal" split={true}>
						{actionHistory.map((item, idx) => {
							return <List.Item key={idx} actions={[<a key="list-loadmore-edit">view</a>,
								<a key="list-loadmore-more">clear</a>]}>
								<List.Item.Meta
									key={idx}
									title={<div key={idx}><Typography.Text strong={true}>{item.src}</Typography.Text>:&nbsp;
										<Typography.Text>{item.action}</Typography.Text></div>}/>
								{item.actioner}
							</List.Item>
						})}
					</List>
				</Card>
			</Col>
		</>
	);
};

export default GenericStatusButtonTabPage;
