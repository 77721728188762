import React, {useState} from "react";
import {
	Layout,
	Menu,
	Icon,
	Row,
	Col,
	Select,
	Button,
	Dropdown,
	Modal,
	Form,
	Input,
	AutoComplete,
	Typography
} from "antd";
import {Link} from "react-router-dom";
import {useHistory} from "react-router";
const { Header, Footer, Sider, Content } = Layout;

const InSiteFrame = (props) => {
	console.log(props);
	const history = useHistory();
	const [collapsed, setCollapsed] = useState(false);
	const onCollapse = () => { setCollapsed(!collapsed) };
	const onMenuClick = ({ item, key, keyPath, domEvent }) => {
		switch (key) {
			case "0":
				history.push('/dashboard');
				break;
			case "1":
				history.push('/overview');
				break;
			case "2":
				history.push('/planner');
				break;
			case "3":
				history.push('/leads');
				break;
			case "5":
				history.push('/house');
				break;
			case "6":
				history.push('/stock-management');
				break;
			case "7":
				history.push('/actions');
				break;
			case "8":
				history.push('/traffic');
				break;
			case "12":
				history.push('/admin');
				break;
			case "10":
				history.push('/marketing-report');
				break;
			case "14":
				history.push('/integrations');
				break;
			case "49":
				history.push('/register');
				break;
			default:
				break;
		}
	};

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Header style={{height: '84px', lineHeight: '84px'}}>
				<Row>
					<Col span={1}>
						<Icon className="trigger" type={collapsed ? 'menu-unfold' : 'menu-fold'} onClick={onCollapse} />
					</Col>
					<Col span={1}>
						<div className="logo" style={{height: '160%'}}>
							<img style={{width: '160%'}} src="https://storage.googleapis.com/proj.insitelogic.com.au/etc/biggin-scott.png" alt="" />
						</div>
					</Col>
					<Col span={6} push={8}>
						<Typography.Title level={2} style={{ color: 'white', lineHeight: '84px' }}>Biggin & Scott - Richmond</Typography.Title>
					</Col>
					<Col span={3} push={13} style={{textAlign: 'right'}}>
						<Dropdown overlay={<OmniMenu history={history} />} placement="bottomLeft">
							<Button size="large" icon="plus" />
						</Dropdown>
					</Col>
				</Row>
			</Header>
			<Layout>
				<Sider className="insite-frame" trigger={null} collapsible collapsed={collapsed}>
					<div className={`logo`} />
					<Menu theme="dark" mode="inline" onClick={onMenuClick}>
						<Menu.Item key="0">
							<Icon type="appstore" />
							{/*<Link to="/overview">Overview</Link>*/}
							<span>Dashboard</span>
						</Menu.Item>
						<Menu.Item key="1">
							<Icon type="pie-chart" />
							{/*<Link to="/overview">Overview</Link>*/}
							<span>Overview</span>
						</Menu.Item>
						<Menu.Item key="2">
							<Icon type="calendar" />
							{/*<Link to="/planner">My Work Day</Link>*/}
							<span>My Work Day</span>
						</Menu.Item>
						<Menu.Item key="3">
							<Icon type="desktop" />
							{/*<Link to="/leads">Lead Report</Link>*/}
							<span>Leads Report</span>
						</Menu.Item>
						<Menu.SubMenu key="sub1" title={<span><Icon type="bank" /><span>Stock</span></span>}>
							<Menu.Item key="4">Land Stats</Menu.Item>
							<Menu.Item key="5">Package Stats</Menu.Item>
						</Menu.SubMenu>
						<Menu.SubMenu key="sub2" title={<span><Icon type="area-chart" /><span>Reporting</span></span>}>
							<Menu.Item key="7">Download Reports</Menu.Item>
							<Menu.Item key="8">Web Reports</Menu.Item>
							<Menu.Item key="9">eDM Reports</Menu.Item>
							<Menu.Item key="10">Marketing Report</Menu.Item>
						</Menu.SubMenu>
						<Menu.SubMenu key="sub3" title={<span><Icon type="team" /><span>Admin</span></span>}>
							<Menu.Item key={12}>
								<Icon type="setting" />
								<span>Admin Settings</span>
							</Menu.Item>
							<Menu.Item key={13}>
								<Icon type="solution" />
								<span>User Settings</span>
							</Menu.Item>
							<Menu.Item key={14}>
								<Icon type="api" />
								<span>Integrations</span>
							</Menu.Item>
						</Menu.SubMenu>
						<Menu.ItemGroup key="grp_products" title="InSite Products">
							<Menu.Item key="6">
								<Icon type="shopping-cart" />
								<span>Inv. Management</span>
							</Menu.Item>
						</Menu.ItemGroup>
						<Menu.ItemGroup key="grp_system" title="System">
							<Menu.Item key={49}>
								<Icon type="user-add" />
								<span>Register User</span>
							</Menu.Item>
							<Menu.Item key={50}>
								<Icon type="logout" />
								<span>Logout</span>
							</Menu.Item>
						</Menu.ItemGroup>
					</Menu>
				</Sider>
				<Content style={{padding: '12px'}}>
					{props.children}
				</Content>
			</Layout>
		</Layout>
	);
};

const OmniMenu = (props) => {
	const [selectedOmniItem, setSelectedOmniItem] = useState(null);
	const onMenuClick = ({ item, key, keyPath, domEvent }) => {
		switch (key) {
			case "1":
				setSelectedOmniItem("Add New Lead");
				break;
			case "2":
				setSelectedOmniItem("Add New Note");
				break;
			case "3":
				setSelectedOmniItem("Record Phone Call");
				break;
			case "4":
				props.history.push('/profile-alternate');
				break;
			case "5":
				props.history.push('/profile-version-3');
				break;
			case "6":
				let xpath = "//style[contains(text(),'APP_STYLES')]";
				let matchingElement = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
				console.log(matchingElement);
				fetch("/src/styles/insite.css").then((resp) => {
					if(resp.status !== 200) {
						console.error("failed");
					} else {
						return resp.text();
					}
				}).then((txt) => {
					console.log(txt);
					matchingElement.innerHTML = txt;
				});
				break;
			default:
				break;
		}
	};

	return (
		<Menu onClick={onMenuClick}>
			<Menu.Item key="1">
				Add New Lead
			</Menu.Item>
			<Menu.Item key="2">
				Add New Note
			</Menu.Item>
			<Menu.Item key="3">
				Record Phone Call
			</Menu.Item>
			<Modal title={selectedOmniItem} visible={selectedOmniItem !== null} onOk={() => setSelectedOmniItem(null)} onCancel={() => setSelectedOmniItem(null)}>
				<OmniPopup selectedOmniItem={selectedOmniItem} />
			</Modal>
		</Menu>
	);
};

const OmniPopup = ({selectedOmniItem}) => {
	const layout = {
		labelCol: { span: 8 },
		wrapperCol: { span: 16 },
	};
	let body = null;
	if(selectedOmniItem ==="Add New Lead") {
		body = (
			<Form {...layout}>
				<Form.Item label="First Name">
					<Input />
				</Form.Item>
				<Form.Item label="Last Name">
					<Input />
				</Form.Item>
				<Form.Item label="Email Address">
					<Input />
				</Form.Item>
				<Form.Item label="Phone">
					<Input />
				</Form.Item>
			</Form>
		);
	} else if(selectedOmniItem ==="Add New Note") {
		body = (
			<Form {...layout}>
				<Form.Item label="Lead Name">
					<AutoComplete placeholder={`Lookup Existing Lead`}>
						{Array(50).fill().map((_, idx) =>
							<AutoComplete.Option key={idx}>
								{(new Chance()).name({nationality: 'en'})}
							</AutoComplete.Option>
						)}
					</AutoComplete>
				</Form.Item>
				<Form.Item label="Set Status">
					<Select placeholder={`Select a lead status`}>
						<Select.Option value="0">Unactioned</Select.Option>
						<Select.Option value="1">Cold</Select.Option>
						<Select.Option value="2">Warm</Select.Option>
						<Select.Option value="3">Hot</Select.Option>
						<Select.Option value="4">Industry</Select.Option>
						<Select.Option value="7">Archived</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item label="Note">
					<Input.TextArea rows={6} placeholder={`Enter the note content`} />
				</Form.Item>
			</Form>
		);
	} else if(selectedOmniItem ==="Record Phone Call") {
		body = (
			<Form {...layout}>
				<Form.Item label="Lead Name">
					<AutoComplete placeholder={`Lookup Existing Lead`}>
						{Array(50).fill().map((_, idx) =>
							<AutoComplete.Option key={idx}>
								{(new Chance()).name({nationality: 'en'})}
							</AutoComplete.Option>
						)}
					</AutoComplete>
				</Form.Item>
				<Form.Item label="Set Status">
					<Select placeholder={`Select a lead status`}>
						<Select.Option value="0">Unactioned</Select.Option>
						<Select.Option value="1">Cold</Select.Option>
						<Select.Option value="2">Warm</Select.Option>
						<Select.Option value="3">Hot</Select.Option>
						<Select.Option value="4">Industry</Select.Option>
						<Select.Option value="7">Archived</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item label="Note">
					<Input.TextArea rows={6} placeholder={`Enter notes about your phone call`} />
				</Form.Item>
			</Form>
		);
	}

	return body;
};

export default InSiteFrame;
