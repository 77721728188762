import React, {useState} from "react";
import InSiteFrame from "../components/frame/InSiteFrame";
import {Breadcrumb, Card, Col, DatePicker, Row} from "antd";
import moment from "moment";
import InSitePieChart from "../components/charts/InSitePieChart";
import InSitePackageDataTable from "../components/table/InSitePackageDataTable";
import InSiteBuilderDataTable from "../components/table/InSiteBuilderDataTable";
import {DatePickerStripShitVersion} from "../components/datepicker/Datepicker";

const PackagePage = (props) => {
	const [pkgCount, setPkgCount] = useState(null);
	const [builderCount, setBuilderCount] = useState(null);

	const updatePkgCount = (count) => {
		setPkgCount(count);
	};

	const updateBuilderCount = (count) => {
		setBuilderCount(count);
	};

	return (
		<InSiteFrame>
			<Row gutter={[16, 16]} type={`flex`} style={{alignItems: 'flex-end'}}>
				<Col span={17}>
					<Breadcrumb style={{alignItems: 'flex-end'}}>
						<Breadcrumb.Item>Stock</Breadcrumb.Item>
						<Breadcrumb.Item>Package Stats</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
				<DatePickerStripShitVersion />
			</Row>
			<Row gutter={[16, 16]} type={`flex`}>
				<Col span={8}>
					<Card title="Packages by Lot">
						<InSitePieChart id={`example_one`} data={[7, 8, 4, 13]} labels={['Label A', 'Label B', 'Label C', 'Label D']} />
					</Card>
				</Col>
				<Col span={8}>
					<Card title="Packages by Price">
						<InSitePieChart id={`example_one`} data={[7, 8, 4, 13]} labels={['Label A', 'Label B', 'Label C', 'Label D']} />
					</Card>
				</Col>
				<Col span={8}>
					<Card title="Packages by Builder">
						<InSitePieChart id={`example_one`} data={[7, 8, 4, 13]} labels={['Label A', 'Label B', 'Label C', 'Label D']} />
					</Card>
				</Col>
			</Row>
			<Row gutter={[16, 16]} type={`flex`}>
				<Col span={24}>
					<Card title="Package Report" extra={<span className="extra-subtitle">{pkgCount} records</span>}>
						<InSitePackageDataTable setCount={updatePkgCount} />
					</Card>
				</Col>
			</Row>
			<Row gutter={[16, 16]} type={`flex`}>
				<Col span={24}>
					<Card title="Builder Report" extra={<span className="extra-subtitle">{builderCount} records</span>}>
						<InSiteBuilderDataTable setCount={updateBuilderCount} />
					</Card>
				</Col>
			</Row>
		</InSiteFrame>
	);
};

export default PackagePage;
