import {Card, Table} from "antd";
import React from "react";
import moment from "moment";

const SalesTable = (props) => {
	const columns = [
		{ key: 'date', dataIndex: 'date', title: 'Date' },
		{ key: 'address', dataIndex: 'address', title: 'Address' },
		{ key: 'buyer', dataIndex: 'buyer', title: 'Purchaser' },
		{ key: 'amount', dataIndex: 'amount', title: 'Purchase Amount' }
	];

	const data = [
		{ key: '1', date: moment('2020-08-11 13:30:00').format('Mo MMM, YYYY'), address: '12 Alabaster Street', buyer: 'Staten', amount: '$1,200,000' },
		{ key: '2', date: moment('2020-08-07 13:30:00').format('Mo MMM, YYYY'), address: '14 Crimson Crescent', buyer: 'Hoover', amount: '$810,000' },
		{ key: '3', date: moment('2020-08-04 13:30:00').format('Mo MMM, YYYY'), address: '3 Thompson Court', buyer: 'Higgins', amount: '$640,000' },
		{ key: '4', date: moment('2020-08-01 13:30:00').format('Mo MMM, YYYY'), address: '47 Mandama Avenue', buyer: 'Timms', amount: '$490,000' },
		{ key: '5', date: moment('2020-07-30 13:30:00').format('Mo MMM, YYYY'), address: '6 Polymer Lane', buyer: 'Rogers', amount: '$912,000' },
		{ key: '6', date: moment('2020-07-27 13:30:00').format('Mo MMM, YYYY'), address: '98 Jimson Road', buyer: 'Goodes', amount: '$543,000' },
	];

	return (
		<Card title="Sales (Aug 2020)" extra={<a href="#">View All Sales</a>}>
			<Table className="insite" dataSource={data} columns={columns} pagination={{defaultPageSize: 5}} />
		</Card>
	);
};

export default SalesTable;
