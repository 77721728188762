import React from "react";
import {Card, Col, Row, Statistic, Breadcrumb, Icon, Table} from "antd";

import InSiteFrame from "../components/frame/InSiteFrame";
import InSitePieChart from "../components/charts/InSitePieChart";
import {DatePickerStripShitVersion} from "../components/datepicker/Datepicker";
import AgentActivityTable from "../components/overview/AgentActivityTable";
import PieChartRow from "../components/overview/PieChartRow";
import SalesTable from "../components/overview/SalesTable";
import ListingsTable from "../components/overview/ListingsTable";

const OverviewPage = (props) => {
	return (
		<InSiteFrame>
			<div>
				<Row gutter={[16, 16]} type={`flex`} style={{alignItems: 'flex-end'}}>
					<Col span={17}>
						<Breadcrumb style={{alignItems: 'flex-end'}}>
							<Breadcrumb.Item>Overview</Breadcrumb.Item>
						</Breadcrumb>
					</Col>
					<DatePickerStripShitVersion />
				</Row>
				<Row gutter={[16, 16]} type={`flex`}>
					<Col span={8}>
						<Card title="Sales">
							<Row gutter={[16, 16]} type={`flex`}>
								<Col span={12}>
									<Statistic title="Sales" value="16" />
								</Col>
								<Col span={12}>
									<Statistic title="Value" value="$14,800,000" />
								</Col>
							</Row>
						</Card>
					</Col>
					<Col span={8}>
						<Card title="Listings">
							<Row gutter={[16, 16]} type={`flex`}>
								<Col span={12}>
									<Statistic title="Listings" value="18" valueStyle={{ color: '#3f8600' }} prefix={<Icon type="arrow-up" />} />
								</Col>
								<Col span={12}>
									<Statistic title="Prev. Month" value="16" />
								</Col>
							</Row>
						</Card>
					</Col>
					<Col span={8}>
						<Card title="Property Engagement">
							<Row gutter={[16, 16]} type={`flex`}>
								<Col span={12}>
									<Statistic title="Views" value="211" />
								</Col>
								<Col span={12}>
									<Statistic title="Unique Views" value="93" />
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
				<PieChartRow />
				<Row gutter={[16, 16]} type={`flex`}>
					<Col span={24}>
						<AgentActivityTable />
					</Col>
				</Row>
				<Row gutter={[16, 16]} type={`flex`}>
					<Col span={12}>
						<SalesTable />
					</Col>
					<Col span={12}>
						<ListingsTable />
					</Col>
				</Row>
			</div>
		</InSiteFrame>
	);
};

export default OverviewPage;
