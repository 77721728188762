import React, {useState} from "react";

import {Breadcrumb, Card, Col, Row, Table} from 'antd';
import InSiteFrame from "../components/frame/InSiteFrame";
import InSiteLeadsDataTable from "../components/table/leads/InSiteLeadsDataTable";

const LeadsPage = (props) => {
	const [leadCount, setLeadCount] = useState(0);
	const updateLeadCount = (count) => {
		setLeadCount(count);
	};
	return (
		<InSiteFrame>
			<Row gutter={[16, 16]} type={`flex`} style={{alignItems: 'flex-end'}}>
				<Col span={24}>
					<Breadcrumb style={{alignItems: 'flex-end'}}>
						<Breadcrumb.Item>Leads Report</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<Card title={`Leads Report`} extra={<span className="extra-subtitle">{leadCount} records</span>}>
						<InSiteLeadsDataTable setCount={updateLeadCount} />
					</Card>
				</Col>
			</Row>
		</InSiteFrame>
	);
};

export default LeadsPage;
