import React from "react";
import InSiteFrame from "../components/frame/InSiteFrame";
import {Breadcrumb, Card, Col, DatePicker, Row, Statistic, Tooltip} from "antd";
import moment from "moment";
import Chance from "chance";
import InSitePieChart from "../components/charts/InSitePieChart";
import InSiteTrafficHistoryChart from "../components/charts/InSiteTrafficHistoryChart";
import {DatePickerStripShitVersion} from "../components/datepicker/Datepicker";

const TrafficPage = (props) => {
	const chance = new Chance();
	return (
		<InSiteFrame>
			<Row gutter={[16, 16]} type={`flex`} style={{alignItems: 'flex-end'}}>
				<Col span={17}>
					<Breadcrumb style={{alignItems: 'flex-end'}}>
						<Breadcrumb.Item>Reporting</Breadcrumb.Item>
						<Breadcrumb.Item>Web Reports</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
				<DatePickerStripShitVersion />
			</Row>
			<Row gutter={[16, 16]} type={`flex`}>
				<Col span={8}>
					<Card title={`Summary Stats`}>
						<Row gutter={[16,16]}>
							<Col span={24}>
								<Statistic title={`Registrations`} value={chance.integer({min: 3, max: 15})} />
							</Col>
						</Row>
						<Row gutter={[16,16]}>
							<Col span={24}>
								<Statistic title={`Active Users`} value={chance.integer({min: 300, max: 950})} />
							</Col>
						</Row>
						<Row gutter={[16,16]}>
							<Col span={24}>
								<Statistic title={`Active Users - Visits`} value={chance.integer({min: 200, max: 700})} />
							</Col>
						</Row>
						<Row gutter={[16,16]}>
							<Col span={24}>
								<Statistic title={`Profiles Monitored`} value={chance.integer({min: 500, max: 1500})} />
							</Col>
						</Row>
						<Row gutter={[16,16]}>
							<Col span={24}>
								<Statistic title={`Total Visits`} value={chance.integer({min: 500, max: 1500})} />
							</Col>
						</Row>
					</Card>
				</Col>
				<Col span={8}>
					<Card title={`Registration Sources`}>
						<InSitePieChart id={`example_one`} data={[7, 8, 4, 13]} labels={['Label A', 'Label B', 'Label C', 'Label D']} width={'70%'} />
					</Card>
				</Col>
				<Col span={8}>
					<Card title={`All Visitor Sources`}>
						<InSitePieChart id={`example_one`} data={[7, 8, 4, 13]} labels={['Label A', 'Label B', 'Label C', 'Label D']} width={'70%'} />
					</Card>
				</Col>
			</Row>
			<Row gutter={[16, 16]} type={`flex`}>
				<Col span={24}>
					<Card title="Website Visits">
						<InSiteTrafficHistoryChart/>
					</Card>
				</Col>
			</Row>
			<Row gutter={[16, 16]} type={`flex`}>
				<Col span={8}>
					<Card title={`Device Preferences`}>
						<InSitePieChart id={`example_one`} data={[7, 8, 4, 13]} labels={['Label A', 'Label B', 'Label C', 'Label D']} />
					</Card>
				</Col>
				<Col span={8}>
					<Card title={`New vs Returning`}>
						<InSitePieChart id={`example_one`} data={[7, 8, 4, 13]} labels={['Label A', 'Label B', 'Label C', 'Label D']} />
					</Card>
				</Col>
				<Col span={8}>
					<Card title={`Identified Visitors`}>
						<InSitePieChart id={`example_one`} data={[7, 8, 4, 13]} labels={['Label A', 'Label B', 'Label C', 'Label D']} />
					</Card>
				</Col>
			</Row>
		</InSiteFrame>
	);
};

export default TrafficPage;
