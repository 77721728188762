import React from "react";
import SVG from 'react-inlinesvg';
import {Icon} from "antd";

import FullStar from './../../svg/star-orange.svg';
import HalfStar from './../../svg/star-half-orange.svg';
import EmptyStar from './../../svg/star-empty-orange.svg';

const FullStarSVG = (props) => { return <SVG src={FullStar} />; };
const FullStarIcon = (props) => {
	return (
		<Icon style={{ width: '1.2em', height: '1.2em' }} component={FullStarSVG} {...props} />
	);
};

const HalfStarSVG = (props) => { return <SVG src={HalfStar} />; };
const HalfStarIcon = (props) => {
	return (
		<Icon style={{ width: '1.2em', height: '1.2em' }} component={HalfStarSVG} {...props} />
	);
};

const EmptyStarSVG = (props) => { return <SVG src={EmptyStar} />; };
const EmptyStarIcon = (props) => {
	return (
		<Icon style={{ width: '1.2em', height: '1.2em' }} component={EmptyStarSVG} {...props} />
	);
};

function convertToStarRating(rating) {
	let i = 0;
	let buffer = [];
	// Calculate the number of each type of star needed
	let full_stars = Math.floor(rating);
	let half_stars = Math.ceil(rating - full_stars);
	let empty_stars = 5 - full_stars - half_stars;

	for (i = 0; i < full_stars; i++) {
		buffer.push("FULL");
	}

	for (i = 0; i < half_stars; i++) {
		buffer.push("HALF");
	}

	for (i = 0; i < empty_stars; i++) {
		buffer.push("EMPTY");
	}

	return buffer;
}

const generateStarRating = (rating) => {
	return convertToStarRating(rating).map((entry, idx) => {
		if(entry === "FULL") {
			return <FullStarIcon key={idx} />;
		} else if(entry === "HALF") {
			return <HalfStarIcon key={idx} />;
		} else if(entry === "EMPTY") {
			return <EmptyStarIcon key={idx} />;
		}
	});
};

export {
	FullStarIcon,
	HalfStarIcon,
	EmptyStarIcon,
	generateStarRating
};
