import React from "react";
import { Table } from 'antd';
import Chance from 'chance';

const OverviewDownloadsTable = ({ displayAmount=5 }) => {
	const chance = new Chance();
	const COLUMNS = [
		{ title: 'Type', dataIndex: 'type', key: 'type' },
		{ title: 'Description', dataIndex: 'desc', key: 'desc' },
		{ title: 'Actions', dataIndex: 'actions', key: 'actions' }
	];

	const DATA = generateData(displayAmount);

	return (
		<Table className="insite" columns={COLUMNS} dataSource={DATA} />
	);
};

const generateData = (amount=5) => {
	return [...Array(amount)].map((_, i) => {
		return {
			key: chance.integer({ min: 1 }),
			type: chance.pickone(['Download', 'Lot Flyer', 'Masterplan']),
			desc: chance.string({ alpha: true }),
			actions: chance.integer({ min: 1, max: 50 }) }
	});
};

export default OverviewDownloadsTable;
