import {Card, Col, Row, Table} from "antd";
import React from "react";
import InSitePieChart from "../charts/InSitePieChart";

const PieChartRow = (props) => {
	const [salesTab, setSalesTab] = React.useState('sales.chart');
	const [listingTab, setListingTab] = React.useState('listing.chart');
	const [referrerTab, setReferrerTab] = React.useState('referrer.chart');

	const graphTabLists = {
		SALES: [ {key: 'sales.chart', tab: 'Chart'}, {key: 'sales.table', tab: 'Table'} ],
		LISTINGS: [ {key: 'listing.chart', tab: 'Chart'}, {key: 'listing.table', tab: 'Table'} ],
		REFERRER: [ {key: 'referrer.chart', tab: 'Chart'}, {key: 'referrer.table', tab: 'Table'} ]
	};

	const contentList = {
		"sales.chart": (
			<InSitePieChart width="75%" position="right" id={`example_one`} data={[7, 4, 3, 2]} labels={['John Smith', 'Ben Brown', 'Alf Long', 'Amy Burger']} />
		),
		"listing.chart": (
			<InSitePieChart width="75%" position="right" id={`example_one`} data={[6, 5, 3, 5]} labels={['John Smith', 'Ben Brown', 'Alf Long', 'Amy Burger']} />
		),
		"referrer.chart": (
			<InSitePieChart width="75%" position="right" id={`example_one`} data={[32, 17, 112, 187, 15, 36, 35]} labels={['Direct', 'REA', 'Social', 'Google', 'Google Adwords', 'Domain', 'Others']} />
		),
		"sales.table": (
			<Table
				className="insite"
				dataSource={[
					{ key: '1', agent: 'John Smith', total: 7 },
					{ key: '2', agent: 'Ben Brown', total: 4 },
					{ key: '3', agent: 'Alf Long', total: 3 },
					{ key: '4', agent: 'Amy Burger', total: 2 },
				]}
				columns={[
					{ title: 'Agent', dataIndex: 'agent', key: '1' },
					{ title: 'Total', dataIndex: 'total', key: '2' },
				]} />
		),
		"listing.table": (
			<Table
				className="insite"
				pagination={{ defaultPageSize: 5 }}
				dataSource={
					((arr1, arr2) => arr1.map((k, i) => [k, arr2[i]]))([6, 5, 3, 5], ['John Smith', 'Ben Brown', 'Alf Long', 'Amy Burger'])
						.reduce((accum, curr, idx) => {
							let tmp = { key: idx, agent: curr[1], total: curr[0] }
							accum.push(tmp);
							return accum;
						}, [])
				}
				columns={[
					{ title: 'Agent', dataIndex: 'agent', key: '1' },
					{ title: 'Total', dataIndex: 'total', key: '2' },
				]} />
		),
		"referrer.table": (
			<Table
				className="insite"
				dataSource={
					((arr1, arr2) => arr1.map((k, i) => [k, arr2[i]]))([32, 17, 112, 187, 15, 36, 35], ['Direct', 'REA', 'Social', 'Google', 'Google Adwords', 'Domain', 'Others'])
						.reduce((accum, curr, idx) => {
							let tmp = { key: idx, referrer: curr[1], total: curr[0] }
							accum.push(tmp);
							return accum;
						}, [])
				} columns={[
				{ title: 'Referrer Source', dataIndex: 'referrer', key: '1' },
				{ title: 'Total', dataIndex: 'total', key: '2' },
			]} />
		),
	};

	return (
		<Row gutter={[16, 16]} type={`flex`}>
			<Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
				<Card title='Sales' tabList={graphTabLists.SALES} activeTabKey={salesTab} onTabChange={(key) => setSalesTab(key)}>
					{contentList[salesTab]}
				</Card>
			</Col>
			<Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
				<Card title='Listings' tabList={graphTabLists.LISTINGS} activeTabKey={listingTab} onTabChange={(key) => setListingTab(key)}>
					{contentList[listingTab]}
				</Card>
			</Col>
			<Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
				<Card title='Referrer Source' tabList={graphTabLists.REFERRER} activeTabKey={referrerTab} onTabChange={(key) => setReferrerTab(key)}>
					{contentList[referrerTab]}
				</Card>
			</Col>
		</Row>
	);
};

export default PieChartRow;
