import React from "react";
import { useHistory } from "react-router-dom";
import {Button, Checkbox, Form, Icon, Input} from "antd";

const InSiteLoginForm = (props) => {
	let history = useHistory();

	const handleSubmit = e => {
		e.preventDefault();
		props.form.validateFields((err, values) => {
			if (!err) {
				console.log('Received values of form: ', values);
				history.replace('/dashboard')
			}
		});
	};


	const { getFieldDecorator } = props.form;
	return (
		<Form onSubmit={handleSubmit} className="login-form">
			<Form.Item>
				{getFieldDecorator('username', {
					rules: [{ required: true, message: 'Please input your username!' }],
				})(
					<Input
						prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
						placeholder="Username"
					/>,
				)}
			</Form.Item>
			<Form.Item>
				{getFieldDecorator('password', {
					rules: [{ required: true, message: 'Please input your Password!' }],
				})(
					<Input
						prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
						type="password"
						placeholder="Password"
					/>,
				)}
			</Form.Item>
			<Form.Item>
				{getFieldDecorator('remember', {
					valuePropName: 'checked',
					initialValue: true,
				})(<Checkbox>Remember me</Checkbox>)}
				<a className="login-form-forgot" href="">
					Forgot password
				</a>
			</Form.Item>
			<Form.Item>
				<Button type="primary" htmlType="submit" className="login-form-button" style={{width: '100%'}}>Log in</Button>
			</Form.Item>
		</Form>
	);
};

const WrappedInSiteLoginForm = Form.create({ name: 'insite_login' })(InSiteLoginForm);

export default WrappedInSiteLoginForm;
