import React, {useEffect, useState} from "react";
import {Table} from 'antd';
import Chance from 'chance';
import formatMoney from "../../util/formatMoney";

const generateData = (amount=20) => {
	const chance = new Chance();
	return [...Array(amount)].map((_, idx) => {
		let priceA = Math.floor(chance.integer({min: 250000, max: 700000}) / 25000.0) * 25000;
		let priceB = Math.ceil(chance.integer({min: 250000, max: 700000}) / 25000.0) * 25000;

		return {
			key: idx,
			builder: chance.string({ alpha: true }),
			total: chance.integer({min: 15, max: 40}),
			priceRangeMin: Math.min(priceA, priceB),
			priceRangeMax: Math.max(priceA, priceB),
			lotsInUse: chance.integer({min: 10, max: 30}),
			stagesInUse: chance.integer({min: 2, max: 6}),
			actions: chance.integer({min: 10, max: 65}),
		};
	});
};

const DATA = generateData();

const InSiteBuilderDataTable = ({setCount}) => {
	const [filteringInfo, setFilteringInfo] = useState(null);
	useEffect(() => { setCount(DATA.length); });

	const COLUMNS = [
		{
			title: 'Builder',
			dataIndex: 'builder',
			key: 'builder',
		},
		{
			title: 'Total Packages',
			dataIndex: 'total',
			key: 'total',
			sorter: (a, b) => a.total - b.total,
		},
		{
			title: 'Price Range',
			dataIndex: 'priceRangeMin',
			key: 'priceRangeMin',
			render: (text, record, idx) => {
				return (<span>${formatMoney(record.priceRangeMin)} - ${formatMoney(record.priceRangeMax)}</span>);
			}
		},
		{
			title: 'Lots in Use',
			dataIndex: 'lotsInUse',
			key: 'lotsInUse',
			sorter: (a, b) => a.lotsInUse - b.lotsInUse,
		},
		{
			title: 'Stages in Use',
			dataIndex: 'stagesInUse',
			key: 'stagesInUse',
			sorter: (a, b) => a.stagesInUse - b.stagesInUse,
		},
		{
			title: 'Actions',
			dataIndex: 'actions',
			key: 'actions',
			sorter: (a, b) => a.actions - b.actions,
		},
	];

	return (
		<Table className="insite" columns={COLUMNS} dataSource={DATA} onChange={(pagination, filters, sorter) => { console.log(filters); setFilteringInfo(filters); }} />
	);
};

export default InSiteBuilderDataTable;
