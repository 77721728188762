import React, {useState} from "react";
import {Card, Col, Divider, Form, List, Modal, PageHeader, Row, Steps, Table, Typography} from "antd";
import Button from "antd/es/button";
import NewInstanceForm from "../../components/forms/NewInstanceForm";

const DEMO_PROJECT_LIST = [
	"Example Project A",
	"Example Project B",
	"Example Project C",
	"Example Project D"
];

const DEMO_PROJECT_TABLE_DATA = [
	{ key: 1, title: "Example A", launchDate: "12th January, 2020" },
	{ key: 2, title: "Example B", launchDate: "6th January, 2020" },
	{ key: 3, title: "Example C", launchDate: "1st January, 2020" },
	{ key: 4, title: "Example D", launchDate: "25th December, 2019" },
	{ key: 5, title: "Example E", launchDate: "12th October, 2019" },
];
const DEMO_USER_TABLE_DATA = [
	{ key: 1, name: "Example A", email: "example.a@insitelogic.com.au", role: "Sales Agent" },
	{ key: 1, name: "Example B", email: "example.b@insitelogic.com.au", role: "Sales Asst." },
	{ key: 1, name: "Example C", email: "example.c@insitelogic.com.au", role: "Sales Agent" },
	{ key: 1, name: "Example D", email: "example.d@insitelogic.com.au", role: "Marketing Mgr" },
	{ key: 1, name: "Example E", email: "example.e@insitelogic.com.au", role: "Admin" },
];
const DEMO_PROJECT_TABLE_COLS = [
	{ key: 1, dataIndex: 'title', title: 'Project Name' },
	{ key: 2, dataIndex: 'launchDate', title: 'Project Launch Date' },
	{ key: 3, title: 'Actions', render: (text, record) => (
		<span>
			<a>Update Image</a>
			<Divider type="vertical" />
			<a>Deactivate</a>
		</span>
	) }
];
const DEMO_USER_TABLE_COLS = [
	{ key: 1, dataIndex: 'name', title: 'User Name' },
	{ key: 2, dataIndex: 'email', title: 'User Email' },
	{ key: 3, dataIndex: 'role', title: 'User Access' },
	{ key: 4, title: 'Actions', render: (text, record) => (
			<span>
			<a>Update</a>
			<Divider type="vertical" />
			<a>Deactivate</a>
		</span>
		) }
];

const AdminHomePage = (props) => {
	const [showInstanceModal, setShowInstanceModal] = useState(false);
	const showModal = () => { setShowInstanceModal(true) };
	const hideModal = () => { setShowInstanceModal(false) };

	return (
		<div style={{padding: '16px'}}>
			<Row gutter={[16,16]} type="flex">
				<Col>
					<h1>InSite Instance Manager</h1>
				</Col>
			</Row>
			<Row gutter={[16,16]} type="flex">
				<Col span={8}>
					<List header={
						<div>
							<h3 style={{display: 'inline'}}>Instance List</h3>
							<div style={{float: 'right'}}>
								<Button icon="file-add" type="primary" style={{position: 'relative', bottom: '4px'}} onClick={showModal}>Add Instance</Button>
							</div>
						</div>
					} bordered dataSource={DEMO_PROJECT_LIST} renderItem={item =>
						<List.Item>
							{item}
						</List.Item>
					} />
				</Col>
				<Col span={16}>
					<PageHeader title={`Example Project A`} extra={[
						<Button key="4">View Instance</Button>,
						<Button key="3">Deactivate</Button>,
						<Button key="2">Add User</Button>,
						<Button key="1" type="primary">
							Add Project
						</Button>,
					]} />
					<Row gutter={[16,16]} type="flex">
						<Col span={11}>
							<Card title={`Projects`}>
								<Table dataSource={DEMO_PROJECT_TABLE_DATA} columns={DEMO_PROJECT_TABLE_COLS} />
							</Card>
						</Col>
						<Col span={13}>
							<Card title={`Users`}>
								<Table dataSource={DEMO_USER_TABLE_DATA} columns={DEMO_USER_TABLE_COLS} />
							</Card>
						</Col>
					</Row>
				</Col>
			</Row>
			<Modal title="Add Instance" visible={showInstanceModal} onOk={hideModal} onCancel={hideModal} width={1040}>
				<Steps size="small" current={1} style={{marginBottom: '32px'}}>
					<Steps.Step title="Basic Information" />
					<Steps.Step title="Internal Information" />
					<Steps.Step title="Add Users" />
					<Steps.Step title="Add Projects" />
				</Steps>
				<NewInstanceForm/>
			</Modal>
		</div>
	);
};

export default AdminHomePage;
