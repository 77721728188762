import {Typography} from "antd";
import React from "react";

export default [
	{ title: 'Stage', dataIndex: 'name', key: 'name', className: 'insite__stock__namecell', render: (txt) => { return (<Typography.Text strong={true} style={{color: "inherit"}}>{txt}</Typography.Text>) }, align: 'center' },
	{ title: 'Total', dataIndex: 'total', key: 'total', render: (txt) => { return (<Typography.Text strong={true}>{txt}</Typography.Text>) }, align: 'center' },
	{ title: 'Released', dataIndex: 'released', key: 'released', render: (txt) => { return (<Typography.Text strong={true}>{txt}</Typography.Text>) }, align: 'center' },
	{ title: 'Available', dataIndex: 'available', key: 'available', render: (txt) => { return (<Typography.Text strong={true}>{txt}</Typography.Text>) }, align: 'center' },
	{ title: 'Hold', dataIndex: 'hold', key: 'hold', render: (txt) => { return (<Typography.Text strong={true}>{txt}</Typography.Text>) }, align: 'center' },
	{ title: 'Sold', dataIndex: 'sold', key: 'sold', render: (txt) => { return (<Typography.Text strong={true}>{txt}</Typography.Text>) }, align: 'center' },
	{ title: 'Deposit', dataIndex: 'deposit', key: 'deposit', render: (txt) => { return (<Typography.Text strong={true}>{txt}</Typography.Text>) }, align: 'center' },
	{ title: 'Conditional', dataIndex: 'condition', key: 'condition', render: (txt) => { return (<Typography.Text strong={true}>{txt}</Typography.Text>) }, align: 'center' },
	{ title: 'Unconditional', dataIndex: 'unconditional', key: 'unconditional', render: (txt) => { return (<Typography.Text strong={true}>{txt}</Typography.Text>) }, align: 'center' },
	{ title: 'Settled', dataIndex: 'settled', key: 'settled', render: (txt) => { return (<Typography.Text strong={true}>{txt}</Typography.Text>) }, align: 'center' }
];
