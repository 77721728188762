import {AutoComplete, Button, Icon, Input, notification, Select, Table} from "antd";
import Highlighter from "react-highlight-words";
import {useHistory} from "react-router";
import React, {useState} from "react";
import Chance from "chance";
import formatMoney from "../../../util/formatMoney";
import SalesDrawerNew from "../../drawer/SalesDrawerNew";

const getNestedStatusTableSearchProps = (dataIndex, searchedColumn, searchText, renderOverride=null, setSearchStateCallback=null, setResetStateCallback=null) => {
	return {
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={node => {
						window.statusSearchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => setSearchStateCallback(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					type="primary"
					onClick={() => setSearchStateCallback(selectedKeys, confirm, dataIndex)}
					icon="search"
					size="small"
					style={{ width: 90, marginRight: 8 }}
				>
					Search
				</Button>
				<Button onClick={() => setResetStateCallback(clearFilters)} size="small" style={{ width: 90 }}>
					Reset
				</Button>
			</div>
		),
		filterIcon: filtered => (
			<Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
		),
		onFilter: (value, record) =>
			record[dataIndex]
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => window.statusSearchInput.select());
			}
		},
		render: (text, record, idx) => {
			if(renderOverride !== null) {
				return renderOverride(text, record, idx);
			} else {
				let color = '#000000';
				if(text === record.name) {
					if(record.status === 'Sold' || record.status === 'Unconditional') {
						color = '#ff4d4f';
					} else if(record.status === 'Deposit' || record.status === 'Conditional') {
						color = '#1890ff';
					} else if(record.status === 'Settled') {
						color = '#a0d911';
					}
				}

				return searchedColumn === dataIndex ? (
					<Highlighter
						highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
						searchWords={[searchText]}
						autoEscape
						textToHighlight={text.toString()}
					/>
				) : (
					<span style={{color: color}}>{text}</span>
				)
			}
		}
	};
};

const NestedStatusTable = (props) => {
	const history = useHistory();
	const DATA = props.data;

	const [isEditing, setIsEditing] = useState(false);
	const [editingRow, setEditingRow] = useState(null);

	const [searchText, setSearchText] = useState(null);
	const [searchedColumn, setSearchedColumn] = useState(null);

	const [isSalesDrawerVisible, setSalesDrawerVisible] = useState(false);

	const [filteredInfo, setFilteredInfo] = useState({});
	const STATUS_COLUMNS = [
		{ width: '10%', align: 'center', title: 'Lot', dataIndex: 'name', key: 'name', render: (text, record, idx) => {
				let color = '#000000';
				if(record.status === 'Sold' || record.status === 'Unconditional') {
					color = '#ff4d4f';
				} else if(record.status === 'Deposit' || record.status === 'Conditional') {
					color = '#1890ff';
				} else if(record.status === 'Settled') {
					color = '#a0d911';
				}
				return (<span style={{color: color}}>{text}</span>);
			},
			...getNestedStatusTableSearchProps(
				'name',
				searchedColumn,
				searchText,
				null,
				(selectedKeys, confirm, dataIndex) => {
					confirm();
					setSearchText(selectedKeys[0]);
					setSearchedColumn(dataIndex);
				},
				(clearFilters) => {
					clearFilters();
					setSearchText('');
				})
		},
		{ width: '10%', align: 'center', title: 'Status', dataIndex: 'status', key: 'status', render: (text, record, idx) => {
				if(isEditing && editingRow === idx) {
					return (
						<div style={{width: '100%'}}>
							<Select style={{width: '100%'}} value={record.type}>
								<Select.Option value="Available">Available</Select.Option>
								<Select.Option value="Hold">Hold</Select.Option>
								<Select.Option value="Deposit">Deposit</Select.Option>
								<Select.Option value="Sold">Sold</Select.Option>
								<Select.Option value="Conditional">Conditional</Select.Option>
								<Select.Option value="Unconditional">Unconditional</Select.Option>
								<Select.Option value="Settled">Settled</Select.Option>
							</Select>
						</div>
					);
				} else {
					let color = '#000000';
					return (<span style={{color: color}}>{text}</span>);
				}
			},
			filters: [{"text":"Available","value":"Available"},{"text":"Hold","value":"Hold"},{"text":"Deposit","value":"Deposit"},{"text":"Sold","value":"Sold"},{"text":"Conditional","value":"Conditional"},{"text":"Unconditional","value":"Unconditional"},{"text":"Settled","value":"Settled"}],
			filteredValue: filteredInfo.status || null,
			onFilter: (value, record) => record.status === value
		},
		{ width: '10%', align: 'center', title: 'Conditional', dataIndex: 'condition', key: 'condition', render: (txt, record, idx) => {
				if(isEditing && editingRow === idx) {
					return (
						<div style={{width: '100%'}}>
							<Input value={record.condition} />
						</div>
					);
				} else {
					if(!txt) {
						return (<span>-</span>);
					} else {
						return (<span>{txt}</span>);
					}
				}
			} },
		{ width: '15%', align: 'center', title: 'Price', dataIndex: 'price', key: 'price', render: (txt, record, idx) => {
				if(isEditing && editingRow === idx) {
					return (
						<div style={{width: '100%'}}>
							<Input value={record.price} prefix={'$'} suffix={'AUD'} />
						</div>
					);
				} else {
					return (<span>${formatMoney(txt)}</span>);
				}
			},
			filters: [{ text: '> $200,000', value: 200000 }, { text: '> $225,000', value: 225000 }, { text: '> $250,000', value: 250000 }, { text: '> $275,000', value: 275000 }, { text: '> $300,000', value: 300000 }, { text: '> $325,000', value: 325000 }, { text: '> $350,000', value: 350000 }, { text: '> $375,000', value: 375000 }, { text: '> $400,000', value: 400000 }],
			filteredValue: filteredInfo.price || null,
			onFilter: (value, record) => record.price >= value
		},
		{ width: '10%', align: 'center', title: 'Buyer', dataIndex: 'buyer', key: 'buyer',
			...getNestedStatusTableSearchProps(
				'buyer',
				searchedColumn,
				searchText,
				(txt, record, idx) => {
					if(isEditing && editingRow === idx) {
						const opts = (new Array(50)).fill('')
							.map(() => (new Chance()).name({ nationality: 'en' }));
						console.log(opts);
						return (
							<div style={{width: '100%'}}>
								<AutoComplete dataSource={opts} filterOption={((inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1)} />
							</div>
						);
					} else {
						if(!txt) {
							return (<span>-</span>);
						} else {
							return (<a style={{cursor: 'pointer'}} onClick={() => history.push('/stock-management/lot-101/edit')}>{txt}</a>);
						}
					}
				},
				(selectedKeys, confirm, dataIndex) => {
					confirm();
					setSearchText(selectedKeys[0]);
					setSearchedColumn(dataIndex);
				},
				(clearFilters) => {
					clearFilters();
					setSearchText('');
				})
		},
		{ editable: true, title: 'Note', width: '20%', key: 'note', render: (text, record, idx) => {
				const chance = new Chance();
				if(isEditing && editingRow === idx) {
					return (
						<div style={{width: '100%'}}>
							<Input.TextArea value={chance.sentence()} />
						</div>
					);
				} else {
					return (<span style={{textAlign: 'left !important'}}>{chance.sentence()}</span>);
				}
			} },
		{ width: '15%', align: 'center', title: 'Actions', dataIndex: '', key: 'action', render: (text, record, idx) => {
				if(isEditing && editingRow === idx) {
					return (
						<div>
							<a onClick={() => {
								setEditingRow(null);
								setIsEditing(false);
								notification['success']({
									message: 'Updated Lot Details',
									description: `${record.name} has been updated.`
								});
							}}>Save</a> | <a onClick={() => { setEditingRow(null); setIsEditing(false); }}>Cancel</a>
						</div>
					);
				} else {
					return (
						<div>
							<a onClick={() => {
								setEditingRow(idx);
								setIsEditing(true);
							}}>Edit</a>
						</div>
					);
				}
			} },
	];

	return (
		<React.Fragment>
			<Table className="stock-nested-table" columns={STATUS_COLUMNS} dataSource={DATA} pagination={false} onChange={((pagination, filters, sorter) => setFilteredInfo(filters))} onHeaderRow={column => {
				return {
					className: 'nested-header-row'
				};
			}} />
			<SalesDrawerNew visible={isSalesDrawerVisible} onClose={() => setSalesDrawerVisible(false)} onSubmitSuccess={() => setSalesDrawerVisible(false)} />
		</React.Fragment>
	);
};

export default NestedStatusTable;
