import {Col, DatePicker, Drawer, Form, Input, Row, Select} from "antd";
import React from "react";

const { Option } = Select;

const UpdateProfileForm = (props) => {
	const { getFieldDecorator } = props.form;
	return (
		<Form layout="vertical" hideRequiredMark>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item label="First Name">
						{getFieldDecorator('firstName', {
							rules: [{ required: true, message: 'Please enter first name' }],
						})(<Input placeholder="Please enter first name" />)}
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Last Name">
						{getFieldDecorator('lastName', {
							rules: [{ required: true, message: 'Please enter last name' }],
						})(<Input placeholder="Please enter first name" />)}
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item label="Email Address">
						{getFieldDecorator('email', {
							rules: [{ type: 'email', message: 'The input is not valid email!' }, { required: true, message: 'Please enter a email address' }],
						})(<Input placeholder="Please enter a email address" type="email" />)}
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Phone Number">
						{getFieldDecorator('phone', {
							rules: [{ required: true, message: 'Please input a phone number' }],
						})(<Input placeholder="Please enter a phone number" type="phone" />)}
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item label="Status">
						{getFieldDecorator('status', {
							rules: [{ required: true, message: 'Please select a lead status' }],
						})(
							<Select placeholder="Please select a lead status">
								<Option value="cold">Cold</Option>
								<Option value="warm">Warm</Option>
								<Option value="hot">Hot</Option>
								<Option value="industry">Industry</Option>
								<Option value="deposit">Deposit</Option>
								<Option value="purchaser">Purchaser</Option>
							</Select>,
						)}
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Assigned Agent">
						{getFieldDecorator('agent', {
							rules: [{ required: true, message: 'Please assign an agent to this lead' }],
						})(
							<Select placeholder="Please assign an agent to this lead">
								<Option value="1">Example Agent A</Option>
								<Option value="2">Example Agent B</Option>
								<Option value="3">Example Agent C</Option>
								<Option value="4">Example Agent D</Option>
							</Select>,
						)}
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
};

export default Form.create()(UpdateProfileForm);
