import {Button, Card, Col, Form, Icon, List, Modal, notification, PageHeader, Row, Skeleton, Upload} from "antd";
import React from "react";

const UploadFilesDetailsForm = ({onPrevClick, onNextClick}) => {
	return (
		<Row gutter={[16, 16]} type={`flex`}>
			<Col span={24}>
				<Card style={{padding: 0}}>
					<PageHeader className="inv-management-header" style={{padding: 0}} title={`Lot 101`} extra={[
						<Button type="default" onClick={() => onPrevClick()}>Back</Button>,
						<Button type="primary" onClick={() => onNextClick()}>Next</Button>
					]} />
				</Card>
			</Col>
			<Col span={12}>
				<Card title={`Upload Files`}>
					<Upload.Dragger name={`file`} multiple={true} style={{height: '100%'}}>
						<p className="ant-upload-drag-icon">
							<Icon type="inbox" />
						</p>
						<p className="ant-upload-text">Click or drag file to this area to upload</p>
					</Upload.Dragger>
				</Card>
			</Col>
			<Col span={12}>
				<Card title={`Files List`}>
					<List bordered={true} header="Files">
						<List.Item actions={[
							<Button type="danger" icon="delete" onClick={() => {
								Modal.confirm({
									title: 'Delete File?',
									content: 'This action will irretrievably delete this file, are you sure you want to do this?',
									onOk() {
										notification['info']({
											message: 'File Deleted',
											description: 'This file has been deleted.'
										});
									}
								});
							}}>Delete File</Button>,
							<Button type="primary" icon="download">Download File</Button>,
						]}>
							Example-File.pdf
						</List.Item>
						<List.Item actions={[
							<Button type="danger" icon="delete" onClick={() => {
								Modal.confirm({
									title: 'Delete File?',
									content: 'This action will irretrievably delete this file, are you sure you want to do this?',
									onOk() {
										notification['info']({
											message: 'File Deleted',
											description: 'This file has been deleted.'
										});
									}
								});
							}}>Delete File</Button>,
							<Button type="primary" icon="download">Download File</Button>,
						]}>
							Example-File.pdf
						</List.Item>
						<List.Item actions={[
							<Button type="danger" icon="delete" onClick={() => {
								Modal.confirm({
									title: 'Delete File?',
									content: 'This action will irretrievably delete this file, are you sure you want to do this?',
									onOk() {
										notification['info']({
											message: 'File Deleted',
											description: 'This file has been deleted.'
										});
									}
								});
							}}>Delete File</Button>,
							<Button type="primary" icon="download">Download File</Button>,
						]}>
							Example-File.pdf
						</List.Item>
						<List.Item actions={[
							<Button type="danger" icon="delete" onClick={() => {
								Modal.confirm({
									title: 'Delete File?',
									content: 'This action will irretrievably delete this file, are you sure you want to do this?',
									onOk() {
										notification['info']({
											message: 'File Deleted',
											description: 'This file has been deleted.'
										});
									}
								});
							}}>Delete File</Button>,
							<Button type="primary" icon="download">Download File</Button>,
						]}>
							Example-File.pdf
						</List.Item>
						<List.Item actions={[
							<Button type="danger" icon="delete" onClick={() => {
								Modal.confirm({
									title: 'Delete File?',
									content: 'This action will irretrievably delete this file, are you sure you want to do this?',
									onOk() {
										notification['info']({
											message: 'File Deleted',
											description: 'This file has been deleted.'
										});
									}
								});
							}}>Delete File</Button>,
							<Button type="primary" icon="download">Download File</Button>,
						]}>
							Example-File.pdf
						</List.Item>
					</List>
				</Card>
			</Col>
		</Row>
	);
};

export default UploadFilesDetailsForm;
