import InSiteFrame from "../components/frame/InSiteFrame";
import React from "react";
import {Avatar, Badge, Breadcrumb, Button, Calendar, Card, Col, Icon, List, Row} from "antd";
import {DatePickerStripShitVersion} from "../components/datepicker/Datepicker";

// section for test data

const data = [
	{
		title: 'Follow up phone-call with Example Person',
	},
	{
		title: 'Send price-list to Other Person',
	},
	{
		title: 'Contract signing with Purchasing Person',
	},
	{
		title: 'Sales meeting with Boss Person',
	},
];

function getListData(value) {
	let listData;
	switch (value.date()) {
		case 8:
			listData = [
				{ type: 'warning', content: 'This is warning event.' },
				{ type: 'success', content: 'This is usual event.' },
			];
			break;
		case 10:
			listData = [
				{ type: 'warning', content: 'This is warning event.' },
				{ type: 'success', content: 'This is usual event.' },
				{ type: 'error', content: 'This is error event.' },
			];
			break;
		case 15:
			listData = [
				{ type: 'warning', content: 'This is warning event' },
				{ type: 'success', content: 'This is very long usual event。。....' },
				{ type: 'error', content: 'This is error event 1.' },
				{ type: 'error', content: 'This is error event 2.' },
				{ type: 'error', content: 'This is error event 3.' },
				{ type: 'error', content: 'This is error event 4.' },
			];
			break;
		default:
	}
	return listData || [];
}

function dateCellRender(value) {
	const listData = getListData(value);
	return (
		<ul className="events">
			{listData.map(item => (
				<li key={item.content}>
					<Badge status={item.type} text={item.content} />
				</li>
			))}
		</ul>
	);
}

function getMonthData(value) {
	if (value.month() === 8) {
		return 1394;
	}
}

function monthCellRender(value) {
	const num = getMonthData(value);
	return num ? (
		<div className="notes-month">
			<section>{num}</section>
			<span>Backlog number</span>
		</div>
	) : null;
}

// end test data section

const MyWorkDayPage = (props) => {
	return (
		<InSiteFrame>
			<Row gutter={[16, 16]} type={`flex`} style={{alignItems: 'flex-end'}}>
				<Col span={24}>
					<Breadcrumb style={{alignItems: 'flex-end'}}>
						<Breadcrumb.Item>My Work Day</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			<Row gutter={[16, 16]} type={`flex`}>
				<Col span={16}>
					<Card title={`My Planner`} extra={<Button type="primary" icon={`plus`}>Add Calendar Entry</Button>}>
						<Calendar dateCellRender={dateCellRender} monthCellRender={monthCellRender} />
					</Card>
				</Col>
				<Col span={8}>
					<Card title={`My Todo List`} extra={<Button type="primary" icon={`plus`}>Add Todo</Button>}>
						<List
							itemLayout="horizontal"
							dataSource={data}
							renderItem={item => (
								<List.Item extra={[<Button type="primary" shape="circle" icon={`check`} />, <Button style={{marginLeft: '8px'}} type="primary" shape="circle" icon={`close`} />]}>
									<List.Item.Meta
										avatar={<Avatar src="https://storage.googleapis.com/proj.insitelogic.com.au/etc/todo-icon.svg" />}
										title={<span>{item.title}</span>}
										description="This is an example todo item"
									/>
								</List.Item>
							)} />
					</Card>
				</Col>
			</Row>
		</InSiteFrame>
	);
};

export default MyWorkDayPage;
