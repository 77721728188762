import { Table, Input, Button, Icon } from 'antd';
import Highlighter from 'react-highlight-words';
import React, {useEffect} from "react";
import Chance from "chance";
import { withRouter } from 'react-router-dom';
import {generateStarRating} from "../../icons/StarIcons";
import moment from "moment";

import Actioned from './../../../imgs/note-2.png';
import Unactioned from './../../../imgs/note-0.png';
import Review from './../../../imgs/note-1.png';

function testPersonGenerator() {
	const chance = new Chance();
	return {
		key: chance.string(),
		name: chance.name({nationality: 'en'}),
		lastNote: chance.paragraph({sentences: 1}),
		lastNoteType: chance.pickone(["Note", "Phone", "Email", "Meeting"]),
		lastNoteDate: chance.date({string: true, american: false}),
		rating: chance.integer({min: 0, max: 5}),
		salesAgent: chance.name({nationality: 'en'}),
		status: chance.pickone(["", "Cold", "Warm", "Hot"]),
		dateIdentified: chance.date({string: true, american: false}),
		lastActive: chance.date({string: true, american: false}),
		notes: chance.pickone(["Actioned", "Unactioned", "To Review"]),
	};
}

function generateTestData(amount) {
	let buffer = [];
	for(let i = 0; i < amount; i++) {
		buffer.push(testPersonGenerator());
	}

	return buffer;
}

const data = generateTestData(100);

class InSiteLeadsDataTable extends React.Component {
	state = {
		searchText: '',
		searchedColumn: '',
		filteredInfo: null
	};

	componentDidMount() {
		this.props.setCount(data.length);
	}

	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={node => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon="search"
					size="small"
					style={{ width: 90, marginRight: 8 }}
				>
					Search
				</Button>
				<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					Reset
				</Button>
			</div>
		),
		filterIcon: filtered => (
			<Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
		),
		onFilter: (value, record) =>
			record[dataIndex]
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
		render: text =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text.toString()}
				/>
			) : (
				text
			),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	handleReset = clearFilters => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	selectRow = record => {
		this.props.history.push('/profile');
	};

	handleChange = (pagination, filters, sorter) => {
		console.log('Various parameters', pagination, filters, sorter);
		this.setState({
			filteredInfo: filters
		});
	};

	render() {
		let { filteredInfo } = this.state;
		filteredInfo = filteredInfo || {};
		let self = this;

		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
				render: text => <a>{text}</a>,
				...this.getColumnSearchProps('name'),
			},
			{
				title: 'Latest Note',
				dataIndex: 'lastNote',
				key: 'lastNote',
				width: 500,
				sorter: (a, b) => moment(a.lastNoteDate, "D/M/YYYY").diff(moment(b.lastNoteDate, "D/M/YYYY")),
				filters: [{ text: 'Note', value: 'Note' },{ text: 'Phone', value: 'Phone' },{ text: 'Email', value: 'Email' },{ text: 'Meeting', value: 'Meeting' }],
				filteredValue: filteredInfo.lastNote || null,
				onFilter: (value, record) => record.lastNoteType === value,
				render: (txt, record, idx) => {
					let icon = null;
					let formattedDate = moment(record.lastNoteDate, "D/M/YYYY").format("ddd, Do MMM Y");
					let noteContent = txt;

					switch (record.lastNoteType) {
						case "Note":
							icon = "form";
							break;
						case "Phone":
							icon = "phone";
							break;
						case "Email":
							icon = "mail";
							break;
						case "Meeting":
							icon = "team";
							break;
						default:
							break;
					}

					return (
						<span><Icon type={icon} /> ({formattedDate}) - {noteContent}</span>
					);
				}
			},
			{
				title: 'Rating',
				dataIndex: 'rating',
				key: 'rating',
				sorter: (a, b) => a.rating - b.rating,
				render: (text, record, idx) => {
					return generateStarRating(record.rating);
				}
			},
			{
				title: 'Sales Agent',
				dataIndex: 'salesAgent',
				key: 'salesAgent',
				...this.getColumnSearchProps('salesAgent'),
			},
			{
				title: 'Status',
				dataIndex: 'status',
				key: 'status',
				filters: [{ text: 'Unactioned', value: '' },{ text: 'Cold', value: 'Cold' },{ text: 'Warm', value: 'Warm' },{ text: 'Hot', value: 'Hot' }],
				filteredValue: filteredInfo.status || null,
				onFilter: (value, record) => record.status === value,
				render: (text) => {
					let txtColor = "#000000";
					switch (text) {
						case "Cold":
							txtColor = "#44b6ff";
							break;
						case "Warm":
							txtColor = "#ffc444";
							break;
						case "Hot":
							txtColor = "#ff4444";
							break;
						default:
							break;
					}
					return (<span style={{ color: txtColor }}>{text}</span>);
				}
			},
			{
				title: 'Date Identified',
				dataIndex: 'dateIdentified',
				key: 'dateIdentified',
				sorter: (a, b) => moment(a.dateIdentified, "D/M/YYYY").diff(moment(b.dateIdentified, "D/M/YYYY"))
			},
			{
				title: 'Date Last Active',
				dataIndex: 'lastActive',
				key: 'lastActive',
				sorter: (a, b) => moment(a.lastActive, "D/M/YYYY").diff(moment(b.lastActive, "D/M/YYYY"))
			},
			{
				title: 'Notes',
				dataIndex: 'notes',
				key: 'notes',
				filters: [{text: "Actioned", value: "Actioned"}, {text: "Unactioned", value: "Unactioned"}, {text: "To Review", value: "To Review"}],
				filteredValue: filteredInfo.notes || null,
				onFilter: (value, record) => record.notes === value,
				render: (txt) => {
					switch (txt) {
						case "To Review":
							return (<img src={Review} alt="To Review" />);
						case "Actioned":
							return (<img src={Actioned} alt="Actioned" />);
						case "Unactioned":
							return (<img src={Unactioned} alt="Unactioned" />);
						default:
							return (<img src={Unactioned} alt="Unactioned" />);
					}
				}
			}
		];

		return <Table className="insite" columns={columns} dataSource={data} pagination={{defaultPageSize: 25}} onChange={this.handleChange} onRow={(record) => ({
			onClick: () => {
				this.selectRow(record);
			},
		})} />;
	}
}

export default withRouter(InSiteLeadsDataTable);
