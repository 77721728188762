import React from "react";
import {Card, Col, Form, Row} from "antd";
import WrappedInSiteLoginForm from "../components/forms/LoginForm";

export default function LoginPage() {
	return (
		<div className={`login__fullpage_container`}>
			<Row align={`middle`} justify={`center`} type={`flex`} style={{height: "100%"}}>
				<Col span={8}>
					<div style={{marginBottom: '16px'}}>
						<img style={{width: '75%', margin: '0 auto', display: 'block'}} src="https://storage.googleapis.com/proj.insitelogic.com.au/etc/ef9d5d2ab7fc0c03f06e5b9727ac15c6_-victoria-frankston-city-seaford-biggin-scott-seafordhtml.png" alt=""/>
					</div>
					<Card>
						<h1>Login</h1>
						<WrappedInSiteLoginForm/>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
