const GSUITE_CLIENT_ID = '436530609991-tle45qq6p2qno2ulg3km53gi34ipiqs8.apps.googleusercontent.com';
const GSUITE_SECRET = 'cOKP8u8MSzjoedL0BCOJCxRL';
const API_KEY = 'AIzaSyAwzIZGfGTLIE3LoQ-mgKTo5DfB6QTMEJg';

export default function initGsuiteIntegration(authenticationListenerCallback) {
	gapi.load('client:auth2', function () {
		postApiLoad(authenticationListenerCallback);
	});
}

function postApiLoad(authenticationListenerCallback) {
	gapi.client.init({
		apiKey: API_KEY,
		discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
		clientId: GSUITE_CLIENT_ID,
		scope: 'https://www.googleapis.com/auth/calendar'
	}).then(function () {
		gapi.auth2.getAuthInstance().isSignedIn.listen(authenticationListenerCallback);
		authenticationListenerCallback(gapi.auth2.getAuthInstance().isSignedIn.get());
	});
}
