import React from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
} from "react-router-dom";
import LoginPage from "../pages/Login";
import OverviewPage from "../pages/Overview";
import LeadsPage from "../pages/Leads";
import MyWorkDayPage from "../pages/Planner";
import AdminHomePage from "../pages/admin/AdminHome";
import RegisterPage from "../pages/Register";
import IntegrationsPage from "../pages/admin/Integrations";
import MarketingReportPage from "../pages/MarketingReport";
import DashboardPage from "../pages/Dashboard";
import TrafficPage from "../pages/Traffic";
import DownloadsPage from "../pages/Downloads";
import PackagePage from "../pages/Package";
import ProfileDaveVersion from "../pages/ProfileDaveVersion";
import StockManagementLotPage from "../pages/StockManagementLot";
import StockManagementAltPage from "../pages/stockManagementAlt";
import CorpHomePage from "../pages/CorpHome";
import CorpLeadsPage from "../pages/CorpLeads";
import StockManagementStepFormPage from "../pages/StockManagementStepForm";

export default function AppRouter() {
	return (
		<Router>
			<Switch>
				<Route exact path="/">
					<LoginPage />
				</Route>
				<Route exact path="/corporate">
					<CorpHomePage />
				</Route>
				<Route exact path="/corporate/leads">
					<CorpLeadsPage />
				</Route>
				<Route exact path="/corporate/inventory">
					<CorpHomePage />
				</Route>
				<Route exact path="/dashboard">
					<DashboardPage />
				</Route>
				<Route exact path="/overview">
					<OverviewPage />
				</Route>
				<Route exact path="/planner">
					<MyWorkDayPage />
				</Route>
				<Route exact path="/leads">
					<LeadsPage />
				</Route>
				<Route exact path="/profile">
					<ProfileDaveVersion />
				</Route>
				<Route exact path="/register">
					<RegisterPage />
				</Route>
				<Route exact path="/marketing-report">
					<MarketingReportPage />
				</Route>
				<Route exact path="/stock-management/lot-101">
					<StockManagementLotPage />
				</Route>
				<Route exact path="/stock-management/lot-101/edit">
					<StockManagementStepFormPage />
				</Route>
				<Route exact path="/stock-management">
					<StockManagementAltPage />
				</Route>
				<Route exact path="/house">
					<PackagePage />
				</Route>
				<Route exact path="/actions">
					<DownloadsPage />
				</Route>
				<Route exact path="/traffic">
					<TrafficPage />
				</Route>
				<Route exact path="/admin">
					<AdminHomePage />
				</Route>
				<Route exact path="/integrations">
					<IntegrationsPage />
				</Route>
			</Switch>
		</Router>
	);
}
