import React, {useState} from "react";
import {
	Drawer,
	Form,
	Button,
	Col,
	Row,
	Input,
	Select,
	DatePicker,
	Icon,
	Upload,
	List,
	Divider,
	AutoComplete, Collapse, Typography, Checkbox, Descriptions, Modal
} from 'antd';

const SalesDrawerNew = (props) => {
	const [showAddPurchaserDetails, setShowAddPurchaserDetails] = useState(false);
	const [showAddContactDetails, setShowAddContactDetails] = useState(false);

	const opts = [
		'Lot 101',
		'Lot 102',
		'Lot 103',
		'Lot 104',
		'Lot 105',
		'Lot 106',
		'Lot 107',
		'Lot 108',
		'Lot 109',
		'Lot 110',
	];

	return (
		<Drawer width={720} title={`Add Sale/Deposit`} visible={props.visible} onClose={props.onClose}>
			<Form>
				<Form.Item label={`Name`} style={{marginBottom: '8px'}}>
					<Input value={`Example Profile`} disabled={true} />
				</Form.Item>
				<Form.Item label={`Email`} style={{marginBottom: '8px'}}>
					<Input value={`example@insitelogic.com.au`} disabled={true} />
				</Form.Item>
				<Divider>Deposit</Divider>
				<Row gutter={[16, 16]} type={`flex`}>
					<Col span={8}>
						<Form.Item label={`Initial Deposit`} style={{marginBottom: '8px'}}>
							<Input prefix="$" value={`2,000`} suffix="AUD" />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={`Balance Deposit`} style={{marginBottom: '8px'}}>
							<Input prefix="$" value={``} suffix="AUD" />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={`Contract Signing`} style={{marginBottom: '8px'}}>
							<Input prefix="$" value={``} suffix="AUD" />
						</Form.Item>
					</Col>
				</Row>

				<Divider>Purchaser Details</Divider>
				<Collapse accordion={true}>
					<Collapse.Panel header="Purchaser Details" key="1" extra={[<Icon style={{fontSize: '22px'}} key={1} type="plus-circle" onClick={() => setShowAddPurchaserDetails(true)} />]}>
						<List bordered={true} size="small">
							<List.Item key={1}>
								<Descriptions className="stock-management" layout="vertical" size="small">
									<Descriptions.Item label="Title">
										<Typography.Text style={{fontStyle: 'italic'}}>Mr.</Typography.Text>
									</Descriptions.Item>
									<Descriptions.Item label="First/Middle Name">
										<Typography.Text style={{fontStyle: 'italic'}}>Test</Typography.Text>
									</Descriptions.Item>
									<Descriptions.Item label="Surname">
										<Typography.Text style={{fontStyle: 'italic'}}>Person</Typography.Text>
									</Descriptions.Item>
								</Descriptions>
							</List.Item>
							<List.Item key={2}>
								<Descriptions className="stock-management" layout="vertical" size="small">
									<Descriptions.Item label="Company Details">
										<Typography.Text style={{fontStyle: 'italic'}}>Example Company Pty Ltd.</Typography.Text>
									</Descriptions.Item>
								</Descriptions>
							</List.Item>
						</List>
					</Collapse.Panel>
				</Collapse>

				<Divider>Contact Details</Divider>
				<Collapse accordion={true}>
					<Collapse.Panel header="Contact Details" key="1" extra={[<Icon style={{fontSize: '22px'}} key={1} type="plus-circle" onClick={() => setShowAddContactDetails(true)} />]}>
						<List bordered={true} size="small">
							<List.Item key={1}>
								<Descriptions className="stock-management" layout="vertical" size="small">
									<Descriptions.Item label="Home">
										<Typography.Text style={{fontStyle: 'italic'}}>5222 0000</Typography.Text>
									</Descriptions.Item>
									<Descriptions.Item label="Mobile">
										<Typography.Text style={{fontStyle: 'italic'}}>0400 000 000</Typography.Text>
									</Descriptions.Item>
									<Descriptions.Item label="Email">
										<Typography.Text style={{fontStyle: 'italic'}}>example@insitelogic.com.au</Typography.Text>
									</Descriptions.Item>
								</Descriptions>
							</List.Item>
						</List>
					</Collapse.Panel>
				</Collapse>

				<Divider>Solicitor Details</Divider>
				<Row gutter={[16, 16]} type={`flex`}>
					<Col span={12}>
						<Form.Item label={`Trading Name`} style={{marginBottom: '8px'}}>
							<Input value="" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label={`Contact Name`} style={{marginBottom: '8px'}}>
							<Input value="" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]} type={`flex`}>
					<Col span={12}>
						<Form.Item label={`Address`} style={{marginBottom: '8px'}}>
							<Input value="" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label={`Contact Email`} style={{marginBottom: '8px'}}>
							<Input value="" />
						</Form.Item>
					</Col>
				</Row>

				<Divider>Sales Details</Divider>
				<Row gutter={[16, 16]} type={`flex`}>
					<Col span={12}>
						<Form.Item label={`Sales Price`} style={{marginBottom: '8px'}}>
							<Input prefix="$" value={``} suffix="AUD" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label={`Lot Number`} style={{marginBottom: '8px'}}>
							<AutoComplete placeholder={`Search for the purchasing lot`} dataSource={opts} filterOption={(inputValue, option) =>
								option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
							} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]} type={`flex`}>
					<Col span={16} style={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
						<Typography.Text>Deposit 10% ______________ of which ______________ is paid.</Typography.Text>
					</Col>
					<Col span={8}>
						<Form.Item label={`Balance Due`} style={{marginBottom: '8px', width: '100%'}}>
							<DatePicker style={{width: '100%'}} />
						</Form.Item>
					</Col>
				</Row>

				<Divider>Conditions</Divider>
				<Row gutter={[16, 16]} type={`flex`}>
					<Col span={6} style={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
						<Checkbox>Has Finance</Checkbox>
					</Col>
					<Col span={18}>
						<Form.Item label={`Lender`} style={{marginBottom: '8px'}}>
							<Input value="" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]} type={`flex`}>
					<Col span={24}>
						<Form.Item label={`Notes`} style={{marginBottom: '8px'}}>
							<Input.TextArea rows={4} />
						</Form.Item>
					</Col>
				</Row>

				<Divider>Attachments</Divider>
				<Form.Item label={`File Uploads`}>
					<Upload.Dragger>
						<p className="ant-upload-drag-icon">
							<Icon type="inbox" />
						</p>
						<p className="ant-upload-text">Click or drag file to this area to upload</p>
						<p className="ant-upload-hint">
							Support for a single or bulk upload. Strictly prohibit from uploading company data or other
							band files
						</p>
					</Upload.Dragger>
					<List style={{marginTop: '8px', marginBottom: '16px'}} size={`small`} header={<span style={{fontWeight: 'bold'}}>Uploaded Files</span>} bordered={true}>
						<List.Item actions={[<a>Download</a>, <a>Delete</a>]}>Requirements.pdf</List.Item>
						<List.Item actions={[<a>Download</a>, <a>Delete</a>]}>Contract Draft A.pdf</List.Item>
					</List>
				</Form.Item>
			</Form>
			<div
				style={{
					position: 'absolute',
					right: 0,
					bottom: 0,
					width: '100%',
					borderTop: '1px solid #e9e9e9',
					padding: '10px 16px',
					background: '#fff',
					textAlign: 'right',
				}}
			>
				<Button onClick={props.onClose} style={{ marginRight: 8 }}>
					Cancel
				</Button>
				<Button onClick={props.onSubmitSuccess} type="primary">
					Submit
				</Button>
			</div>
			<Modal title="Add Purchaser Details" visible={showAddPurchaserDetails} onOk={() => setShowAddPurchaserDetails(false)} onCancel={() => setShowAddPurchaserDetails(false)}>
				<Form>
					<Form.Item label={`Title`} style={{marginBottom: '8px'}}>
						<Input value={``} />
					</Form.Item>
					<Form.Item label={`First/Middle Name`} style={{marginBottom: '8px'}}>
						<Input value={``} />
					</Form.Item>
					<Form.Item label={`Surname`} style={{marginBottom: '8px'}}>
						<Input value={``} />
					</Form.Item>
				</Form>
			</Modal>

			<Modal title="Add Contact Details" visible={showAddContactDetails} onOk={() => setShowAddContactDetails(false)} onCancel={() => setShowAddContactDetails(false)}>
				<Form>
					<Form.Item label={`Home Phone`} style={{marginBottom: '8px'}}>
						<Input value={``} />
					</Form.Item>
					<Form.Item label={`Mobile Phone`} style={{marginBottom: '8px'}}>
						<Input value={``} />
					</Form.Item>
					<Form.Item label={`Email Address`} style={{marginBottom: '8px'}}>
						<Input value={``} />
					</Form.Item>
				</Form>
			</Modal>
		</Drawer>
	);
};

export default SalesDrawerNew;
