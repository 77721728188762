import React from "react";
import InSiteFrame from "../components/frame/InSiteFrame";
import {Breadcrumb, Card, Col, DatePicker, Row} from "antd";
import moment from "moment";
import OverviewDownloadsTable from "../components/table/overview/OverviewDownloadsTable";
import {DatePickerStripShitVersion} from "../components/datepicker/Datepicker";

const DownloadsPage = (props) => {
	return (
		<InSiteFrame>
			<Row gutter={[16, 16]} type={`flex`} style={{alignItems: 'flex-end'}}>
				<Col span={17}>
					<Breadcrumb style={{alignItems: 'flex-end'}}>
						<Breadcrumb.Item>Reporting</Breadcrumb.Item>
						<Breadcrumb.Item>Download Reports</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
				<DatePickerStripShitVersion />
			</Row>
			<Row gutter={[16, 16]} type={`flex`}>
				<Col span={24}>
					<Card title="Download Engagement">
						<OverviewDownloadsTable displayAmount={50} />
					</Card>
				</Col>
			</Row>
		</InSiteFrame>
	);
};

export default DownloadsPage;
