import {Typography} from "antd";
import React from "react";
import formatMoney from "../../../../util/formatMoney";

export default (history) => {
	return [
		{
			title: 'Lot No.',
			dataIndex: 'name',
			key: 'name',
			align: 'center',
			render: (txt, record, idx) => {
				let color = '#000000';
				if (record.status === 'Sold' || record.status === 'Unconditional') {
					color = '#ff4d4f';
				} else if (record.status === 'Deposit' || record.status === 'Conditional') {
					color = '#1890ff';
				} else if (record.status === 'Settled') {
					color = '#a0d911';
				}

				return (
					<Typography.Text style={{color: color}}>
						{txt}
					</Typography.Text>
				);
			}
		},
		{
			align: 'center',
			title: 'Buyer',
			dataIndex: 'buyer',
			key: 'buyer',
			render: (txt, record, idx) => {
				if(!txt) {
					return (<span>-</span>);
				} else {
					return (<a style={{cursor: 'pointer', color: 'black', textDecoration: 'underline'}} onClick={() => history.push('/stock-management/lot-101/edit')}>{txt}</a>);
				}
			}
		},
		{
			align: 'center',
			title: 'Sale Price',
			dataIndex: 'price',
			key: 'price',
			render: (txt, record, idx) => {
				return (<span>${formatMoney(txt)}</span>);
			}
		},
		{
			align: 'center',
			title: 'Conditional Type',
			dataIndex: 'condition',
			key: 'condition',
			render: (txt, record, idx) => {
				if(!txt) {
					return (<span>-</span>);
				} else {
					return (<span>{txt}</span>);
				}
			}
		},
	];
};
