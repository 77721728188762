import { Icon, Typography} from "antd";
import React from "react";
import formatMoney from "../../../../util/formatMoney";

export default (history) => {
	return [
		{
			title: 'Lot No.',
			dataIndex: 'name',
			key: 'name',
			align: 'center',
			render: (txt, record, idx) => {
				let color = '#000000';
				if (record.status === 'Sold' || record.status === 'Unconditional') {
					color = '#ff4d4f';
				} else if (record.status === 'Deposit' || record.status === 'Conditional') {
					color = '#1890ff';
				} else if (record.status === 'Settled') {
					color = '#a0d911';
				}

				return (
					<Typography.Text style={{color: color}}>
						{txt}
					</Typography.Text>
				);
			}
		},
		{
			align: 'center',
			title: 'Buyer',
			dataIndex: 'buyer',
			key: 'buyer',
			render: (txt, record, idx) => {
				if(!txt) {
					return (<span>-</span>);
				} else {
					return (<a style={{cursor: 'pointer', color: 'black', textDecoration: 'underline'}} onClick={() => history.push('/stock-management/lot-101/edit')}>{txt}</a>);
				}
			}
		},
		{
			align: 'center',
			title: 'List Price',
			dataIndex: 'price',
			key: 'price',
			render: (txt, record, idx) => {
				return (<span>${formatMoney(txt)}</span>);
			}
		},
		{
			align: 'center',
			title: 'Initial Deposit',
			dataIndex: 'depositAmt',
			key: 'depositAmt',
			render: (txt, record, idx) => {
				return (<span>${formatMoney(txt)}</span>);
			}
		},
		{
			align: 'center',
			title: 'Balance Deposit',
			dataIndex: 'depositOutstanding',
			key: 'depositOutstanding',
			render: (txt, record, idx) => {
				return (<span>${formatMoney(txt)}</span>);
			}
		},
		{
			align: 'center',
			title: 'Conditional',
			dataIndex: 'condition',
			key: 'condition',
			render: (txt, record, idx) => {
				if(!txt) {
					return (<span>-</span>);
				} else {
					return (<span>{txt}</span>);
				}
			}
		},
		{
			align: 'center',
			title: 'Unconditional',
			dataIndex: 'status',
			key: 'unconditional',
			render: (txt, record, idx) => {
				if(txt === 'Conditional') {
					return (<Icon type="close" />);
				} else if(txt === 'Unconditional') {
					return (<Icon type="check" />);
				} else {
					return (<span>-</span>);
				}
			}
		},
		{
			align: 'center',
			title: 'Rebate',
			dataIndex: 'status',
			key: 'rebate',
			render: (txt, record, idx) => {
				if(txt !== 'Conditional') {
					return (<span>-</span>);
				} else {
					return (<span>{`$${formatMoney(5000)}`}</span>);
				}
			}
		}
	];
};
