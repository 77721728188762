import React from "react";
import ReactApexChart from 'react-apexcharts';

const CONFIG = {
	series: [{
		name: 'Registrations',
		type: 'column',
		data: [4, 6, 2, 1, 3, 4, 3]
	}, {
		name: 'Visits',
		type: 'line',
		data: [112, 93, 67, 126, 187, 98, 88]
	}],
	options: {
		colors: ["#00adef", "#f06123"],
		chart: {
			height: 350,
			type: 'line',
		},
		stroke: {
			width: [0, 4]
		},
		dataLabels: {
			enabled: true,
			enabledOnSeries: [1]
		},
		labels: ["07 Feb 2020", "08 Feb 2020", "09 Feb 2020", "10 Feb 2020", "11 Feb 2020", "12 Feb 2020", "13 Feb 2020"],
		xaxis: {
			type: 'datetime'
		},
		yaxis: [{
			title: {
				text: 'Registrations',
			},

		}, {
			opposite: true,
			title: {
				text: 'Visits'
			}
		}]
	},
};

const InSiteTrafficHistoryChart = (props) => {
	return (
		<ReactApexChart options={CONFIG.options} series={CONFIG.series} type="line" height={350} width="80%" />
	);
};

export default InSiteTrafficHistoryChart;
