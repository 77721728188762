import React from "react";
import ReactApexChart from 'react-apexcharts';

const CONFIG = {
	series: [{
		name: 'Online',
		data: [23]
	}, {
		name: 'Onsite',
		data: [12]
	}],
	options: {
		colors: ["#f06123", "#ffc0a5"],
		chart: {
			type: 'bar',
			height: 280,
			stacked: true,
			stackType: '100%'
		},
		plotOptions: {
			bar: {
				horizontal: true,
			},
		},
		stroke: {
			width: 1,
			colors: ['#fff']
		},
		xaxis: {
			categories: ["Registration Source"],
		},
		tooltip: {
			y: {
				formatter: function (val) {
					return val;
				}
			}
		},
		fill: {
			opacity: 1
		},
		legend: {
			position: 'top',
			horizontalAlign: 'left',
			offsetX: 40
		}
	}
};

const InSiteFillingBarChart = (props) => {
	return (
		<ReactApexChart options={CONFIG.options} series={CONFIG.series} type="bar" height={160} width={'80%'} />
	);
};

export default InSiteFillingBarChart;
