import InSiteDashboardFrame from "../components/frame/InSiteDashboardFrame";
import {Card, Icon, Layout, Menu, Table} from "antd";
import React from "react";
import Chance from "chance";
import moment from "moment";
import {useHistory} from "react-router";

function generateDataSource(amount=50) {
	const chance = new Chance();
	let buffer = [];
	for(let i = 0; i < amount; i++) {
		buffer.push({
			name: chance.name({ nationality: 'en' }),
			email: chance.email(),
			latestNote: chance.sentence(),
			latestNoteType: chance.pickone(["Note", "Phone", "Email", "Meeting"]),
			latestNoteTimestamp: chance.timestamp(),
			registration: chance.pickone(["Online", "On Site", "CRM Integration"]),
			enquirySrc: chance.pickone(["Social Media", "eDM Campaign", "Google Adwords", "Google Search", "Newspaper"]),
			projectId: chance.integer({ min: 1, max: 20 }),
			projectName: chance.pickone(["Project One", "Project Two", "Project Three", "Project Four"]),
			dateCreated: chance.timestamp(),
			lastActive: chance.timestamp()
		});
	}

	return buffer;
}

const CorpLeadsPage = (props) => {
	const history = useHistory();
	const COLUMNS = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name'
		},
		{
			title: 'Email Address',
			dataIndex: 'email',
			key: 'email'
		},
		{
			title: 'Latest Note',
			dataIndex: 'latestNote',
			key: 'latestNote',
			render: (txt, record, idx) => {
				let icon = null;
				let formattedDate = moment.unix(record.latestNoteTimestamp).format("ddd, Do MMM Y");
				let noteContent = txt;

				switch (record.latestNoteType) {
					case "Note":
						icon = "form";
						break;
					case "Phone":
						icon = "phone";
						break;
					case "Email":
						icon = "mail";
						break;
					case "Meeting":
						icon = "team";
						break;
					default:
						break;
				}

				return (
					<span><Icon type={icon} /> ({formattedDate}) - {noteContent}</span>
				);
			}
		},
		{
			title: 'Registration Source',
			dataIndex: 'registration',
			key: 'registration'
		},
		{
			title: 'Enquiry Source',
			dataIndex: 'enquirySrc',
			key: 'enquirySrc'
		},
		{
			title: 'Project',
			dataIndex: 'projectName',
			key: 'projectName'
		},
		{
			title: 'Registration Date',
			dataIndex: 'dateCreated',
			key: 'dateCreated',
			render: (txt) => {
				return moment.unix(txt).format("ddd, Do MMM Y")
			}
		},
		{
			title: 'Date Last Active',
			dataIndex: 'lastActive',
			key: 'lastActive',
			render: (txt) => {
				return moment.unix(txt).format("ddd, Do MMM Y")
			}
		}
	];
	const dataSrc = generateDataSource();

	return (
		<InSiteDashboardFrame>
			<Layout>
				<Layout.Sider className={`sider-no-theme`}>
					<Menu mode="inline">
						<Menu.Item key="dashboard">
							<Icon type="home" />
							<a href="/corporate">Dashboard</a>
						</Menu.Item>
						<Menu.Item key="leads">
							<Icon type="contacts" />
							<a href="/corporate/leads">Leads</a>
						</Menu.Item>
						<Menu.Item key="inventory">
							<Icon type="reconciliation" />
							<a href="/corporate/inventory">Inventory</a>
						</Menu.Item>
						<Menu.SubMenu key="projects" title={
							<span>
								<Icon type="project" />
								<span>Projects</span>
							</span>
						}>
							<Menu.ItemGroup key="land" title="Land">
								<Menu.Item key="1">
									<a href="/overview">Project One</a>
								</Menu.Item>
								<Menu.Item key="2">
									<a href="/overview">Project Two</a>
								</Menu.Item>
							</Menu.ItemGroup>
							<Menu.ItemGroup key="townhomes" title="Medium Density">
								<Menu.Item key="3">
									<a href="/overview">Project Three</a>
								</Menu.Item>
								<Menu.Item key="4">
									<a href="/overview">Project Four</a>
								</Menu.Item>
							</Menu.ItemGroup>
						</Menu.SubMenu>
					</Menu>
					<Menu style={{marginTop: '8px'}}>
						<Menu.ItemGroup title="System">
							<Menu.Item key="admin" onClick={() => history.push('/admin')}>Admin Console</Menu.Item>
							<Menu.Item key="logout" onClick={() => history.replace('/')}>Logout</Menu.Item>
						</Menu.ItemGroup>
					</Menu>
				</Layout.Sider>
				<Layout.Content style={{paddingLeft: '8px'}}>
					<Card title="Corporate Leads">
						<Table className="insite" columns={COLUMNS} dataSource={dataSrc} onRow={(record) => ({
								onClick: () => {
									history.push("/profile")
								},
							})} />
					</Card>
				</Layout.Content>
			</Layout>
		</InSiteDashboardFrame>
	);
};

export default CorpLeadsPage;
