import React from "react";
import ReactApexChart from 'react-apexcharts';
import { Doughnut } from 'react-chartjs-2';
import {Col, Row} from "antd";
import en from "@amcharts/amcharts4/lang/en";

const InSitePieChart = ({id, labels, data, width='60%', alignCenter=true, position='bottom'}) => {
	let opts = {
		chart: {
			height: '280px'
		},
		labels: labels,
		dataLabels: {
			enabled: data.length <= 7
		},
		legend: {
			show: true,
			position: position,
			floating: false,
			formatter: function(seriesName, opts) {
				return [`${seriesName} (${opts.w.globals.series[opts.seriesIndex]})`];
			}
		},
		plotOptions: {
			pie: {
				donut: {
					size: '50%',
					total: {
						show: true,
						showAlways: true
					}
				}
			}
		}
	};

	const style = {};
	if(alignCenter) {
		style.margin = '0 auto';
	}

	return (
		<div id={id} style={style}>
			<ReactApexChart width={width} options={opts} series={data} type={`donut`} />
		</div>
	);
};

const generateRandomColorCode = () => {
	return "#000000".replace(/0/g,function(){return (~~(Math.random()*16)).toString(16);});
};

const zip = (arr1, arr2) => arr1.map((k, i) => [k, arr2[i]]);

export default InSitePieChart;
