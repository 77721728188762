import React from "react";
import { Table } from 'antd';
import Chance from 'chance';
import moment from "moment";

import Actioned from './../../../imgs/note-2.png';
import Unactioned from './../../../imgs/note-0.png';
import Review from './../../../imgs/note-1.png';

const DashboardAgentActivityTable = (props) => {
	const chance = new Chance();
	const COLUMNS = [
		{ title: 'Sales Agent', dataIndex: 'name', key: 'name' },
		{ title: 'Role', dataIndex: 'role', key: 'role' },
		{ title: 'Sales', dataIndex: 'sales', key: 'sales' },
		{ title: 'Listings', dataIndex: 'registrations', key: 'registrations' },
		{ title: 'Last Accessed', dataIndex: 'lastLoggedIn', key: 'lastLoggedIn', render: (txt) => {
			return {
				props: {
					style: {
						background: '#aaffaa'
					}
				},
				children: (
					<div style={{textAlign: 'center'}}>
						{txt}
					</div>
				)
			};
		} },
	];

	const DATA = [
		{ key: chance.integer({ min: 1 }), name: chance.name({nationality: 'en'}), role: 'Sales Agent', sales: chance.integer({ min: 1, max: 10 }), registrations: chance.integer({ min: 20, max: 45 }), lastLoggedIn: moment(chance.date()).format('Mo MMM, YYYY'), ticks: chance.integer({ min: 1, max: 10 }), crosses: chance.integer({ min: 1, max: 10 }), pending: chance.integer({ min: 1, max: 10 }) },
		{ key: chance.integer({ min: 2 }), name: chance.name({nationality: 'en'}), role: 'Sales Agent', sales: chance.integer({ min: 1, max: 10 }), registrations: chance.integer({ min: 20, max: 45 }), lastLoggedIn: moment(chance.date()).format('Mo MMM, YYYY'), ticks: chance.integer({ min: 1, max: 10 }), crosses: chance.integer({ min: 1, max: 10 }), pending: chance.integer({ min: 1, max: 10 }) },
		{ key: chance.integer({ min: 3 }), name: chance.name({nationality: 'en'}), role: 'Sales Agent', sales: chance.integer({ min: 1, max: 10 }), registrations: chance.integer({ min: 20, max: 45 }), lastLoggedIn: moment(chance.date()).format('Mo MMM, YYYY'), ticks: chance.integer({ min: 1, max: 10 }), crosses: chance.integer({ min: 1, max: 10 }), pending: chance.integer({ min: 1, max: 10 }) },
	];

	return ( <Table className="insite" columns={COLUMNS} dataSource={DATA} pagination={false} /> );
};

export default DashboardAgentActivityTable;
