export default [
	{
		key: 0,
		name: 'Stage 1',
		stageNumber: 1,
		releaseDate: '26th May, 2020',
		total: 4,
		released: 4,
		get available() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Available').length },
		get hold() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Hold').length },
		get deposit() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Deposit').length },
		get condition() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Conditional').length },
		get unconditional() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Unconditional').length },
		get settled() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Settled').length },
		get sold() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Sold').length },
		lots: [
			{
				key: 11,
				name: `Lot 101`,
				area: 478,
				frontage: 16,
				price: 330000,
				depth: 32,
				status: 'Hold',
				fall: '<0.3m',
				type: 'Regular',
				released: true,
				condition: 'S.T.F',
				buyer: 'Smith',
				depositAmt: 1000,
				depositDate: 1590994800,
				get depositOutstanding() { return this.price * 0.1 - this.depositAmt },
				get settlementPrice() { return this.price - this.depositOutstanding },
				get settlementDate() { return this.depositDate + (86400 * 30) },
			},
			{
				key: 12,
				name: `Lot 102`,
				area: 336,
				frontage: 11,
				price: 261000,
				depth: 24,
				status: 'Available',
				fall: '<0.3m',
				type: 'Regular',
				released: true,
				condition: '',
				buyer: '',
				depositAmt: null,
				depositDate: null,
				get depositOutstanding() { return null; },
				get settlementPrice() { return null; },
				get settlementDate() { return null; },
			},
			{
				key: 13,
				name: `Lot 103`,
				area: 327,
				frontage: 13,
				price: 273000,
				depth: 22,
				status: 'Deposit',
				fall: '<0.3m',
				type: 'Corner',
				released: true,
				condition: 'S.T.F',
				buyer: 'Baggins',
				depositAmt: 1000,
				depositDate: 1590994800,
				get depositOutstanding() { return this.price * 0.1 - this.depositAmt },
				get settlementPrice() { return this.price - this.depositOutstanding },
				get settlementDate() { return this.depositDate + (86400 * 30) },
			},
			{
				key: 14,
				name: `Lot 104`,
				area: 476,
				frontage: 15,
				price: 312000,
				depth: 30,
				status: 'Deposit',
				fall: '<0.3m',
				type: 'Regular',
				released: true,
				condition: 'S.T.F',
				buyer: 'Took',
				depositAmt: 1000,
				depositDate: 1590994800,
				get depositOutstanding() { return this.price * 0.1 - this.depositAmt },
				get settlementPrice() { return this.price - this.depositOutstanding },
				get settlementDate() { return this.depositDate + (86400 * 30) },
			}
		]
	},
	{
		key: 1,
		name: 'Stage 2',
		stageNumber: 2,
		releaseDate: '20th May, 2020',
		total: 4,
		released: 4,
		get available() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Available').length },
		get hold() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Hold').length },
		get deposit() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Deposit').length },
		get condition() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Conditional').length },
		get unconditional() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Unconditional').length },
		get settled() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Settled').length },
		get sold() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Sold').length },
		lots: [
			{
				key: 21,
				name: `Lot 201`,
				area: 478,
				frontage: 16,
				price: 330000,
				depth: 32,
				status: 'Sold',
				fall: '<0.3m',
				type: 'Regular',
				released: true,
				condition: '',
				buyer: 'Banks Builders Pty Ltd.',
				depositAmt: 1000,
				depositDate: 1590994800,
				get depositOutstanding() { return this.price * 0.1 - this.depositAmt },
				get settlementPrice() { return this.price - this.depositOutstanding },
				get settlementDate() { return this.depositDate + (86400 * 30) },
			},
			{
				key: 22,
				name: `Lot 202`,
				area: 336,
				frontage: 11,
				price: 261000,
				depth: 24,
				status: 'Available',
				fall: '<0.3m',
				type: 'Corner',
				released: true,
				condition: '',
				buyer: '',
				depositAmt: null,
				depositDate: null,
				get depositOutstanding() { return null; },
				get settlementPrice() { return null; },
				get settlementDate() { return null; },
			},
			{
				key: 23,
				name: `Lot 203`,
				area: 327,
				frontage: 13,
				price: 273000,
				depth: 22,
				status: 'Available',
				fall: '<0.3m',
				type: 'Regular',
				released: true,
				condition: '',
				buyer: '',
				depositAmt: null,
				depositDate: null,
				get depositOutstanding() { return null; },
				get settlementPrice() { return null; },
				get settlementDate() { return null; },
			},
			{
				key: 24,
				name: `Lot 204`,
				area: 476,
				frontage: 15,
				price: 312000,
				depth: 30,
				status: 'Settled',
				fall: '<0.3m',
				type: 'Smart',
				released: true,
				condition: '',
				buyer: 'Bolger',
				depositAmt: 1000,
				depositDate: 1590994800,
				get depositOutstanding() { return this.price * 0.1 - this.depositAmt },
				get settlementPrice() { return this.price - this.depositOutstanding },
				get settlementDate() { return this.depositDate + (86400 * 30) },
			}
		]
	},
	{
		key: 2,
		name: 'Stage 3',
		stageNumber: 3,
		releaseDate: '14th May, 2020',
		total: 4,
		released: 4,
		get available() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Available').length },
		get hold() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Hold').length },
		get deposit() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Deposit').length },
		get condition() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Conditional').length },
		get unconditional() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Unconditional').length },
		get settled() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Settled').length },
		get sold() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Sold').length },
		lots: [
			{
				key: 31,
				name: `Lot 301`,
				area: 478,
				frontage: 16,
				price: 330000,
				depth: 32,
				status: "Conditional",
				fall: '<0.3m',
				type: 'Regular',
				released: true,
				condition: 'S.T.F',
				buyer: 'Diggle',
				depositAmt: 1000,
				depositDate: 1590994800,
				get depositOutstanding() { return this.price * 0.1 - this.depositAmt },
				get settlementPrice() { return this.price - this.depositOutstanding },
				get settlementDate() { return this.depositDate + (86400 * 30) },
			},
			{
				key: 32,
				name: `Lot 302`,
				area: 336,
				frontage: 11,
				price: 261000,
				depth: 24,
				status: "Conditional",
				fall: '<0.3m',
				type: 'Corner',
				released: true,
				condition: 'S.T.F',
				buyer: 'Gamgee',
				depositAmt: 1000,
				depositDate: 1590994800,
				get depositOutstanding() { return this.price * 0.1 - this.depositAmt },
				get settlementPrice() { return this.price - this.depositOutstanding },
				get settlementDate() { return this.depositDate + (86400 * 30) },
			},
			{
				key: 33,
				name: `Lot 303`,
				area: 327,
				frontage: 13,
				price: 273000,
				depth: 22,
				status: "Conditional",
				fall: '<0.3m',
				type: 'Regular',
				released: true,
				condition: 'S.T.F',
				buyer: 'Hayward',
				depositAmt: 1000,
				depositDate: 1590994800,
				get depositOutstanding() { return this.price * 0.1 - this.depositAmt },
				get settlementPrice() { return this.price - this.depositOutstanding },
				get settlementDate() { return this.depositDate + (86400 * 30) },
			},
			{
				key: 34,
				name: `Lot 304`,
				area: 476,
				frontage: 15,
				price: 312000,
				depth: 30,
				status: "Unconditional",
				fall: '<0.3m',
				type: 'Smart',
				released: true,
				condition: '',
				buyer: 'Tunnelly',
				depositAmt: 1000,
				depositDate: 1590994800,
				get depositOutstanding() { return this.price * 0.1 - this.depositAmt },
				get settlementPrice() { return this.price - this.depositOutstanding },
				get settlementDate() { return this.depositDate + (86400 * 30) },
			}
		]
	},
	{
		key: 3,
		name: 'Stage 4',
		stageNumber: 4,
		releaseDate: '5th May, 2020',
		total: 4,
		released: 4,
		get available() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Available').length },
		get hold() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Hold').length },
		get deposit() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Deposit').length },
		get condition() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Conditional').length },
		get unconditional() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Unconditional').length },
		get settled() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Settled').length },
		get sold() { return this.lots.map((lot) => lot.status).filter((status) => status === 'Sold').length },
		lots: [
			{
				key: 41,
				name: `Lot 401`,
				area: 478,
				frontage: 16,
				price: 330000,
				depth: 32,
				status: 'Sold',
				fall: '<0.3m',
				type: 'Regular',
				released: true,
				condition: '',
				buyer: 'Proudfoot',
				depositAmt: 1000,
				depositDate: 1590994800,
				get depositOutstanding() { return this.price * 0.1 - this.depositAmt },
				get settlementPrice() { return this.price - this.depositOutstanding },
				get settlementDate() { return this.depositDate + (86400 * 30) },
			},
			{
				key: 42,
				name: `Lot 402`,
				area: 336,
				frontage: 11,
				price: 261000,
				depth: 24,
				status: 'Settled',
				fall: '<0.3m',
				type: 'Corner',
				released: true,
				condition: '',
				buyer: 'Sackville-Stoor Pty Ltd.',
				depositAmt: 1000,
				depositDate: 1590994800,
				get depositOutstanding() { return this.price * 0.1 - this.depositAmt },
				get settlementPrice() { return this.price - this.depositOutstanding },
				get settlementDate() { return this.depositDate + (86400 * 30) },
			},
			{
				key: 43,
				name: `Lot 403`,
				area: 327,
				frontage: 13,
				price: 273000,
				depth: 22,
				status: 'Sold',
				fall: '<0.3m',
				type: 'Regular',
				released: true,
				condition: '',
				buyer: 'Underhill',
				depositAmt: 1000,
				depositDate: 1590994800,
				get depositOutstanding() { return this.price * 0.1 - this.depositAmt },
				get settlementPrice() { return this.price - this.depositOutstanding },
				get settlementDate() { return this.depositDate + (86400 * 30) },
			},
			{
				key: 44,
				name: `Lot 404`,
				area: 476,
				frontage: 15,
				price: 312000,
				depth: 30,
				status: 'Conditional',
				fall: '<0.3m',
				type: 'Smart',
				released: true,
				condition: 'S.T.F',
				buyer: 'Whitfoot Trust Pty Ltd.',
				depositAmt: 1000,
				depositDate: 1590994800,
				get depositOutstanding() { return this.price * 0.1 - this.depositAmt },
				get settlementPrice() { return this.price - this.depositOutstanding },
				get settlementDate() { return this.depositDate + (86400 * 30) },
			}
		]
	},
];
