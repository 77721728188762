import {Card, Table} from "antd";
import React from "react";

const ListingsTable = (props) => {
	const columns = [
		{ key: 'address', dataIndex: 'address', title: 'Address' },
		{ key: 'type', dataIndex: 'type', title: 'Type' },
		{ key: 'agent', dataIndex: 'agent', title: 'Agent' },
	];

	const data = [
		{ key: '1', address: '14 Telmoor Lane', type: 'Auction', agent: 'Smith' },
		{ key: '2', address: '21 Rehsus Street', type: 'Auction', agent: 'Brown' },
		{ key: '3', address: '62 York Court', type: 'Private', agent: 'Long' },
		{ key: '4', address: '87 Camel Court', type: 'Auction', agent: 'Burger' },
		{ key: '5', address: '4 Tesla Way', type: 'Private', agent: 'Long' },
		{ key: '6', address: '112 Check Road', type: 'Auction', agent: 'Brown' },
	];

	return (
		<Card title="Listings (Aug 2020)" extra={<a href="#">View All Listings</a>}>
			<Table className="insite" dataSource={data} columns={columns} pagination={{defaultPageSize: 5}} />
		</Card>
	);
};

export default ListingsTable;
