import {Card, Col, Row, Statistic} from "antd";
import DashboardAgentActivityTable from "../table/dashboard/DashboardAgentActivityTable";
import React from "react";
import {useHistory} from 'react-router';
import Chance from "chance";

const DashboardProjectTile = ({ title, ytdSales, ytdListings, currSales, currListings }) => {
	const history = useHistory();
	return (
		<Col xxl={24} xl={24} lg={12} md={24} sm={24} xs={24} style={{cursor: 'pointer'}} onClick={() => history.push('/overview')}>
			<Card title={title}>
				<Row gutter={[16, 16]}>
					<Col xxl={5} xl={5} lg={24} md={24} sm={24} xs={24}>
						<div style={{width: '90%', height: '120px', backgroundColor: '#012d5a', display: 'flex'}}>
							<h1 style={{fontSize: '18px', fontWeight: '500', width: '100%', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>{title}</h1>
						</div>
					</Col>
					<Col span={2}>&nbsp;</Col>
					<Col span={5}>
						<span className="subtitle">2020</span>
						<Statistic title='Sales' value={ytdSales} />
						<Statistic style={{ marginTop: '12px' }} title='Listings' value={ytdListings} />
					</Col>
					<Col span={12}>
						<span className="subtitle">August 2020</span>
						<Row gutter={[16, 16]}>
							<Col lg={8} md={8} sm={8} xs={8}>
								<Statistic title='Sales' value={currSales} />
								<Statistic style={{ marginTop: '12px' }} title='Listings' value={currListings} />
							</Col>
						</Row>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col xxl={24} lg={24} md={24} sm={24} xs={24}>
						<span className="subtitle">Sales agent activity in Last 30 days:</span>
						<DashboardAgentActivityTable/>
					</Col>
				</Row>
			</Card>
		</Col>
	);
};

export default DashboardProjectTile;
