import InSiteFrame from "../components/frame/InSiteFrame";
import React, {useState} from "react";
import {Breadcrumb, Button, Card, Col, DatePicker, Icon, Row, Statistic, Table, Tag, Tooltip} from "antd";
import InSiteFillingBarChart from "../components/charts/InSiteFillingBarChart";
import InSiteTrafficHistoryChart from "../components/charts/InSiteTrafficHistoryChart";
import moment from "moment";

const testDataGenerator = () => {
	const chance = new Chance();
	return {
		key: chance.integer({ min: 1 }),
		name: chance.name({nationality: 'en'}),
		lot: `Lot ${chance.integer({min: 101, max: 999})}`,
		registration: chance.pickone(["Online", "Onsite"]),
		enquiry_src: chance.pickset(["Signage", "Google", "Website - Estate", "Referral", "Builder Purchaser", "Builder Referral", "Channel Referral", "Letterbox Drop", "Newspaper", "Promotion Stand", "Radio", "TV", "Domain.com.au", "Web - Other", "Social Media", "Realestate.com.au", "Webchat"], 3)
	};
};

function generateTestData(amount) {
	let buffer = [];
	for(let i = 0; i < amount; i++) {
		buffer.push(testDataGenerator());
	}

	return buffer;
}

const TABLE_DATA = generateTestData(20);
const ENQ_SRC_CNT_DATA = TABLE_DATA.reduce((map, record) => {
	return record.enquiry_src.reduce((map, enqSrc) => {
		map[enqSrc] = (map[enqSrc]||0) + 1;
		return map;
	}, map);
}, {});
const ENQ_SRC_FILTERS = Object.keys(ENQ_SRC_CNT_DATA).map((item) => {
	return { text: `${item} (${ENQ_SRC_CNT_DATA[item]})`, value: item };
});

const MarketingReportPage = (props) => {
	const [filteringInfo, setFilteringInfo] = useState(null);
	const [checkedTags, setCheckedTags] = useState([]);

	const handleChange = (tag, checked) => {
		const nextSelectedTags = checked ? [...checkedTags, tag] : checkedTags.filter(t => t !== tag);
		console.log('You are interested in: ', nextSelectedTags);
		setCheckedTags(nextSelectedTags);

		if(filteringInfo !== null) {
			if(filteringInfo.hasOwnProperty('enquiry_src')) {
				if(Array.isArray(filteringInfo.enquiry_src)) {
					if(checked) {
						filteringInfo.enquiry_src.push(tag);
					} else {
						filteringInfo.enquiry_src = filteringInfo.enquiry_src.filter(t => t !== tag)
					}

					setFilteringInfo(filteringInfo);
				} else {
					filteringInfo.enquiry_src = [tag];
					setFilteringInfo(filteringInfo);
				}
			} else {
				filteringInfo.enquiry_src = [tag];
				setFilteringInfo(filteringInfo);
			}
		} else {
			setFilteringInfo({ enquiry_src: [tag] });
		}
	};

	const TABLE_COLUMNS = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			render: text => <a href="/profile">{text}</a>,
		},
		{
			title: 'Lot',
			dataIndex: 'lot',
			key: 'lot',
		},
		{
			title: 'Registration',
			dataIndex: 'registration',
			key: 'registration',
			filters: [{text: "Online", value: "Online"}, {text: "Onsite", value: "Onsite"}],
			filteredValue: filteringInfo !== null ? filteringInfo.registration : null,
			onFilter: (value, record) => record.registration === value,
		},
		{
			title: 'Enquiry Sources',
			dataIndex: 'enquiry_src',
			key: 'enquiry_src',
			filters: ENQ_SRC_FILTERS,
			filteredValue: filteringInfo !== null ? filteringInfo.enquiry_src : null,
			onFilter: (value, record) => record.enquiry_src.includes(value),
			render: enquiry_src => (
				<span>
				{enquiry_src.map(src => {
					if(filteringInfo !== null && filteringInfo.enquiry_src.includes(src)) {
						return (
							<Tag color="red" key={src}>{src.toUpperCase()}</Tag>
						);
					} else {
						return (
							<Tag color="geekblue" key={src}>{src.toUpperCase()}</Tag>
						);
					}
				})}
			</span>
			)
		}
	];

	return (
		<InSiteFrame>
			<Row gutter={[16, 16]} type={`flex`} style={{alignItems: 'flex-end'}}>
				<Col span={17}>
					<Breadcrumb style={{alignItems: 'flex-end'}}>
						<Breadcrumb.Item>Reporting</Breadcrumb.Item>
						<Breadcrumb.Item>Marketing Reports</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
				<Breadcrumb.Item>Marketing Reports</Breadcrumb.Item>
			</Row>
			<Row gutter={[16,16]} type="flex">
				<Col span={24}>
					<Card title="ROI Analytics">
						<Row gutter={[16,16]} type="flex">
							<Col span={6}>
								<Statistic style={{textAlign: 'center'}} title="Contracts" value={6} />
							</Col>
							<Col span={6}>
								<Statistic style={{textAlign: 'center'}} title="Deposits" value={3} />
							</Col>
							<Col span={6}>
								<Statistic style={{textAlign: 'center'}} title="Registrations" value={31} />
							</Col>
							<Col span={6}>
								<Statistic style={{textAlign: 'center'}} title="Conversion Rate" value={5.16} precision={2} />
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			<Row gutter={[16,16]} type="flex">
				<Col span={6}>
					<Card title="Website Traffic">
						<Row gutter={[16,16]} type="flex">
							<Col span={12}>
								<Statistic title="Visits" value={744} precision={0} />
							</Col>
							<Col span={12}>
								<Statistic title="Unique Visits" value={598} precision={0} />
							</Col>
						</Row>

						<Row gutter={[16,16]} type="flex">
							<Col span={12}>
								<Statistic title="Avg Daily Visits" value={106.29} precision={2} />
							</Col>
							<Col span={12}>
								<Statistic title="Avg Unique Visits" value={85.43} precision={2} />
							</Col>
						</Row>

						<Row gutter={[16,16]} type="flex">
							<Col span={12}>
								<Statistic title="Registrations" value={13} precision={0} />
							</Col>
							<Col span={12}>
								<Statistic title="Avg Daily Registrations" value={1.86} precision={2} />
							</Col>
						</Row>
					</Card>
				</Col>


				<Col span={18}>
					<Card title="Contract Source">
						<InSiteFillingBarChart/>
					</Card>
				</Col>
			</Row>

			<Row gutter={[16,16]} type="flex">
				<Col span={24}>
					<Card title="Traffic History">
						<InSiteTrafficHistoryChart/>
					</Card>
				</Col>
			</Row>

			<Row gutter={[16,16]} type="flex">
				<Col span={24}>
					<Card title="Purchaser Enquiry Sources">
						<Row gutter={[16,16]} type="flex" style={{ marginBottom: '8px' }}>
							<Col span={24}>
								{Object.keys(ENQ_SRC_CNT_DATA).map((enqSrc) =>
									<Tag.CheckableTag key={enqSrc} checked={checkedTags.indexOf(enqSrc) > -1} onChange={checked => handleChange(enqSrc, checked)}>{enqSrc} ({ENQ_SRC_CNT_DATA[enqSrc]})</Tag.CheckableTag>
								)}
							</Col>
						</Row>
						<Table className="insite" columns={TABLE_COLUMNS} dataSource={TABLE_DATA} onChange={(pagination, filters, sorter) => { console.log(filters); setFilteringInfo(filters); setCheckedTags(filters.enquiry_src); }} />
					</Card>
				</Col>
			</Row>
		</InSiteFrame>
	);
};

export default MarketingReportPage;
