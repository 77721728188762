import {Table} from "antd";
import React from "react";
import Chance from "chance";

const generateData = (amount=20) => {
	const chance = new Chance();
	return [...Array(amount)].map((_, idx) => {
		return {
			key: idx,
			name: chance.string({ alpha: true }),
			total: chance.integer({min: 15, max: 40}),
		};
	});
};

const PieChartTableView = (props) => {
	const COLUMNS = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Total',
			dataIndex: 'total',
			key: 'total',
			sorter: (a, b) => a.total - b.total,
		}
	];

	return (
		<Table className="insite" columns={COLUMNS} dataSource={generateData(5)} />
	);
};

export default PieChartTableView;
