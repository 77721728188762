import React, {useState} from "react";
import {Button, Icon, Input, notification, Select, Table} from "antd";
import Chance from "chance";
import Highlighter from "react-highlight-words";

const getNestedTableSearchProps = (dataIndex, searchedColumn, searchText, render=null, setSearchStateCallback=null, setResetStateCallback=null) => {
	return {
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={node => {
						window.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => setSearchStateCallback(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					type="primary"
					onClick={() => setSearchStateCallback(selectedKeys, confirm, dataIndex)}
					icon="search"
					size="small"
					style={{ width: 90, marginRight: 8 }}
				>
					Search
				</Button>
				<Button onClick={() => setResetStateCallback(clearFilters)} size="small" style={{ width: 90 }}>
					Reset
				</Button>
			</div>
		),
		filterIcon: filtered => (
			<Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
		),
		onFilter: (value, record) =>
			record[dataIndex]
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => window.searchInput.select());
			}
		},
		render: (text, record, idx) => {
			let color = '#000000';
			if(record.status === 'Sold') {
				color = 'red';
			} else if(record.status === 'Hold') {
				color = 'blue';
			} else if(record.status === 'Available') {
				color = '#a0d911';
			}
			return searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text.toString()}
				/>
			) : (
				<span style={{color: color}}>{text}</span>
			)
		}
	};
};

const NestedTable = (props) => {
	const DATA = props.data;
	const [isEditing, setIsEditing] = useState(false);
	const [editingRow, setEditingRow] = useState(null);

	const [searchText, setSearchText] = useState(null);
	const [searchedColumn, setSearchedColumn] = useState(null);
	const [filteredInfo, setFilteredInfo] = useState({});


	const LOT_COLUMNS = [
		{ width: '10%', align: 'center', title: 'Lot', dataIndex: 'name', key: 'name', render: (text, record, idx) => {
				let color = '#000000';
				if(record.status === 'Sold') {
					color = 'red';
				} else if(record.status === 'Hold') {
					color = 'blue';
				} else if(record.status === 'Available') {
					color = '#a0d911';
				}
				return (<span style={{color: color}}>{text}</span>);
			}, ...getNestedTableSearchProps(
				'name',
				searchedColumn,
				searchText,
				null,
				(selectedKeys, confirm, dataIndex) => {
					confirm();
					setSearchText(selectedKeys[0]);
					setSearchedColumn(dataIndex);
				},
				(clearFilters) => {
					clearFilters();
					setSearchText('');
				}) },
		{ width: '10%', editable: true, align: 'center', title: 'Size', dataIndex: 'area', key: 'area', render: (text, record, idx) => {
				if(isEditing && editingRow === idx) {
					return (
						<div>
							<Input value={record.area} suffix={<span>m<sup>2</sup></span>} />
						</div>
					);
				} else {
					return (<span>{text}m<sup>2</sup></span>);
				}
			},
			filters: [{ text: '> 100m2', value: 100 }, { text: '> 200m2', value: 200 }, { text: '> 300m2', value: 300 }, { text: '> 400m2', value: 400 }, { text: '> 500m2', value: 500 }, { text: '> 600m2', value: 600 }],
			filteredValue: filteredInfo.area || null,
			onFilter: (value, record) => record.area >= value
		},
		{ width: '10%', editable: true, align: 'center', title: 'Frontage', dataIndex: 'frontage', key: 'frontage', render: (text, record, idx) => {
				if(isEditing && editingRow === idx) {
					return (
						<div>
							<Input value={record.frontage} suffix={<span>m</span>} />
						</div>
					);
				} else {
					return (<span>{text}m</span>);
				}
			},
			filters: [{ text: '> 8m', value: 8 }, { text: '> 10m', value: 10 }, { text: '> 12m', value: 12 }, { text: '> 14m', value: 14 }, { text: '> 16m', value: 16 }, { text: '> 18m', value: 18 }],
			filteredValue: filteredInfo.frontage || null,
			onFilter: (value, record) => record.frontage >= value
		},
		{ width: '10%', editable: true, align: 'center', title: 'Depth', dataIndex: 'depth', key: 'depth', render: (text, record, idx) => {
				if(isEditing && editingRow === idx) {
					return (
						<div>
							<Input value={record.depth} suffix={<span>m</span>} />
						</div>
					);
				} else {
					return (<span>{text}m</span>);
				}
			},
			filters: [{ text: '> 16m', value: 16 }, { text: '> 18m', value: 18 }, { text: '> 20m', value: 20 }, { text: '> 24m', value: 24 }, { text: '> 28m', value: 28 }, { text: '> 32m', value: 32 }],
			filteredValue: filteredInfo.depth || null,
			onFilter: (value, record) => record.depth >= value
		},
		{ width: '10%', editable: true, align: 'center', title: 'Fall', dataIndex: 'fall', key: 'fall', render: (text, record, idx) => {
				if(isEditing && editingRow === idx) {
					return (
						<div>
							<Input value={record.fall} />
						</div>
					);
				} else {
					return (<span>{text}</span>);
				}
			} },
		{ width: '15%', editable: true, align: 'center', title: 'Type', dataIndex: 'type', key: 'type', render: (text, record, idx) => {
				if(isEditing && editingRow === idx) {
					return (
						<div style={{width: '100%'}}>
							<Select style={{width: '100%'}} value={record.type}>
								<Select.Option value="Regular">Regular</Select.Option>
								<Select.Option value="Corner">Corner</Select.Option>
								<Select.Option value="Smart">Smart</Select.Option>
							</Select>
						</div>
					);
				} else {
					return (<span>{text}</span>);
				}
			},
			filters: [{ text: 'Regular', value: 'Regular' }, { text: 'Corner', value: 'Corner' }, { text: 'Smart', value: 'Smart' }],
			filteredValue: filteredInfo.type || null,
			onFilter: (value, record) => record.type === value
		},
		{ editable: true, title: 'Note', width: '20%', key: 'note', render: (text, record, idx) => {
				const chance = new Chance();
				if(isEditing && editingRow === idx) {
					return (
						<div style={{width: '100%'}}>
							<Input.TextArea value={chance.sentence()} />
						</div>
					);
				} else {
					return (<span>{chance.sentence()}</span>);
				}
			} },
		{ width: '25%', align: 'center', title: 'Actions', dataIndex: '', key: 'action', render: (text, record, idx) => {
				if(isEditing && editingRow === idx) {
					return (
						<div>
							<a onClick={() => {
								setEditingRow(null);
								setIsEditing(false);
								notification['success']({
									message: 'Updated Lot Details',
									description: `${record.name} has been updated.`
								});
							}}>Save</a> | <a onClick={() => { setEditingRow(null); setIsEditing(false); }}>Cancel</a>
						</div>
					);
				} else {
					return (
						<div>
							<a onClick={() => {
								setEditingRow(idx);
								setIsEditing(true);
							}}>Edit</a>
						</div>
					);
				}
			} },
	];

	return (
		<React.Fragment>
			<Table className="stock-nested-table" columns={LOT_COLUMNS} dataSource={DATA} pagination={false} onChange={((pagination, filters, sorter) => setFilteredInfo(filters))} onHeaderRow={column => {
				return {
					className: 'nested-header-row'
				};
			}} />
		</React.Fragment>

	);
};

export default NestedTable;
