import Chance from 'chance';
import React, {useState} from "react";
import {
	AutoComplete,
	Button,
	Card,
	Col,
	Collapse, DatePicker,
	Descriptions,
	Form,
	Icon,
	Input,
	List,
	Modal, notification, PageHeader,
	Row,
	Switch
} from "antd";

function mockNameData() {
	const chance = new Chance();
	let buffer = [];
	for(let i = 0; i < 100; i++) {
		buffer.push(chance.name({ nationality: 'en' }));
	}

	return buffer;
}

function showSuccessNotification(title, content) {
	notification['success']({
		message: title,
		description: content
	});
}

const EditCustomerDetailsAltForm = ({ onNextClick }) => {
	const [contactIsCompany, setContactIsCompany] = useState(false);
	const [hasLender, setHasLender] = useState(false);
	const [showAddContactModal, setShowAddContactModal] = useState(false);
	const [showAddSolicitor, setShowAddSolicitor] = useState(false);
	const [selectedPerson, setSelectedPerson] = useState('');

	return (
		<Row gutter={[16, 16]} type={`flex`}>
			<Col span={24}>
				<Card>
					<PageHeader className="inv-management-header" style={{padding: 0}} title={`Lot 101`} extra={[
						<Button type="primary" onClick={() => onNextClick()}>Next</Button>
					]} />
				</Card>
			</Col>
			<Col span={12}>
				<Card title={"Purchaser Details"}>
					<Form>
						<Form.Item label={`InSite User`} help={"Details such as name, email and phone number will be imported from the InSite profile."}>
							<AutoComplete
								placeholder="Start typing a name to search in the InSite database."
								dataSource={mockNameData()}
								onSelect={(value) => { setSelectedPerson(value) }}
								filterOption={(inputValue, option) =>
									option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
								} />
						</Form.Item>

						<Form.Item label={`Purchasers Name`} help="Purchasers full name as to appear on contract.">
							<Input value={selectedPerson} onChange={event => setSelectedPerson(event.target.value)} />
						</Form.Item>

						<Form.Item label={`Additional Purchaser Information`}>
							<Collapse>
								<Collapse.Panel key={1} header={`Purchaser Details`} extra={<Button type="primary" shape="circle" icon="plus" size="small" onClick={() => setShowAddContactModal(true)} />}>
									<List size={"small"}>
										<List.Item>
											<Descriptions size="small" column={4} layout="vertical">
												<Descriptions.Item label={`First Name`}>Example</Descriptions.Item>
												<Descriptions.Item label={`Last Name`}>Person</Descriptions.Item>
												<Descriptions.Item label={`Email Address`}>example.person@insitelogic.com.au</Descriptions.Item>
												<Descriptions.Item label={`Phone Number`}>0400 000 000</Descriptions.Item>
											</Descriptions>
										</List.Item>
										<List.Item>
											<Descriptions size="small" column={4} layout="vertical">
												<Descriptions.Item label={`First Name`}>Other</Descriptions.Item>
												<Descriptions.Item label={`Last Name`}>Person</Descriptions.Item>
												<Descriptions.Item label={`Email Address`}>other.person@insitelogic.com.au</Descriptions.Item>
												<Descriptions.Item label={`Phone Number`}>0400 000 000</Descriptions.Item>
											</Descriptions>
										</List.Item>
										<List.Item>
											<Descriptions size="small" column={4} layout="vertical">
												<Descriptions.Item label={`Company Name`}>Example Company</Descriptions.Item>
												<Descriptions.Item label={`Email Address`}>example.person@insitelogic.com.au</Descriptions.Item>
												<Descriptions.Item label={`Phone Number`}>0400 000 000</Descriptions.Item>
											</Descriptions>
										</List.Item>
									</List>
								</Collapse.Panel>
							</Collapse>
						</Form.Item>

					</Form>
				</Card>
			</Col>
			<Col span={12}>
				<Card title={"Financing & Solicitor Details"}>
					<Form.Item label={`Finance Details`}>
						<Switch checkedChildren={<Icon type="check" />} unCheckedChildren={<Icon type="close" />} onChange={(checked) => setHasLender(checked)} />
						<Row gutter={[16, 16]} type={`flex`}>
							<Col span={8}>
								<Form.Item label="Lender Name">
									<Input disabled={!hasLender} />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label="Loan Amount">
									<Input prefix="$" disabled={!hasLender} />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label="Loan Approval Date">
									<DatePicker style={{width: '100%'}} disabled={!hasLender} />
								</Form.Item>
							</Col>
						</Row>
					</Form.Item>

					<Form.Item label={`Solicitor Information`}>
						<Collapse>
							<Collapse.Panel key={1} header={`Solicitor Details`} extra={<Button type="primary" shape="circle" icon="plus" size="small" onClick={() => setShowAddSolicitor(true)} />}>
								<List size={"small"}>
									<List.Item>
										<Descriptions size="small" column={4} layout="vertical">
											<Descriptions.Item label={`Company`}>Example Person</Descriptions.Item>
											<Descriptions.Item label={`Contact Name`}>Example Person</Descriptions.Item>
											<Descriptions.Item label={`Contact Email Address`}>example.person@insitelogic.com.au</Descriptions.Item>
											<Descriptions.Item label={`Contact Phone Number`}>0400 000 000</Descriptions.Item>
										</Descriptions>
									</List.Item>
								</List>
							</Collapse.Panel>
						</Collapse>
					</Form.Item>

					<Form.Item label="Note">
						<Input.TextArea rows={4} />
					</Form.Item>
				</Card>
			</Col>
			<Modal title="Add Customer Details" visible={showAddContactModal} onOk={() => { showSuccessNotification("Added Contact", "Contact was added to contract details."); setShowAddContactModal(false); }} onCancel={() => setShowAddContactModal(false)}>
				<Form>
					<Form.Item label={"Is Company?"}>
						<Switch checkedChildren={<Icon type="check" />} unCheckedChildren={<Icon type="close" />} onChange={(checked) => setContactIsCompany(checked)} />
					</Form.Item>

					<ContactDetailsNameEntry isCompany={contactIsCompany} />

					<Form.Item label={"Email Address"}>
						<Input />
					</Form.Item>

					<Form.Item label={"Phone Number"}>
						<Input />
					</Form.Item>
				</Form>
			</Modal>

			<Modal title="Add Solicitor Details" visible={showAddSolicitor} onOk={() => { showSuccessNotification("Added Solicitor", "Solicitor was added to contract details."); setShowAddSolicitor(false); }} onCancel={() => setShowAddSolicitor(false)}>
				<Form>
					<Form.Item label={"Solicitor Company Name"}>
						<Input />
					</Form.Item>

					<Form.Item label={"Solicitor Contact Name"}>
						<Input />
					</Form.Item>

					<Form.Item label={"Solicitor Contact Email Address"}>
						<Input />
					</Form.Item>

					<Form.Item label={"Solicitor Contact Phone Number"}>
						<Input />
					</Form.Item>
				</Form>
			</Modal>
		</Row>
	);
};

const ContactDetailsNameEntry = ({isCompany}) => {
	if(isCompany) {
		return (
			<React.Fragment>
				<Form.Item label={"Company Name"}>
					<Input />
				</Form.Item>
			</React.Fragment>
		);
	} else {
		return (
			<React.Fragment>
				<Form.Item label={"First Name"}>
					<Input />
				</Form.Item>

				<Form.Item label={"Last Name"}>
					<Input />
				</Form.Item>
			</React.Fragment>
		);
	}
}

export default EditCustomerDetailsAltForm;
