import React from "react";
import {Layout, Row, Col, Select, Typography} from "antd";

const { Header, Content } = Layout;

const InSiteDashboardFrame = (props) => {
	console.log(props);

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Header style={{height: '84px', lineHeight: '84px'}}>
				<Row>
					<Col span={1}>
						&nbsp;
					</Col>
					<Col span={1}>
						<div className="logo" style={{height: '160%'}}>
							<img style={{width: '160%'}} src="https://storage.googleapis.com/proj.insitelogic.com.au/etc/biggin-scott.png" alt="" />
						</div>
					</Col>
					<Col span={6} push={8}>
						<Typography.Title level={2} style={{ color: 'white', lineHeight: '84px' }}>Biggin & Scott - Corporate</Typography.Title>
					</Col>
				</Row>
			</Header>
			<Layout>
				<Content style={{padding: '12px'}}>
					{props.children}
				</Content>
			</Layout>
		</Layout>
	);
};

export default InSiteDashboardFrame;
