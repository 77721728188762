import InSiteFrame from "../components/frame/InSiteFrame";
import React, {useState} from "react";
import {
	Breadcrumb,
	Button,
	Card,
	Col,
	Input,
	List,
	notification,
	Row,
	Select,
	Table,
	Typography
} from "antd";
import Chance from 'chance';
import {useHistory} from "react-router";
import stageData from './../data/inventoryManagement/StageData';
import actionHistory from './../data/inventoryManagement/ActionHistory';
import stageColumnDefs from './../components/table/inventoryManagement/columnDefs/StageColumnDefs';
import ReportButtonTab from "../components/tabPage/inventoryManagement/ReportButtonTab";
import NestedTable from "../components/table/inventoryManagement/NestedTable";
import NestedStatusTable from "../components/table/inventoryManagement/NestedStatusTable";
import GenericStatusButtonTabPage from "../components/tabPage/inventoryManagement/GenericStatusButtonTabPage";
import SalesDrawerNew from "../components/drawer/SalesDrawerNew";
import PurchaserDetailsTab from "../components/tabPage/inventoryManagement/PurchaserDetailsTab";
import {DatePickerStripShitVersion} from "../components/datepicker/Datepicker";
import SoldLotColumnDefs from "../components/table/inventoryManagement/columnDefs/SoldLotColumnDefs";
import DepositLotColumnDefs from "../components/table/inventoryManagement/columnDefs/DepositLotColumnDefs";
import ConditionalLotColumnDefs from "../components/table/inventoryManagement/columnDefs/ConditionalLotColumnDefs";
import UnconditionalLotColumnDefs from "../components/table/inventoryManagement/columnDefs/UnconditionalLotColumnDefs";

const stageDataGenerator = () => {
	return stageData;
};

const actionHistoryGenerator = () => {
	return actionHistory;
};

const StockManagementAltPage = () => {
	const history = useHistory();
	const DATA = stageDataGenerator();
	const [selectedTab, setSelectedTab] = useState('inventory');
	const STAGE_COLUMNS = stageColumnDefs;

	const [isEditing, setIsEditing] = useState(false);
	const [editingRow, setEditingRow] = useState(null);
	const LOT_COLUMNS = [
		{ width: '10%', align: 'center', title: 'Lot', dataIndex: 'name', key: 'name', render: (text, record, idx) => {
			let color = '#000000';
			if(record.status === 'Sold' || record.status === 'Unconditional') {
				color = '#ff4d4f';
			} else if(record.status === 'Deposit' || record.status === 'Conditional') {
				color = '#1890ff';
			} else if(record.status === 'Settled') {
				color = '#a0d911';
			}
			return (<span style={{color: color}}>{text}</span>);
		} },
		{ width: '10%', editable: true, align: 'center', title: 'Size', dataIndex: 'area', key: 'area', render: (text, record, idx) => {
			if(isEditing && editingRow === idx) {
				return (
					<div>
						<Input value={record.area} suffix={<span>m<sup>2</sup></span>} />
					</div>
				);
			} else {
				return (<span>{text}m<sup>2</sup></span>);
			}
		} },
		{ width: '10%', editable: true, align: 'center', title: 'Frontage', dataIndex: 'frontage', key: 'frontage', render: (text, record, idx) => {
			if(isEditing && editingRow === idx) {
				return (
					<div>
						<Input value={record.frontage} suffix={<span>m</span>} />
					</div>
				);
			} else {
				return (<span>{text}m</span>);
			}
		} },
		{ width: '10%', editable: true, align: 'center', title: 'Depth', dataIndex: 'depth', key: 'depth', render: (text, record, idx) => {
			if(isEditing && editingRow === idx) {
				return (
					<div>
						<Input value={record.depth} suffix={<span>m</span>} />
					</div>
				);
			} else {
				return (<span>{text}m</span>);
			}
		} },
		{ width: '10%', editable: true, align: 'center', title: 'Fall', dataIndex: 'fall', key: 'fall', render: (text, record, idx) => {
			if(isEditing && editingRow === idx) {
				return (
					<div>
						<Input value={record.fall} />
					</div>
				);
			} else {
				return (<span>{text}</span>);
			}
		} },
		{ width: '15%', editable: true, align: 'center', title: 'Type', dataIndex: 'type', key: 'type', render: (text, record, idx) => {
			if(isEditing && editingRow === idx) {
				return (
					<div style={{width: '100%'}}>
						<Select style={{width: '100%'}} value={record.type}>
							<Select.Option value="Regular">Regular</Select.Option>
							<Select.Option value="Corner">Corner</Select.Option>
							<Select.Option value="Smart">Smart</Select.Option>
						</Select>
					</div>
				);
			} else {
				return (<span>{text}</span>);
			}
		} },
		{ editable: true, title: 'Note', width: '20%', key: 'note', render: (text, record, idx) => {
			const chance = new Chance();
			if(isEditing && editingRow === idx) {
				return (
					<div style={{width: '100%'}}>
						<Input.TextArea value={chance.sentence()} />
					</div>
				);
			} else {
				return (<span>{chance.sentence()}</span>);
			}
		} },
		{ width: '25%', align: 'center', title: 'Actions', dataIndex: '', key: 'action', render: (text, record, idx) => {
			if(isEditing && editingRow === idx) {
				return (
					<div>
						<a onClick={() => {
							setEditingRow(null);
							setIsEditing(false);
							notification['success']({
								message: 'Updated Lot Details',
								description: `${record.name} has been updated.`
							});
						}}>Save</a> | <a onClick={() => { setEditingRow(null); setIsEditing(false); }}>Cancel</a>
					</div>
				);
			} else {
				return (
					<div>
						<a onClick={() => {
							setEditingRow(idx);
							setIsEditing(true);
						}}>Edit</a>
					</div>
				);
			}
		} },
	];

	const [selectedEmbeddedTab, setSelectedEmbeddedTab] = useState('status');
	const EMBEDDED_TABS = {
		status: (
			<>
				<Table className="insite_stock_table" columns={STAGE_COLUMNS} dataSource={DATA} expandedRowRender={NestedTableStatusWrapper} expandRowByClick={true} />
			</>
		),
		lot: (
			<>
				<Table className="insite_stock_table" columns={STAGE_COLUMNS} dataSource={DATA} expandedRowRender={NestedTableWrapper} expandRowByClick={true} />
			</>
		),
		purchase: (
			<PurchaserDetailsTab data={DATA} />
		)
	};

	const [isSalesDrawerVisible, setSalesDrawerVisible] = useState(false);
	const TABS = {
		inventory: (
			<>
				<Col span={16}>
					<Card title="Inventory" tabList={[ {key: 'status', tab: 'Status Details'}, {key: 'lot', tab: 'Site Details'}, {key: 'purchase', tab: 'Purchaser Details'} ]} activeTabKey={selectedEmbeddedTab} onTabChange={(key) => setSelectedEmbeddedTab(key)}>
						{EMBEDDED_TABS[selectedEmbeddedTab]}
						<SalesDrawerNew visible={isSalesDrawerVisible} onClose={() => setSalesDrawerVisible(false)} onSubmitSuccess={() => setSalesDrawerVisible(false)} />
					</Card>
				</Col>
				<Col span={8}>
					<Card className="action_history_list" title="Action History">
						<List bordered={true} itemLayout="horizontal" split={true}>
							{actionHistoryGenerator(10).map((item, idx) => {
								return <List.Item key={idx} actions={[<a key="list-loadmore-edit">view</a>, <a key="list-loadmore-more">clear</a>]}>
									<List.Item.Meta title={<div><Typography.Text strong={true}>{item.src}</Typography.Text>:&nbsp;<Typography.Text>{item.action}</Typography.Text></div>} />
									{item.actioner}
								</List.Item>
							})}
						</List>
					</Card>
				</Col>
			</>
		),
		sold: (
			<GenericStatusButtonTabPage cardTitle="Sold" lotColumns={SoldLotColumnDefs(history)} tableData={DATA.map((stage) => stage.lots).flat().filter((lot) => lot.status === "Sold")} actionHistory={actionHistory} />
		),
		holds: (
			<GenericStatusButtonTabPage cardTitle="Holds" lotColumns={ConditionalLotColumnDefs(history)} tableData={DATA.map((stage) => stage.lots).flat().filter((lot) => lot.status === "Hold")} actionHistory={actionHistory} />
		),
		unconditionals: (
			<GenericStatusButtonTabPage cardTitle="Unconditionals" lotColumns={UnconditionalLotColumnDefs(history)} tableData={DATA.map((stage) => stage.lots).flat().filter((lot) => lot.status === "Unconditional")} actionHistory={actionHistory} />
		),
		deposits: (
			<GenericStatusButtonTabPage cardTitle="Deposits" lotColumns={DepositLotColumnDefs(history)} tableData={DATA.map((stage) => stage.lots).flat().filter((lot) => lot.status === "Deposit")} actionHistory={actionHistory} />
		),
		conditionals: (
			<GenericStatusButtonTabPage cardTitle="Conditionals" lotColumns={ConditionalLotColumnDefs(history)} tableData={DATA.map((stage) => stage.lots).flat().filter((lot) => lot.status === "Conditional")} actionHistory={actionHistory} />
		),
		settled: (
			<GenericStatusButtonTabPage cardTitle="Settled" lotColumns={SoldLotColumnDefs(history)} tableData={DATA.map((stage) => stage.lots).flat().filter((lot) => lot.status === "Settled")} actionHistory={actionHistory} />
		),
		reports: (
			<ReportButtonTab data={DATA} />
		),
	};

	return (
		<InSiteFrame>
			<Row gutter={[16, 16]} type={`flex`} style={{alignItems: 'flex-end'}}>
				<Col span={15}>
					<Breadcrumb style={{alignItems: 'flex-end'}}>
						<Breadcrumb.Item>Inventory Management</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
				<DatePickerStripShitVersion />
				<Col span={2}>
					<Button type="primary" icon="printer" block>Print Report</Button>
				</Col>
			</Row>
			<Row gutter={[16, 16]} type={`flex`}>
				<Col span={24}>
					<Card>
						<Row gutter={[16, 16]} type={`flex`}>
							<Col span={3}>
								<Button style={{fontSize: '18px', height: '40px'}} type="primary" size="large" block={true} onClick={() => setSelectedTab('inventory')}>Inventory</Button>
							</Col>
							<Col span={3}>
								<Button style={{fontSize: '18px', height: '40px'}} type="primary" size="large" block={true} onClick={() => setSelectedTab('holds')}>Holds</Button>
							</Col>
							<Col span={3}>
								<Button style={{fontSize: '18px', height: '40px'}} type="primary" size="large" block={true} onClick={() => setSelectedTab('sold')}>Sold</Button>
							</Col>
							<Col span={3}>
								<Button style={{fontSize: '18px', height: '40px'}} type="primary" size="large" block={true} onClick={() => setSelectedTab('deposits')}>Deposit</Button>
							</Col>
							<Col span={3}>
								<Button style={{fontSize: '18px', height: '40px'}} type="primary" size="large" block={true} onClick={() => setSelectedTab('conditionals')}>Conditional</Button>
							</Col>
							<Col span={3}>
								<Button style={{fontSize: '18px', height: '40px'}} type="primary" size="large" block={true} onClick={() => setSelectedTab('unconditionals')}>Unconditional</Button>
							</Col>
							<Col span={3}>
								<Button style={{fontSize: '18px', height: '40px'}} type="primary" size="large" block={true} onClick={() => setSelectedTab('settled')}>Settled</Button>
							</Col>
							<Col span={3}>
								<Button style={{fontSize: '18px', height: '40px'}} type="primary" size="large" block={true} onClick={() => setSelectedTab('reports')}>Reports</Button>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			<Row gutter={[16, 16]} type={`flex`}>
				{TABS[selectedTab]}
			</Row>
		</InSiteFrame>
	);
};

const NestedTableWrapper = (record, index, indent, expanded) => {
	console.log(record);
	console.log(index);
	console.log(expanded);
	return (
		<NestedTable data={record.lots} />
	);
};

const NestedTableStatusWrapper = (record, index, indent, expanded) => {
	console.log(record);
	console.log(index);
	console.log(expanded);
	return (
		<NestedStatusTable data={record.lots} />
	);
};



export default StockManagementAltPage;
