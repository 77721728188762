import React, {useEffect, useState} from "react";
import { Table } from 'antd';
import Chance from 'chance';
import formatMoney from "../../util/formatMoney";

let BUILDERS = new Set();
let PACKAGES = new Set();
let STAGES = new Set();

const generateData = (amount=20) => {
	const chance = new Chance();
	let data = [...Array(amount)].map((_, idx) => {
		let tmp = {
			key: idx,
			package: chance.string({ alpha: true }),
			builder: chance.string({ alpha: true }),
			price: chance.integer({min: 250000, max: 700000}),
			lot: `Lot ${chance.integer({min: 101, max: 1899})}`,
			stage: `Stage ${chance.integer({min: 1, max: 18})}`,
			status: chance.pickone(['Available', 'Sold']),
			actions: chance.integer({min: 10, max: 65}),
		};

		BUILDERS.add(tmp.builder);
		PACKAGES.add(tmp.package);
		STAGES.add(tmp.stage);

		return tmp;
	});

	console.log(data);
	console.log(BUILDERS);
	console.log(PACKAGES);
	console.log(STAGES);

	return data;
};

const DATA = generateData();

const InSitePackageDataTable = ({setCount}) => {
	const [filteringInfo, setFilteringInfo] = useState(null);
	useEffect(() => { setCount(DATA.length); });

	const PACKAGES_FILTER = [...PACKAGES].map((val) => { return {text: val, value: val} });
	const BUILDERS_FILTER = [...BUILDERS].map((val) => { return { text: val, value: val } });
	const STAGES_FILTER = [...STAGES].map((val) => { return { text: val, value: val } });
	const COLUMNS = [
		{
			title: 'Package',
			dataIndex: 'package',
			key: 'package',
			filters: PACKAGES_FILTER,
			filteredValue: filteringInfo !== null ? filteringInfo.package : null,
			onFilter: (value, record) => record.package === value
		},
		{
			title: 'Builder',
			dataIndex: 'builder',
			key: 'builder',
			filters: BUILDERS_FILTER,
			filteredValue: filteringInfo !== null ? filteringInfo.builder : null,
			onFilter: (value, record) => record.builder === value
		},
		{ title: 'Price', dataIndex: 'price', key: 'price', render: (text, record, idx) => {
				return (<span>${formatMoney(text)}</span>);
			} },
		{ title: 'Lot', dataIndex: 'lot', key: 'lot' },
		{
			title: 'Stage',
			dataIndex: 'stage',
			key: 'stage',
			filters: STAGES_FILTER,
			filteredValue: filteringInfo !== null ? filteringInfo.stage : null,
			onFilter: (value, record) => record.stage === value
		},
		{
			title: 'Lot Status',
			dataIndex: 'status',
			key: 'status',
			filters: [{text: "Available", value: "Available"}, {text: "Sold", value: "Sold"}],
			filteredValue: filteringInfo !== null ? filteringInfo.status : null,
			onFilter: (value, record) => record.status === value
		},
		{ title: 'Actions', dataIndex: 'actions', key: 'actions', sorter: (a, b) => a.actions - b.actions, }
	];
	console.log(PACKAGES_FILTER);
	console.log(BUILDERS_FILTER);
	console.log(STAGES_FILTER);

	return (
		<Table className="insite" columns={COLUMNS} dataSource={DATA} onChange={(pagination, filters, sorter) => { console.log(filters); setFilteringInfo(filters); }} />
	);
};

export default InSitePackageDataTable;
