import InSiteFrame from "../components/frame/InSiteFrame";
import React, {useState} from "react";
import {Breadcrumb, Button, Card, Col, Form, Input, InputNumber, Result, Row, Select, Steps} from "antd";
import {useHistory} from "react-router";

const RegisterPage = (props) => {
	const [currentFormPage, setCurrentFormPage] = useState(0);

	return (
		<InSiteFrame>
			<Row gutter={[16, 16]} type={`flex`} style={{alignItems: 'flex-end'}}>
				<Col span={24}>
					<Breadcrumb style={{alignItems: 'flex-end'}}>
						<Breadcrumb.Item>Register User</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			<Row gutter={[16,16]} type="flex">
				<Col span={24}>
					<Card>
						<Steps current={currentFormPage}>
							<Steps.Step title="Core Details" />
							<Steps.Step title="Profile Details" />
							<Steps.Step title="Done" status="finish" />
						</Steps>
					</Card>
				</Col>
			</Row>
			<Row gutter={[16,16]} type="flex">
				<Col span={24}>
					<Card>
						<FormPage currentPage={currentFormPage} setCurrentFormPage={setCurrentFormPage} />
					</Card>
				</Col>
			</Row>
		</InSiteFrame>
	);
};

const FormPage = ({currentPage, setCurrentFormPage}) => {
	switch (currentPage) {
		case 0:
			return (<FormPageOne currentPage={currentPage} setCurrentFormPage={setCurrentFormPage} />);
		case 1:
			return (<FormPageTwo currentPage={currentPage} setCurrentFormPage={setCurrentFormPage} />);
		case 2:
			return (<FormFinish currentPage={currentPage} setCurrentFormPage={setCurrentFormPage} />);
		default:
			return (<FormPageOne currentPage={currentPage} setCurrentFormPage={setCurrentFormPage} />);
	}
};

const FormPageOne = ({currentPage, setCurrentFormPage}) => {
	const onNextClick = () => { setCurrentFormPage(currentPage + 1); };
	return (
		<Form>
			<Form.Item label="First Name">
				<Input placeholder="Please enter first name" />
			</Form.Item>
			<Form.Item label="Last Name">
				<Input placeholder="Please enter last name" />
			</Form.Item>
			<Form.Item label="Email Address">
				<Input placeholder="Please enter email" />
			</Form.Item>
			<Form.Item label="Phone Number">
				<Input placeholder="Please enter phone" />
			</Form.Item>
			<Form.Item>
				<Button onClick={onNextClick} type="primary" htmlType="submit">Next</Button>
			</Form.Item>
		</Form>
	);
};

const FormPageTwo = ({currentPage, setCurrentFormPage}) => {
	const onNextClick = () => { setCurrentFormPage(currentPage + 1); };
	return (
		<Form>
			<Form.Item label="Status">
				<Select placeholder="Select a status">
					<Select.Option value={0}>Unactioned</Select.Option>
					<Select.Option value={1}>Cold</Select.Option>
					<Select.Option value={2}>Warm</Select.Option>
					<Select.Option value={3}>Hot</Select.Option>
					<Select.Option value={4}>Industry</Select.Option>
				</Select>
			</Form.Item>
			<Form.Item label="Postcode">
				<Input placeholder="Enter your postcode" pattern="[0-9]{4}" />
			</Form.Item>
			<Form.Item label="Buyer Type">
				<Select placeholder="What kind of buyer are you?">
					<Select.Option value={null}>N/A</Select.Option>
					<Select.Option value="First Home">First Home</Select.Option>
					<Select.Option value="Up-sizer">Up-sizer</Select.Option>
					<Select.Option value="Down-sizer">Down-sizer</Select.Option>
					<Select.Option value="Investor">Investor</Select.Option>
					<Select.Option value="Builder">Builder</Select.Option>
					<Select.Option value={"Not Sure"}>Not Sure</Select.Option>
				</Select>
			</Form.Item>
			<Form.Item label="Referral Source">
				<Select placeholder="Where did you hear about us?">
					<Select.Option value="Project Website">Project Website</Select.Option>
					<Select.Option value="Social Media">Social Media</Select.Option>
					<Select.Option value="realestate.com.au">realestate.com.au</Select.Option>
					<Select.Option value="Signage">Signage</Select.Option>
					<Select.Option value="Google Search">Google Search</Select.Option>
					<Select.Option value="Google Adwords">Google Adwords</Select.Option>
					<Select.Option value="Builder Referral">Builder Referral</Select.Option>
					<Select.Option value="Newspaper">Newspaper</Select.Option>
					<Select.Option value="Family & Friends Referral">Family & Friends Referral</Select.Option>
					<Select.Option value="Radio/TV">Radio/TV</Select.Option>
					<Select.Option value="Repeat Purchaser">Repeat Purchaser</Select.Option>
					<Select.Option value="Walk-in">Walk-in</Select.Option>
					<Select.Option value={"Not Sure"}>Not Sure</Select.Option>
				</Select>
			</Form.Item>
			<Form.Item label="Land Budget">
				<Select placeholder="Select the land budget">
					<Select.Option value={"$150,000 - $200,000"}>$150,000 - $200,000</Select.Option>
					<Select.Option value={"$200,000 - $250,000"}>$200,000 - $250,000</Select.Option>
					<Select.Option value={"$250,000 - $300,000"}>$250,000 - $300,000</Select.Option>
					<Select.Option value={"$300,000 - $350,000"}>$300,000 - $350,000</Select.Option>
					<Select.Option value={"$350,000 - $400,000"}>$350,000 - $400,000</Select.Option>
					<Select.Option value={"$400,000 - $450,000"}>$400,000 - $450,000</Select.Option>
					<Select.Option value={"$450,000 - $500,000"}>$450,000 - $500,000</Select.Option>
					<Select.Option value={"$500,000 - $550,000"}>$500,000 - $550,000</Select.Option>
					<Select.Option value={"$550,000 - $600,000"}>$550,000 - $600,000</Select.Option>
					<Select.Option value={"$600,000 - $650,000"}>$600,000 - $650,000</Select.Option>
					<Select.Option value={"$650,000 - $750,000"}>$650,000 - $700,000</Select.Option>
					<Select.Option value={"$700,000+"}>$700,000+</Select.Option>
					<Select.Option value={"Not Sure"}>Not Sure</Select.Option>
				</Select>
			</Form.Item>
			<Form.Item label="Land Size">
				<Select placeholder="Select the minimum desired land size">
					<Select.Option value={0}>
						&lt; 300m<sup>2</sup>
					</Select.Option>
					<Select.Option value={1}>
						300m<sup>2</sup> - 350m<sup>2</sup>
					</Select.Option>
					<Select.Option value={2}>
						350m<sup>2</sup> - 400m<sup>2</sup>
					</Select.Option>
					<Select.Option value={3}>
						400m<sup>2</sup> - 450m<sup>2</sup>
					</Select.Option>
					<Select.Option value={4}>
						450m<sup>2</sup> - 500m<sup>2</sup>
					</Select.Option>
					<Select.Option value={5}>
						500m<sup>2</sup> - 550m<sup>2</sup>
					</Select.Option>
					<Select.Option value={5}>
						550m<sup>2</sup> - 600m<sup>2</sup>
					</Select.Option>
					<Select.Option value={6}>600m2+</Select.Option>
					<Select.Option value={"Not Sure"}>Not Sure</Select.Option>
				</Select>
			</Form.Item>

			<Form.Item label="House & Land Budget">
				<Select placeholder="Select the total house & land budget">
					<Select.Option value={"$300,000 - $350,000"}>$300,000 - $350,000</Select.Option>
					<Select.Option value={"$350,000 - $400,000"}>$350,000 - $400,000</Select.Option>
					<Select.Option value={"$400,000 - $450,000"}>$400,000 - $450,000</Select.Option>
					<Select.Option value={"$450,000 - $500,000"}>$450,000 - $500,000</Select.Option>
					<Select.Option value={"$500,000 - $550,000"}>$500,000 - $550,000</Select.Option>
					<Select.Option value={"$550,000 - $600,000"}>$550,000 - $600,000</Select.Option>
					<Select.Option value={"$600,000 - $650,000"}>$600,000 - $650,000</Select.Option>
					<Select.Option value={"$650,000 - $750,000"}>$650,000 - $700,000</Select.Option>
					<Select.Option value={"$700,000+"}>$700,000+</Select.Option>
					<Select.Option value={"Not Sure"}>Not Sure</Select.Option>
				</Select>
			</Form.Item>

			<Form.Item label="Note">
				<Input.TextArea rows={4} placeholder="Enter a note about this lead" />
			</Form.Item>
			<Form.Item>
				<Button onClick={onNextClick} type="primary" htmlType="submit">Next</Button>
			</Form.Item>
		</Form>
	);
};


const FormFinish = ({currentPage, setCurrentFormPage}) => {
	const history = useHistory();
	return (
		<Result
			status="success"
			title="Successfully registered new user"
			subTitle="User example@insitelogic.com.au has been entered into InSite"
			extra={[
				<Button type="primary" key="console" onClick={() => history.push('/profile')}>
					View Profile
				</Button>,
				<Button key="buy" onClick={() => setCurrentFormPage(0)}>Register Another Lead</Button>,
			]}
		/>
	);
};

export default RegisterPage;
