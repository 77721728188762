import InSiteFrame from "../components/frame/InSiteFrame";
import React, {useState} from "react";
import {
	Breadcrumb,
	Button,
	Card,
	Col,
	Descriptions, Upload, Icon,
	List,
	Modal,
	notification,
	PageHeader,
	Result,
	Row,
	Skeleton, message
} from "antd";
import {useHistory} from "react-router";

const StockManagementLotPage = (props) => {
	const history = useHistory();
	const [currentTabKey, setCurrentTabKey] = useState('details');
	const [isLoading, setIsLoading] = useState(true);
	const [showUploadModal, setShowUploadModal] = useState(false);
	setTimeout(() => {
		setIsLoading(false);
	}, 450);
	const tabList = [
		{ key: 'details', tab: 'Lot Details' },
		{ key: 'deposit', tab: 'Deposit Information' },
		{ key: 'purchase', tab: 'Purchase Information' },
		{ key: 'files', tab: 'Attached Files' },
	];

	const tabExtra = {
		details: [<Button type="primary" icon="edit" onClick={() => message.warning("This button is not yet implemented.")}>Edit Details</Button>],
		deposit: [<Button type="primary" icon="dollar" onClick={() => history.push('/stock-management/lot-101/edit')}>Edit Deposit Details</Button>],
		purchase: [<Button type="primary" icon="credit-card" onClick={() => history.push('/stock-management/lot-101/edit')}>Edit Purchase Details</Button>],
		files: [<Button type="primary" icon="upload" onClick={() => setShowUploadModal(true)}>Upload File</Button>],
	};
	const contentList = {
		details: (
			<Skeleton active={true} loading={isLoading}>
				<Descriptions layout="vertical" colon={true} bordered={false} size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
					<Descriptions.Item label="Lot Name">Lot 101</Descriptions.Item>
					<Descriptions.Item label="Stage">Stage 1</Descriptions.Item>
					<Descriptions.Item label="Area">350m<sup>2</sup></Descriptions.Item>
					<Descriptions.Item label="Frontage">12.5m</Descriptions.Item>
					<Descriptions.Item label="Depth">30m</Descriptions.Item>
					<Descriptions.Item label="Price">$210,000</Descriptions.Item>
					<Descriptions.Item label="Commission Percentage">10%</Descriptions.Item>
					<Descriptions.Item label="Commission Value">$2,100</Descriptions.Item>
				</Descriptions>
			</Skeleton>
		),
		deposit: (
			<Skeleton active={true} loading={isLoading}>
				<Descriptions layout="vertical" colon={true} bordered={false} size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
					<Descriptions.Item label="Depositor">Example Person (<a href="/profile">View</a>)</Descriptions.Item>
					<Descriptions.Item label="Agent">Test Agent</Descriptions.Item>
					<Descriptions.Item label="Status">Conditional</Descriptions.Item>
					<Descriptions.Item label="Initial Deposit">$2,000</Descriptions.Item>
					<Descriptions.Item label="Balance Deposit">$10,000</Descriptions.Item>
					<Descriptions.Item label="Deposit Date">27th of May, 2020</Descriptions.Item>
					<Descriptions.Item label="Balance Due">15th of June, 2020</Descriptions.Item>
				</Descriptions>
			</Skeleton>
		),
		purchase: (
			<Skeleton active={true} loading={isLoading}>
				<Descriptions layout="vertical" colon={true} bordered={false} size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
					<Descriptions.Item label="Purchaser">Example Person (<a href="/profile">View</a>)</Descriptions.Item>
					<Descriptions.Item label="Agent">Test Agent</Descriptions.Item>
					<Descriptions.Item label="Deposit">$12,000</Descriptions.Item>
					<Descriptions.Item label="Deposit Date">27th of May, 2020</Descriptions.Item>
					<Descriptions.Item label="Sale Price">$210,000</Descriptions.Item>
					<Descriptions.Item label="Conditional on Finance">No</Descriptions.Item>
					<Descriptions.Item label="Lender">N/A</Descriptions.Item>
					<Descriptions.Item label="Solicitor">
						<Card type="inner">
							<Descriptions layout="vertical" colon={true} bordered={false} size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
								<Descriptions.Item label="Trading Name">Fake Solicitors Pty Ltd.</Descriptions.Item>
								<Descriptions.Item label="Contact Name">Example Solicitor</Descriptions.Item>
								<Descriptions.Item label="Address">3/35 Mackey Street, North Geelong, Victoria 3215</Descriptions.Item>
								<Descriptions.Item label="Contact Email">example.solicitor@insitelogic.com.au</Descriptions.Item>
							</Descriptions>
						</Card>
					</Descriptions.Item>
				</Descriptions>
			</Skeleton>
		),
		files: (
			<Skeleton active={true} loading={isLoading}>
				<List bordered={true} header="Files">
					<List.Item actions={[
						<Button type="danger" icon="delete" onClick={() => {
							Modal.confirm({
								title: 'Delete File?',
								content: 'This action will irretrievably delete this file, are you sure you want to do this?',
								onOk() {
									notification['info']({
										message: 'File Deleted',
										description: 'This file has been deleted.'
									});
								}
							});
						}}>Delete File</Button>,
						<Button type="primary" icon="download">Download File</Button>,
					]}>
						Example-File.pdf
					</List.Item>
					<List.Item actions={[
						<Button type="danger" icon="delete" onClick={() => {
							Modal.confirm({
								title: 'Delete File?',
								content: 'This action will irretrievably delete this file, are you sure you want to do this?',
								onOk() {
									notification['info']({
										message: 'File Deleted',
										description: 'This file has been deleted.'
									});
								}
							});
						}}>Delete File</Button>,
						<Button type="primary" icon="download">Download File</Button>,
					]}>
						Example-File.pdf
					</List.Item>
					<List.Item actions={[
						<Button type="danger" icon="delete" onClick={() => {
							Modal.confirm({
								title: 'Delete File?',
								content: 'This action will irretrievably delete this file, are you sure you want to do this?',
								onOk() {
									notification['info']({
										message: 'File Deleted',
										description: 'This file has been deleted.'
									});
								}
							});
						}}>Delete File</Button>,
						<Button type="primary" icon="download">Download File</Button>,
					]}>
						Example-File.pdf
					</List.Item>
					<List.Item actions={[
						<Button type="danger" icon="delete" onClick={() => {
							Modal.confirm({
								title: 'Delete File?',
								content: 'This action will irretrievably delete this file, are you sure you want to do this?',
								onOk() {
									notification['info']({
										message: 'File Deleted',
										description: 'This file has been deleted.'
									});
								}
							});
						}}>Delete File</Button>,
						<Button type="primary" icon="download">Download File</Button>,
					]}>
						Example-File.pdf
					</List.Item>
					<List.Item actions={[
						<Button type="danger" icon="delete" onClick={() => {
							Modal.confirm({
								title: 'Delete File?',
								content: 'This action will irretrievably delete this file, are you sure you want to do this?',
								onOk() {
									notification['info']({
										message: 'File Deleted',
										description: 'This file has been deleted.'
									});
								}
							});
						}}>Delete File</Button>,
						<Button type="primary" icon="download">Download File</Button>,
					]}>
						Example-File.pdf
					</List.Item>
				</List>
				<Modal title="Upload File" visible={showUploadModal} onOk={() => {notification['success']({message: 'File Uploaded', description: 'File was successfully uploaded.'}); setShowUploadModal(false);}} onCancel={() => {setShowUploadModal(false)}}>
					<Upload.Dragger name="file" multiple={true}>
						<p className="ant-upload-drag-icon">
							<Icon type="upload" />
						</p>
						<p className="ant-upload-text">Click or drag file to this area to upload</p>
						<p className="ant-upload-hint">
							Support for a single or bulk upload. Strictly prohibit from uploading company data or other
							band files
						</p>
					</Upload.Dragger>
				</Modal>
			</Skeleton>
		),
	};

	return (
		<InSiteFrame>
			<Row gutter={[16, 16]} type={`flex`} style={{alignItems: 'flex-end'}}>
				<Col span={24}>
					<Breadcrumb style={{alignItems: 'flex-end'}}>
						<Breadcrumb.Item>Inventory Management</Breadcrumb.Item>
						<Breadcrumb.Item>Stage 1</Breadcrumb.Item>
						<Breadcrumb.Item>Lot 101</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			<Row gutter={[16, 16]} type={`flex`}>
				<Col span={24}>
					<div style={{ backgroundColor: '#FFFFFF', padding: 16 }}>
						<PageHeader
							onBack={() => history.push('/stock-management')}
							title="Lot 101"
							subTitle="(Stage 1)"
							extra={[<Button key="2" type="danger" onClick={() => {
								Modal.confirm({
									title: 'Remove Lot from Market?',
									content: 'This action will remove the lot from the market and will no longer displayed on any linked website. Do you wish to proceed?',
									onOk() {
										notification['info']({
											message: 'Lot Removed from Market',
											description: 'This lot has been removed from the market and will no longer appear on any linked websites.'
										});
									}
								});
							}}>Remove from Market</Button>]}
						/>
					</div>
				</Col>
			</Row>
			<Row gutter={[16, 16]} type={`flex`}>
				<Col span={24}>
					<Card tabList={tabList} tabBarExtraContent={tabExtra[currentTabKey]} activeTabKey={currentTabKey} onTabChange={(key) => {
						setIsLoading(true);
						setCurrentTabKey(key)
						setTimeout(() => {
							setIsLoading(false);
						}, 450);
					}}>
						{contentList[currentTabKey]}
					</Card>
				</Col>
			</Row>
		</InSiteFrame>
	);
};

export default StockManagementLotPage;
