import React from 'react';
import ReactDOM from 'react-dom';

import AppRouter from "./routes/AppRouter";
import "./styles/bigginscott.css";

ReactDOM.render(
	<AppRouter/>,
	document.getElementById('root')
);
